import { trackBuildEvents } from './BuildEvents';
import { trackKnowEvents } from './KnowEvents';
import { trackSlideEvents } from './SlideEvents';
import { trackPrezentationEvents } from './PrezentationEvents';
import { trackLearnEvents } from './LearnEvents';
import {
  TD_ISEDITOR,
  TD_ISPRIVATE,
  TD_ISREVIEWER,
  TD_ISVIEWER,
  TD_ISRESTRICTED,
  getPrezData,
  MY_PREZENTATION,
  BUILD,
  SLIDES,
  TD_PLACEMENT,
  TD_ON_TOP,
  TD_OTHER_ACTIONS,
} from './MatomoTrackingDataHelper';
import { trackFingerprintEvents } from './FingerprintEvents';
import { trackOvernightPrezentationsEvents } from './OvernightPrezentationsEvents';
import { trackGenerateEvents } from './GenerateEvents';
import { trackUploadEvents } from './UploadEvents';
import { trackComplyEvents } from './ComplyEvents';
import { trackAcceleratorsEvents } from './AcceleratorsEvents';
import { trackRedesignEvents } from './RedesignEvents';
import { trackSynthesisEvents } from './SynthesisEvents';

const MY_PREZENTATION_EXPANDED_VIEW_ICON = 'my_prezentation-expanded_view-icon';
const MY_PREZENTATION_EXPANDED_VIEW_BUTTON =
  'my_prezentation-expanded_view-button';
const BUILD_EXPANDED_VIEW_BUTTON = 'build-expanded_view-button';
const BUILD_EXPANDED_VIEW_ICON = 'build-expanded_view-icon';
const MY_PREZENTATION_THUMBNAIL_ICON = 'my_prezentation-thumbnail-icon';
const MY_PREZENTATION_THUMBNAIL_BUTTON = 'my_prezentation-thumbnail-button';
const MY_PREZENTATION_COPIED_EXPANDED_VIEW_SHARE_ICON =
  'my_prezentation_copied-expanded_view-share-icon';
const MY_PREZENTATION_COPIED_SHARE_ICON = 'my_prezentation_copied-share-icon';
const MY_PREZENTATION_SHARE_ICON = 'my_prezentation-share-icon';
const MY_PREZENTATION_EXPANDED_VIEW_SHARE_ICON =
  'my_prezentation-expanded_view-share-icon';
const BUILD_SHARE_ICON = 'build-share-icon';
const BUILD_EXPANDED_VIEW_SHARE_ICON = 'build-expanded_view-share-icon';

const getEntityList = (entities) => {
  let eList = '';
  entities.forEach((sEntity) => {
    const entityType = sEntity.shared_entity_type;
    if (entityType === 'company') {
      eList += 'My Company$';
    }
    if (entityType === 'team') {
      eList += 'My Team$';
    }
    if (entityType === 'user') {
      eList += `${sEntity.shared_entity_id}$`;
    }
  });

  return eList;
};

const getSharedEntitiesData = (sharedEntities) => {
  const sharedEntitiesTrackingData = {};
  if (!sharedEntities.length) {
    sharedEntitiesTrackingData[TD_ISPRIVATE] = 1;
    return sharedEntitiesTrackingData;
  }

  const viewers = sharedEntities.filter(
    (sharedEntity) => sharedEntity.permission_level === '1',
  );
  const isViewerList = getEntityList(viewers);
  if (isViewerList) {
    sharedEntitiesTrackingData[TD_ISVIEWER] = isViewerList;
  }
  const reviewers = sharedEntities.filter(
    (sharedEntity) => sharedEntity.permission_level === '2',
  );
  const isReviewerList = getEntityList(reviewers);
  if (isReviewerList) {
    sharedEntitiesTrackingData[TD_ISREVIEWER] = isReviewerList;
  }
  const editors = sharedEntities.filter(
    (sharedEntity) => sharedEntity.permission_level === '3',
  );
  const isEditorsList = getEntityList(editors);
  if (isEditorsList) {
    sharedEntitiesTrackingData[TD_ISEDITOR] = isEditorsList;
  }
  const restricted = sharedEntities.filter(
    (sharedEntity) => sharedEntity.permission_level === '0',
  );
  const isRestrictedList = getEntityList(restricted);
  if (isRestrictedList) {
    sharedEntitiesTrackingData[TD_ISRESTRICTED] = isRestrictedList;
  }

  return sharedEntitiesTrackingData;
};

export const trackCommonEvents = {
  prezentationShareClick: (currentUser, eventOrigin, prezentation) => {
    try {
      const otherData = getPrezData(prezentation);
      switch (eventOrigin) {
        case BUILD_EXPANDED_VIEW_ICON:
          trackBuildEvents.buildShareIcon(currentUser, otherData);
          break;
        case BUILD_SHARE_ICON:
          trackBuildEvents.buildShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_ON_TOP,
          });
          break;
        case BUILD_EXPANDED_VIEW_SHARE_ICON:
          trackBuildEvents.buildShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_OTHER_ACTIONS,
          });
          break;
        case BUILD_EXPANDED_VIEW_BUTTON:
          trackBuildEvents.buildShareButton(currentUser, otherData);
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_ICON:
          trackPrezentationEvents.prezentationsShareIcon(
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION_COPIED_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_ON_TOP,
          });
          break;
        case MY_PREZENTATION_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_ON_TOP,
          });
          break;
        case MY_PREZENTATION_COPIED_EXPANDED_VIEW_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_OTHER_ACTIONS,
          });
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIcon(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_OTHER_ACTIONS,
          });
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_BUTTON:
          trackPrezentationEvents.prezentationsShareButton(
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION_THUMBNAIL_ICON:
          trackPrezentationEvents.prezentationsThumbnailShareIcon(
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION_THUMBNAIL_BUTTON:
          trackPrezentationEvents.prezentationsThumbnailShareButton(
            currentUser,
            otherData,
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },

  prezentationShared: (
    currentUser,
    eventOrigin,
    prezentation,
    sharedEntities,
  ) => {
    try {
      const prezData = getPrezData(prezentation);
      const sharedEntitiesData = getSharedEntitiesData(sharedEntities);
      const otherData = {
        ...prezData,
        ...sharedEntitiesData,
      };
      switch (eventOrigin) {
        case BUILD_EXPANDED_VIEW_ICON:
          trackBuildEvents.buildShareIconUpdate(currentUser, otherData);
          break;
        case BUILD_EXPANDED_VIEW_BUTTON:
          trackBuildEvents.buildShareButtonUpdate(currentUser, otherData);
          break;
        case MY_PREZENTATION_COPIED_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIconUpdate(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_ON_TOP,
          });
          break;
        case MY_PREZENTATION_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIconUpdate(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_ON_TOP,
          });
          break;
        case MY_PREZENTATION_COPIED_EXPANDED_VIEW_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIconUpdate(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_OTHER_ACTIONS,
          });
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_SHARE_ICON:
          trackPrezentationEvents.prezentationsShareIconUpdate(currentUser, {
            ...otherData,
            [TD_PLACEMENT]: TD_OTHER_ACTIONS,
          });
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_ICON:
          trackPrezentationEvents.prezentationsShareIconUpdate(
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION_EXPANDED_VIEW_BUTTON:
          trackPrezentationEvents.prezentationsShareButtonUpdate(
            currentUser,
            otherData,
          );

          break;
        case MY_PREZENTATION_THUMBNAIL_ICON:
          trackPrezentationEvents.prezentationsThumbnailShareIconUpdate(
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION_THUMBNAIL_BUTTON:
          trackPrezentationEvents.prezentationsThumbnailShareButtonUpdate(
            currentUser,
            otherData,
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },

  trackNavItemClick(currentUser, navTitle) {
    try {
      switch (navTitle) {
        case 'Build':
          trackBuildEvents.buildIconClick(currentUser);
          break;
        case 'Prezentations':
          trackPrezentationEvents.prezentationsIconClick(currentUser);
          break;
        case 'Slides':
          trackSlideEvents.slidesIconClick(currentUser);
          break;
        case 'Learn':
          trackLearnEvents.learnIconClick(currentUser);
          break;
        case 'Best Practice Library':
          trackKnowEvents.knowIconClick(currentUser);
          break;
        case 'Fingerprints':
          trackFingerprintEvents.fingerprintsIconclick(currentUser);
          break;
        case 'Overnight Prezentations':
          trackOvernightPrezentationsEvents.OPIconClick(currentUser);
          break;
        case 'Generate':
          trackGenerateEvents.generateIconClick(currentUser);
          break;
        case 'Comply':
          trackComplyEvents.complianceCheckerIconClick(currentUser);
          break;
        case 'Synthesis':
          trackSynthesisEvents.synthesisIconClick(currentUser);
          break;
        case 'Upload':
          trackUploadEvents.uploadIconClick(currentUser);
          break;
        case 'Redesign':
          trackRedesignEvents.redesignCheckerIconClick(currentUser);
          break;
        case 'Accelerators':
          trackAcceleratorsEvents.acceleratorsIconClick(currentUser);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },

  imageReplacementSearch(currentUser, slideData, otherData, origin) {
    try {
      switch (origin) {
        case BUILD:
          trackBuildEvents.buildImagereplacementSearch(
            slideData,
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION:
          trackPrezentationEvents.prezentationsImagereplacementSearch(
            slideData,
            currentUser,
            otherData,
          );
          break;
        case SLIDES:
          trackSlideEvents.slidesImagereplacementSearch(
            slideData,
            currentUser,
            otherData,
          );
          break;
        case 'BPE-Slides':
          trackKnowEvents.knowImageReplacementSearch(
            slideData,
            currentUser,
            otherData,
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },
  imageReplacementAccept(currentUser, slideData, otherData, origin) {
    try {
      switch (origin) {
        case BUILD:
          trackBuildEvents.buildImagereplacementAccept(
            slideData,
            currentUser,
            otherData,
          );
          break;
        case MY_PREZENTATION:
          trackPrezentationEvents.prezentationsImagereplacementAccept(
            slideData,
            currentUser,
            otherData,
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },
  trackTutorialClicked(currentUser, otherData, origin) {
    try {
      switch (origin.toLowerCase()) {
        case 'auto generator':
          trackGenerateEvents.generateTutorialClick(currentUser, otherData);
          break;
        case 'synthesis':
          trackSynthesisEvents.synthesisTutorialLoad(currentUser, otherData);
          break;
        case 'redesign':
          trackRedesignEvents.redesignTutorialLoad(currentUser, otherData);
          break;
        case 'template converter':
          trackComplyEvents.complianceCheckerTutorialPlay(
            currentUser,
            otherData,
          );
          break;
        case 'prezentation library':
          trackPrezentationEvents.prezentationsTutorialIconVideoPlay(
            currentUser,
            otherData,
          );
          break;
        case 'story builder':
          trackBuildEvents.buildTutorialIconVideoClick(currentUser, otherData);
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },
  trackSmartLayoutViewChange(currentUser, otherData, origin) {
    try {
      switch (origin.toLowerCase()) {
        case 'redesign':
          trackRedesignEvents.redesignSmartLayoutViewClick(
            currentUser,
            otherData,
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  },
};
