<template>
  <div class="templates-wrapper">
    <p class="info-text">
      {{
        isOP
          ? 'Select template for your slides'
          : 'Choose template for your prezentation'
      }}
    </p>
    <div class="prezentations-search-bar">
      <v-text-field
        class="prezentations-search-bar__input pl-4 prezentations-search-bar--auto"
        v-model="templateSearched"
        placeholder="Search Templates"
        @keyup.stop.native="handleSearch"
        @focus="searchWithTextBoxActive = true"
        @blur="searchWithTextBoxActive = false"
      >
        <template #append>
          <v-icon
            class="icon"
            v-text="`mdi-close`"
            @click="clearTextFieldSearch"
            name="prez-cross-icon"
            color="#21a7e0"
            v-show="templateSearched.length > 0"
          />
          <v-icon
            class="icon search-icon"
            v-text="`mdi-magnify`"
            :color="searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'"
          />
        </template>
      </v-text-field>
    </div>

    <div class="templates-cntr">
      <div
        class="templates-list"
        v-for="(item, index) in sortedTheme"
        :key="index + '_Templates'"
      >
        <v-row v-if="item.name === 'All'" :key="1">
          <div class="checkboxWithName">
            <v-checkbox
              v-model="item.checked"
              color="primary"
              hide-details
              :label="`${item.name} (${allThemes.length - 1} templates)`"
              :disabled="item.disabled"
              @click="() => handleAllSelection(item)"
              class="allOption"
            />
          </div>
        </v-row>

        <v-row v-else :key="2">
          <div class="checkboxWithName" style="padding-bottom: 0.5rem">
            <v-checkbox
              v-model="item.checked"
              color="primary"
              hide-details
              :disabled="item.disabled"
              @change="() => selectOtherTheme(item)"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="template-title" v-bind="attrs" v-on="on">
                  {{ item.name }}
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>

          <div class="imagesRow" v-if="item.name === 'Uploaded by me'">
            <div class="available-theme-img" style="width: 100%">
              <div class="thumbnailWrapper">
                <v-img
                  :src="item.thumbnailURL"
                  alt="image"
                  :aspect-ratio="16 / 9"
                  :elevation="2"
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
          </div>

          <div class="imagesRow" v-else>
            <div class="available-theme-img">
              <div class="thumbnailWrapper">
                <v-img
                  :src="item.thumbnailURL"
                  alt="image"
                  :aspect-ratio="16 / 9"
                  :elevation="2"
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
            <div class="example-slide-img">
              <div class="thumbnailWrapper">
                <v-img
                  :src="
                    item.sampleSlide ? item.sampleSlideURL : item.thumbnailURL
                  "
                  alt="image"
                  :aspect-ratio="16 / 9"
                  :elevation="2"
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
          </div>
        </v-row>
      </div>
      <div v-if="sortedTheme.length === 0" class="not-found-container">
        <img
          src="/assets/img/no-results-found-templates.svg"
          height="250"
          width="250"
          alt
        />
        <div class="heading mb-1 mt-6">
          {{ $t('build.step3.noTemplatesFound') }}
        </div>
        <v-btn
          center
          class="primary-button"
          height="35"
          width="160"
          rounded
          filled
          color="primary"
          @click="handleAddMore"
        >
          {{ $t('build.step3.addTemplates') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import AddingMoreTemplateDetail from '../../Main/Profile/AddingMoreTemplateDetail.vue';

export default {
  name: 'TemplateToolFilter',
  data() {
    return {
      templateSearched: '',
      sortedTheme: [],
      selectedTemplates: [{ name: 'All', checked: true, disabled: false }],
      searchWithTextBoxActive: false,
    };
  },
  props: {
    allThemes: {
      type: Array,
      default: () => [],
    },
    handleSearchWithTemplateModelChange: {
      type: Function,
      default: () => ({}),
    },
    handleAllTemplatesClick: {
      type: Function,
      default: () => ({}),
    },
  },
  watch: {
    allThemes: {
      handler(val) {
        this.sortedTheme = val.filter((theme) => !theme.isArchived);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isOP() {
      return this.$route.path.includes('overnight-prezentations');
    },
  },
  methods: {
    clearTextFieldSearch() {
      this.templateSearched = '';
      this.handleSearch();
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          templateRequestFrom: 'prezentations',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    handleAllSelection(item) {
      if (!item.checked) {
        this.selectedTemplates = [];
        this.handleSearchWithTemplateModelChange([]);
      } else {
        this.selectedTemplates = [
          { name: 'All', checked: true, disabled: false },
        ];
        this.handleSearchWithTemplateModelChange(this.selectedTemplates);
      }
      this.handleAllTemplatesClick(item);
    },
    selectOtherTheme(item) {
      const tempThemes = [...this.allThemes].map((thm) => {
        if (item.code === thm.code) {
          if (!item.checked) {
            return {
              ...thm,
              checked: false,
              disabled: false,
            };
          }
          return {
            ...thm,
            checked: true,
            disabled: false,
          };
        }
        return {
          ...thm,
        };
      });

      this.selectedTemplates = tempThemes.filter((ele) => ele.checked);
      this.handleSearchWithTemplateModelChange(this.selectedTemplates);
    },
    handleSearch() {
      const finalArray = [];

      this.allThemes.forEach((theme) => {
        if (
          theme.name
            .toLowerCase()
            .includes(this.templateSearched.toLowerCase()) &&
          !theme.isArchived
        ) {
          finalArray.push(theme);
        }
      });

      this.sortedTheme = finalArray;
    },
  },
  mounted() {
    const themes = this.allThemes.filter((theme) => !theme.isArchived);
    this.sortedTheme = [...themes];
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input {
  .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin: 0;
        padding: 4px 0px 0px 4px;
      }
      &::before,
      &::after {
        border: none !important;
      }
    }
  }
}
.templates-wrapper {
  .info-text {
    font-size: 16px;
    font-weight: 400;
    color: #212121;
  }
  .prezentations-search-bar {
    margin: 0 0.2rem 1rem 0.2rem;

    .prezentations-search-bar__input {
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      font-size: 14px;
      height: 40px !important;
      padding: 2px 14px 8px 20px;
      // width: 380px;
      // margin: 0px 42px 0px 0px;
      z-index: 3;
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        .v-input__control {
          .v-input__slot {
            .v-input__append-inner {
              margin: 0;
              padding: 4px 0px 0px 4px;
            }
          }
        }
      }
      ::v-deep .v-input__slot::before {
        border-style: none !important;
        display: none;
      }
      ::v-deep .v-input__icon {
        margin-right: -8px;
      }
    }
  }

  .templates-cntr {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 0px 0px 4px 0px;
    .not-found-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;
      margin-top: 30px;
      .heading {
        color: #000000;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin: 10px 0px;
        text-align: center;
      }
    }
  }

  .templates-cntr::-webkit-scrollbar {
    display: none;
  }

  .templates-list {
    padding: 0 0.5rem;
  }

  .row {
    width: 100%;
    margin: 0;
    cursor: pointer;

    .checkboxWithName {
      display: flex;
      width: 100%;

      .v-input {
        margin: 0;
        padding: 0;

        .v-input__control {
          .v-input__slot {
            border-bottom: none !important;
            padding: 0 !important;
            .v-input--selection-controls__input {
              margin: 0px 0px 0px 16px;
            }
            .v-label {
              margin: 0px 0px 6px 8px;
            }
          }
        }
      }
      .template-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .imagesRow {
      width: 100%;
      display: flex !important;
      height: 104px !important;
      padding: 0;
      margin: 0 4px;

      .available-theme-img {
        padding: 0px;
      }
      .example-slide-img {
        padding: 0px;
        margin-left: 1rem;
      }
      .available-theme-img,
      .example-slide-img {
        width: 48%;
        height: 100%;
        .thumbnailWrapper {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
            -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
          .v-image {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .not-found-container button {
    background: #21a7e0;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 15px 0;
    color: white;
    text-transform: none;
  }
}
</style>
