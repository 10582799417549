<template>
  <div
    class="change-layout-container"
    :data-pendo-id="'redesign-change-layout'"
  >
    <div
      class="heading"
      :style="{
        marginBottom: '8px',
      }"
    >
      {{ $t('generate.smartLayout') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="sub-heading">{{ $t('generate.slideWithDifferentLayout') }}</div>
    <v-tabs
      color="#21a7e0"
      height="57"
      fixed-tabs
      :hide-slider="true"
      v-model="currentTab"
    >
      <v-tab
        :hide-slider="true"
        :style="
          currentTab === 0 ? { 'border-bottom': '2px solid #21a7e0' } : {}
        "
        class="vtab"
      >
        {{ 'Redesigned Layouts' }}
      </v-tab>
      <v-tab
        :hide-slider="true"
        :style="
          currentTab === 1 ? { 'border-bottom': '2px solid #21a7e0' } : {}
        "
        class="vtab"
      >
        {{ 'Reformatted Layouts' }}
      </v-tab>
      <v-tabs-items v-model="currentTab">
        <v-tab-item :value="0">
          <RedesignChangeLayoutViews
            :slides="
              selectedSlide && selectedSlide.redesignedSlides
                ? selectedSlide.redesignedSlides.slides
                : []
            "
            :loading="
              selectedSlide && selectedSlide.redesignedSlides
                ? selectedSlide.redesignedSlides.loading
                : true
            "
            :type="'redesigned'"
            @change="handleChangeLayout"
            :selectedSlideID="selectedSlideID"
          />
        </v-tab-item>
        <v-tab-item :value="1">
          <RedesignChangeLayoutViews
            :slides="
              selectedSlide && selectedSlide.reformattedSlides
                ? selectedSlide.reformattedSlides.slides
                : []
            "
            :loading="
              selectedSlide && selectedSlide.reformattedSlides
                ? selectedSlide.reformattedSlides.loading
                : true
            "
            :type="'reformatted'"
            @change="handleChangeLayout"
            :selectedSlideID="selectedSlideID"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import RedesignChangeLayoutViews from './RedesignChangeLayoutViews.vue';

export default {
  name: 'RedesignChangeLayout',
  components: { RedesignChangeLayoutViews },
  props: {
    selectedSlide: {
      type: Object,
      default: () => null,
    },
    selectedSlideID: {
      type: String,
    },
  },
  watch: {
    currentTab(val) {
      if (val) {
        this.setRedesignLayoutTab(val);
      }
    },
  },
  methods: {
    ...mapActions('profileStore', ['setRedesignLayoutTab']),
    handleChangeLayout(slide) {
      this.$emit('change', { type: 'slide_change', slide });
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  mounted() {
    if (
      !this.selectedSlide.redesignedSlides?.slides?.length &&
      !this.selectedSlide.redesignedSlides?.loading &&
      !this.selectedSlide.reformattedSlides?.loading &&
      this.selectedSlide.reformattedSlides?.slides?.length
    ) {
      this.currentTab = 1;
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.change-layout-container {
  width: 495px;
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .v-tab {
    text-decoration: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-transform: none;
  }
  ::v-deep .v-tabs {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .v-tabs-bar {
      min-height: 57px;
    }
  }

  .vtab {
    font-weight: 300;
    font-size: 1.1rem;
    letter-spacing: normal;
    &:hover {
      text-decoration: none;
    }
  }

  .v-window {
    flex: 1;
    min-height: 0;
  }

  ::v-deep .v-window__container {
    height: 100%;

    .v-window-item {
      height: 100%;
    }
  }
}
</style>
