var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"collection-wrapper"},[_c('div',{staticClass:"collection-header-wrapper"},[_c('div',{staticClass:"collection-heading"},[_c('h1',[_vm._v(_vm._s(_vm.$t('slides.collection')))])]),_c('div',{style:({ display: 'flex', gap: '1.2rem' })},[(_vm.showAllCollectionsDownloadLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":22}}):_c('Download',{attrs:{"file-name":'',"download-file-name":'',"merged-url":function () {},"item":{},"log-download":function () {},"on-download":function () { return _vm.handleDownloadWholeCollection(); },"origin":"prezentation","limitsKey":"prezentation","speedDialDirection":'left',"mode":'icon',"iconModeTooltipText":'slides.downloadEntireCollection',"customSpeedDialPositionEnabled":true,"disableSaveToGDrive":true,"saveToGDriveDisabledTooltip":'slides.entireCollectionDownloadNotAvailable',"isDisabled":_vm.disableOverallCollectionDownload ||
            _vm.getCollectionUpdateProgress ||
            _vm.isDownloadBlocked,"downloadBlocked":_vm.isDownloadBlocked}}),_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","disabled":_vm.getCollectionUpdateProgress,"height":'24px'},on:{"click":function($event){return _vm.showReset()}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black","dark":"","dense":""},domProps:{"textContent":_vm._s('mdi-delete-outline')}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.removeEntireCollection')))])])],1),_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","height":'24px'},on:{"click":_vm.hideCollection}},[_c('v-icon',{attrs:{"color":"black","dense":"","dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1)],1)]),_c('p',{staticClass:"collection-sub"},[_vm._v(" "+_vm._s('Shortlist and download up to 5 slides at once or save for later.')+" ")]),(_vm.getAllCollections.length === 0)?_c('div',{staticClass:"empty-collection"},[_c('v-img',{staticClass:"empty-collection__img",attrs:{"src":'/assets/img/Empty Collection.svg',"alt":""}}),_c('p',{staticClass:"empty-collection__text"},[_vm._v(" "+_vm._s(_vm.$t('slides.collectionIsEmpty'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('slides.downloadUptoFiveSlides'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('slides.clickOnAddToCollection'))+" ")])],1):_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.getAllCollections),function(item,i){return _c('v-expansion-panel',{key:((item.templateID) + "_" + i),attrs:{"expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"set-header"},[_c('span',[_vm._v(" "+_vm._s(item.templateName)+" ")]),_c('span',{staticStyle:{"display":"flex","gap":"1.2rem","align-items":"center"}},[_c('div',{on:{"click":function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                  }}},[(
                    _vm.showCollectionDownloadLoader(item.templateID) === true
                  )?_c('v-progress-circular',{attrs:{"indeterminate":"","size":22}}):_c('Download',{attrs:{"file-name":("" + (item.templateName)).trim(),"download-file-name":("" + (item.templateName)).trim(),"merged-url":function () { return _vm.getMergedUrlForSaveToGdrive(item); },"item":_vm.getDownloadItem(item),"log-download":function () {
                      _vm.logDownloadForSaveToGdrive(item);
                    },"on-download":function () { return _vm.handleDownload(item); },"origin":"prezentation","limitsKey":"prezentation","speedDialDirection":'left',"mode":'icon',"iconModeTooltipText":'slides.downloadThisSet',"customSpeedDialPositionEnabled":true,"isDisabled":_vm.showAllCollectionsDownloadLoader ||
                    _vm.getCollectionUpdateProgress ||
                    _vm.isDownloadBlocked,"downloadBlocked":_vm.isDownloadBlocked}})],1),_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","disabled":_vm.getCollectionUpdateProgress},on:{"click":function($event){$event.stopPropagation();return _vm.showReset(item)}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","dense":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"black","dark":""},domProps:{"textContent":_vm._s('mdi-delete-outline')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.removeThisSet')))])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.panel >= 0 && _vm.panel === i ? 'min-collection' : '',attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v(" $expand ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.panel >= 0 && _vm.panel === i ? _vm.$t('slides.minimizeThisSet') : _vm.$t('slides.expandThisSet'))+" ")])])],1)],1)])]),_c('v-expansion-panel-content',[_vm._l((item.slides),function(slidee,i){return _c('div',{key:((slidee.slideFileName) + "_" + i),staticClass:"slide-image-container"},[_c('img',{key:slidee.slideFileName,staticClass:"slide-image",attrs:{"src":slidee.thumbnail || '/assets/img/slides/placeholder-slide.svg',"contain":"","loading":"lazy","aspect-ratio":16 / 9}}),_c('div',{staticClass:"additional-options"},[_c('div',{staticClass:"show-detail pl-2",on:{"click":function($event){return _vm.handleDeleteCollection(slidee, item.templateID)}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-minus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('slides.removeThisSlide')))])])],1)]),(slidee.isImageReplaced || slidee.isIconReplaced)?_c('div',{staticClass:"image-replaced__collection"},[_vm._v(" "+_vm._s(_vm.$t('build.step1.custom'))+" ")]):_vm._e()])}),(item.slides.length === 5)?_c('div',{staticClass:"limit-reaced__message"},[_c('p',[_vm._v(_vm._s(_vm.$t('slides.limitReached')))]),_c('p',[_vm._v(_vm._s(_vm.$t('slides.slidesAdded')))])]):_vm._e()],2)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }