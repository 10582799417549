var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.type === 'People')?_c('AudienceItem',{attrs:{"itemClass":'user-template',"maxWidth":427,"isInComplete":!_vm.item.item.fingerPrint,"fullName":_vm.item.item.fullName,"firstName":_vm.item.item.firstName,"lastName":_vm.item.item.lastName,"profileImgS3Path":_vm.item.item.profileImage,"email":_vm.audienceEmail,"creator":_vm.item.item.creator,"emailEntered":_vm.item.item.emailEntered,"companyName":_vm.item.item.companyName,"ownerId":_vm.item.item.ownerID,"isPrivate":_vm.item.item.isPrivate,"isShared":_vm.item.item.isShared,"status":_vm.item.type === 'People' && !_vm.item.item.isActive ? 'Disabled' : '',"isDifferentCompany":_vm.item.item.companyID !== _vm.currentUser.user.companyID,"isPublicLimited":_vm.item.item.isPublicLimited,"isGroup":_vm.item.item.isGroup,"fingerprintType":_vm.item.item.fingerprintType
        ? _vm.item.item.fingerprintType.toLowerCase()
        : 'default'}},[_c('div',{staticClass:"dot"},[_vm._v("·")]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.renameTypes(_vm.item.type))+" ")])]):(_vm.item.type === 'Prezentations' && !_vm.item.item.isBestPracticeDeck)?_c('div',{staticClass:"item-templates"},[_c('v-list-item',{staticClass:"audience-item",style:('max-width: 350px')},[_c('v-list-item-avatar',{staticClass:"profile-avatar",attrs:{"size":26}},[_c('img',{attrs:{"src":_vm.typeIcons[_vm.item.type] || _vm.typeIcons.Slides,"alt":""}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-email"},[(
              _vm.item &&
              _vm.item.item &&
              _vm.item.item.matchedIndice &&
              _vm.item.item.matchedIndice.length
            )?_c('div',{staticClass:"matchingSlidesWrapper"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"matching-results"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.item.matchedIndice)+" ")])]}}],null,false,1837582596)},[_c('div',[_c('v-list-item-title',{staticClass:"text-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-email"},[_vm._v(" "+_vm._s(_vm.item.item.matchedIndice)+" ")])],1)])],1):_c('div',{staticClass:"author"},[_c('span',[_vm._v(" "+_vm._s(("Storyline: " + (_vm.item.item.type)))+" ")]),_c('span',[_vm._v(" "+_vm._s("| Author: ")+" "),_c('span',[_vm._v(" "+_vm._s(("" + (_vm.isOwner(_vm.item.item) ? 'Me' : _vm.item.item.ownerName)))+" ")])])])])],1)],1),_c('div',{staticClass:"dot"},[_vm._v("·")]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.renameTypes(_vm.item.type))+" ")])],1):(_vm.item.type === 'Storylines' || _vm.item.type === 'Build')?_c('div',{staticClass:"item-templates"},[_c('v-list-item',{staticClass:"audience-item",style:('max-width: 350px')},[_c('v-list-item-avatar',{staticClass:"profile-avatar",attrs:{"size":26}},[_c('img',{attrs:{"src":_vm.typeIcons[_vm.item.type] || _vm.typeIcons.Slides,"alt":""}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" "),(_vm.item.creator === 'Prezent')?_c('img',{attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"18px"}}):_vm._e()]),_c('v-list-item-subtitle',{staticClass:"text-email"},[_c('div',{staticClass:"author"},[(
                _vm.item.type === 'Storylines' ||
                (_vm.item.type === 'Build' && _vm.item.item.isPrezentGenerated)
              )?_c('span',[_vm._v(" "+_vm._s("Source: ")+" "),(_vm.item.item.isPrezentGenerated)?_c('span',[_vm._v(" "+_vm._s('Prezent')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.currentUser.company.displayName)+" ")])]):_vm._e(),(!_vm.item.item.isPrezentGenerated && _vm.item.type === 'Build')?_c('span',[_vm._v(" "+_vm._s(("Storyline: " + (_vm.item.item.type)))+" ")]):_vm._e(),(!_vm.item.item.isPrezentGenerated)?_c('span',[_vm._v(" "+_vm._s("| Author: ")+" "),_c('span',[_vm._v(" "+_vm._s(("" + (_vm.isOwner(_vm.item.item) ? 'Me' : _vm.item.item.fullName)))+" ")])]):_vm._e()])])],1)],1),_c('div',{staticClass:"dot"},[_vm._v("·")]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.renameTypes(_vm.item.type))+" ")])],1):(_vm.item.type === 'Best Practice Decks')?_c('div',{staticClass:"item-templates"},[_c('v-list-item',{staticClass:"audience-item",style:('max-width: 350px')},[_c('v-list-item-avatar',{staticClass:"profile-avatar",attrs:{"size":26}},[_c('img',{attrs:{"src":_vm.typeIcons[_vm.item.type] || _vm.typeIcons.Slides,"alt":""}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-email"},[(
              _vm.item &&
              _vm.item.item &&
              _vm.item.item.matchedIndice &&
              _vm.item.item.matchedIndice.length
            )?_c('div',{staticClass:"matchingSlidesWrapper"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"matching-results"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.item.matchedIndice)+" ")])]}}],null,false,1837582596)},[_c('div',[_c('v-list-item-title',{staticClass:"text-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-email"},[_vm._v(" "+_vm._s(_vm.item.item.matchedIndice)+" ")])],1)])],1):_vm._e()])],1)],1),_c('div',{staticClass:"dot"},[_vm._v("·")]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.renameTypes(_vm.item.type))+" ")])],1):_c('div',{staticClass:"item-template"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"width":"26","src":_vm.typeIcons[_vm.item.type] || _vm.typeIcons.Slides,"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.item.name || _vm.item.display_name)+" "),(_vm.item.creator === 'Prezent')?_c('img',{attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"22px"}}):_vm._e()]),_c('div',{staticClass:"dot"},[_vm._v("·")]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.renameTypes(_vm.item.type))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }