<template>
  <div
    v-if="item.type && item.type === 'pendo-upload-item'"
    :data-pendo-id="`${menuId}-${item.key}`"
  />
  <div
    class="catHeader"
    v-else-if="item.type && item.type === 'categoryHeader'"
    :name--auto="`${item.automationText}`"
    :style="{
      'font-size': `${listmini ? '14px' : '18px'}`,
      padding: `${!listmini ? '10px 8px 9px 22px' : '11px 8px 11px 8px'}`,
      margin: `${
        !listmini
          ? '0px 12px 2px 12px !important'
          : '0px 0px 2px 0px !important'
      }`,
      cursor: 'text',
      color: '#000',
      'text-align': `${listmini ? 'center' : 'left'}`,
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    }"
    :ripple="false"
  >
    <div>{{ `${listmini ? $t(item.collTitle) : $t(item.expTitle)}` }}</div>
  </div>
  <v-list-item
    :disabled="isTrialExpired"
    :id="`v-step-${itemKey}`"
    :data-pendo-id="`${menuId}-${item.key}`"
    data-amplify-analytics-on="click"
    data-amplify-analytics-name="LeftNavBar"
    :data-amplify-analytics-attrs="`tabName:${item.title}`"
    @click="handleNavClickFn(item.title, item)"
    v-else
    :name--auto="`${item.automationText}`"
    :class="`${itemActiveClass}`"
    :style="{
      margin: `${
        !listmini
          ? '0px 12px 2px 12px !important'
          : '0px auto 2px auto !important'
      }`,
      'border-radius': `${!listmini ? '24px !important' : '50% !important'}`,
      width: `${
        listmini && itemActiveClass === 'activeSideBarItem' ? '55px' : 'auto'
      }`,
      height: `${
        listmini && itemActiveClass === 'activeSideBarItem' ? '55px' : 'auto'
      }`,
      'min-height': `${
        listmini && itemActiveClass === 'activeSideBarItem'
          ? '46px !important'
          : 'auto'
      }`,
      padding: `${
        !listmini ? '0px 8px 0px 8px !important' : '3.5px 5.5px !important'
      }`,
    }"
  >
    <v-tooltip
      v-model="showTooltip"
      right
      max-width="205"
      content-class="tooltip-content"
      :style="{ left: tooltipPos }"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="sideBarListItemWrapper"
          @mouseover="positionTooltip"
          ref="sideBarListItemRef"
          v-bind="attrs"
          v-on="on"
          :style="
            listmini
              ? { display: 'flex', 'justify-content': 'center' }
              : { width: '100% !important' }
          "
        >
          <!--
        <div
          class="icon-wrapper custom-icon"
          v-if="item.icon === 'know-icon'"
        >
          <KnowIcon />
        </div>
        !-->
          <div
            class="icon-wrapper"
            :style="item.flip ? { transform: 'rotate(270deg)' } : {}"
          >
            <!--
          <v-icon
            :id="`v-step-${itemKey}-normal-icon`"
            class="normal"
            color="black"
            :disabled="isTrialExpired"
          >
          !-->
            <component
              :is="iconMap[item.icon]"
              :iconWidth="24"
              :iconHeight="24"
              :iconColor="getIconColor"
              :iconStrokeWidth="0.5"
            />
            <!--
          </v-icon>
          !-->

            <!--
          <v-icon
            :id="`v-step-${itemKey}-active-icon`"
            class="active"
            :disabled="isTrialExpired"
          >
            {{ item.icon }}
          </v-icon>
          !-->
          </div>
          <v-list-item-content v-if="!listmini" class="list-content">
            <div
              v-if="
                item.title === 'overnightPresentations.overnightPresentation'
              "
              class="overnight-title-badge-wrapper"
            >
              <v-list-item-title
                class="overnight-title"
                style="
                  font-size: 17px;
                  overflow: visible;
                  white-space: normal;
                  line-height: 24px;
                "
                >{{ $t(item.title) }}</v-list-item-title
              >

              <NewFeatureBadge
                class="new-feature-badge-class"
                module-name="overnight_prezentations"
                :feature-id="'overnight_prezentations_nav_id'"
                :offsetX="'55'"
                :offsetY="'0'"
                :size="'medium'"
                :userFeatureAccessDate="userFeatureAccessDate"
              />
            </div>
            <div v-else-if="item.title === 'rightSideBar.bestPracticesText'">
              <NewFeatureBadge
                module-name="release_2200"
                :feature-id="'release_2200'"
                :offsetX="'-4'"
                :size="'medium'"
                :offsetY="'16'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'common.upload'">
              <NewFeatureBadge
                module-name="upload"
                :feature-id="'upload_landing_id'"
                :offsetX="'-4'"
                :size="'medium'"
                :offsetY="'16'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'rightSideBar.generate'">
              <NewFeatureBadge
                module-name="generate"
                :feature-id="'generate_id'"
                :offsetX="'-4'"
                :size="'medium'"
                :offsetY="'16'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'profile.prezentations'">
              <NewFeatureBadge
                module-name="prezentations_left_nav"
                :feature-id="'prezentations_left_nav_id'"
                :offsetX="'-4'"
                :size="'medium'"
                :offsetY="'16'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'rightSideBar.comply'">
              <NewFeatureBadge
                module-name="comply"
                :feature-id="'comply_id'"
                :offsetX="'-4'"
                :size="'medium'"
                :offsetY="'16'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'rightSideBar.synthesize'">
              <NewFeatureBadge
                module-name="synthesis"
                :feature-id="'synthesis_id'"
                :size="'medium'"
                :offsetX="'-4'"
                :offsetY="'14'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'rightSideBar.redesign'">
              <NewFeatureBadge
                module-name="redesign"
                :feature-id="'redesign_id'"
                :size="'medium'"
                :offsetX="'-4'"
                :offsetY="'14'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'sideBarFeatures.accelerators'">
              <NewFeatureBadge
                module-name="accelerators"
                :feature-id="'accelerators_id'"
                :size="'medium'"
                :offsetX="'-4'"
                :offsetY="'14'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <div v-else-if="item.title === 'sideBarFeatures.zenithWorkshop'">
              <NewFeatureBadge
                module-name="zenithLearningWorkshop"
                :feature-id="'zenithLearningWorkshop_id'"
                :size="'medium'"
                :offsetX="'-4'"
                :offsetY="'14'"
              >
                <v-list-item-title
                  style="
                    font-size: 17px;
                    overflow: visible;
                    white-space: normal;
                    line-height: 24px;
                  "
                  >{{ $t(item.title) }}
                  <sup v-if="item.beta">{{ $t('rightSideBar.beta') }}</sup>
                </v-list-item-title>
              </NewFeatureBadge>
            </div>
            <v-list-item-title
              v-else
              style="font-size: 20px; overflow: visible"
              class="title-text"
              >{{ $t(item.title) }}
              <sup v-if="item.beta">{{
                $t('rightSideBar.beta')
              }}</sup></v-list-item-title
            >
          </v-list-item-content>
        </div>
      </template>
      <span v-if="item.title.toLowerCase() !== 'zenithWorkshop'">{{
        $t(listmini ? item.collTooltip : item.tooltip)
      }}</span>
    </v-tooltip>
  </v-list-item>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import EventBus from '../common/event-bus';
// import KnowIcon from '../common/Icons/KnowIcon.vue';
import BuildIcon from '../common/Icons/BuildIcon.vue';
import SlideLibIcon from '../common/Icons/SlideLibIcon.vue';
import AutoGeneratorIcon from '../common/Icons/AutoGeneratorIcon.vue';
import PrezentationsExIcon from '../common/Icons/PrezentationsExIcon.vue';
import BpeIcon from '../common/Icons/BpeIcon.vue';
import FingerprintIcon from '../common/Icons/FingerprintIcon.vue';
import CoursesIcon from '../common/Icons/CoursesIcon.vue';
import OpIcon from '../common/Icons/OpIcon.vue';
import ComplyIcon from '../common/Icons/ComplyIcon.vue';
import NewFeatureBadge from '../common/NewFeatureBadge.vue';
import { trackCommonEvents } from '../common/Analytics/CommonEvents';
import usersApi from '../../API/users-api';
import SynthesizeIcon from '../common/Icons/SynthesizeIcon.vue';
import RedesignIcon from '../common/Icons/RedesignIcon.vue';
import AcceleratorsIcon from '../common/Icons/Accelerators.vue';
import ZenithLearningWorkshopIcon from '../common/Icons/ZenithLearningWorkshopIcon.vue';

export default {
  name: 'ListItem',
  components: { NewFeatureBadge },
  data() {
    return {
      tooltipPos: 0,
      showTooltip: false,
      iconMap: {
        'build-icon': BuildIcon,
        'slide-lib-icon': SlideLibIcon,
        'auto-gen-icon': AutoGeneratorIcon,
        'prez-ex-icon': PrezentationsExIcon,
        'bpe-icon': BpeIcon,
        'fp-icon': FingerprintIcon,
        'courses-icon': CoursesIcon,
        'op-icon': OpIcon,
        'comply-icon': ComplyIcon,
        'synthesize-icon': SynthesizeIcon,
        'redesign-icon': RedesignIcon,
        'accelerators-icon': AcceleratorsIcon,
        'zenith-workshop-icon': ZenithLearningWorkshopIcon,
      },
      isIconHovered: false,
    };
  },
  props: {
    itemActiveClass: {
      type: String,
      default: '',
    },
    shouldUseRef: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    itemKey: {
      type: Number,
      default: null,
    },
    listmini: {
      type: Boolean,
    },
    menuId: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),

    handleNavigate() {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: `/home/${this.item.link}`,
          type: 'to_sidebar_link',
          action: 'push',
          pushAction: false,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        this.$router.push(`/home/${this.item.link}`).catch(() => {});
      }

      // clearing global search value when this component is clicked after making a global search
      EventBus.$emit('CLEAR_SEARCH');
    },

    handleNavClickFn(navTitle, navItem) {
      if (navTitle === 'Build' && navTitle.toLowerCase() === this.$route.name) {
        EventBus.$emit('CLEAR_NEW_PREZENTATION');
        return;
      }
      trackCommonEvents.trackNavItemClick(
        this.currentUser,
        navItem?.matomoTitle,
      );
      EventBus.$emit('CLEAR_SEARCH');
      this.handleNavigate();
      if (!this.currentUser.user.isSlideVisited) {
        const userdata = {
          isSlideVisited: true,
        };
        usersApi.methods
          .updateUserProfile(userdata)
          .then((updatedUser) => {
            console.log(updatedUser);
          })
          .catch((err) => console.log(err));
      }
    },

    positionTooltip() {
      this.tooltipPos = this.$refs.sideBarListItemRef.clientWidth;
    },
  },
  computed: {
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapState('users', [
      'isTrialExpired',
      'currentUser',
      'startLoadingSlidesFlag',
      'themes',
    ]),

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    userFeatureAccessDate() {
      let userFeatureAccessDate = null;
      const overnightPresentationFeature = this.currentUser?.features?.find(
        (feature) => feature.feature_name === 'overnight-prezentations',
      );

      if (overnightPresentationFeature) {
        userFeatureAccessDate = overnightPresentationFeature.updated_at;
      }
      return userFeatureAccessDate;
    },

    getIconColor() {
      let color = 'black';
      if (this.isTrialExpired) {
        color = 'gray';
      } else if (this.itemActiveClass === 'activeSideBarItem') {
        color = '#21a7e0';
      } else if (this.isIconHovered) {
        color = 'gray';
      }
      return color;
    },
  },
  watch: {
    listmini() {
      // use the value to toggle any other property when side bar is expanded or collapsed.
      // console.log(this.listmini);
      this.showTooltip = false;
    },
    showTooltip(val) {
      this.isIconHovered = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.overnight-title-badge-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .overnight-title {
    flex: 1;
    margin-right: 10px;
  }
}
.v-list-item {
  // &:hover:not(&--active) {
  //   .custom-icon {
  //     ::v-deep .v-icon {
  //       path {
  //         fill: gray;
  //       }
  //     }
  //   }
  // }
}

.catHeader {
  min-height: 28px !important;
  font-weight: 600;
  .v-ripple__container {
    display: none;
  }
}

.sideBarListItemWrapper {
  display: flex;
  // width: 100%;

  // .icon-wrapper.flip {
  //   transform: rotate(-90deg);
  // }

  .list-content {
    min-width: 278px;
    min-height: 40px;
  }

  .title-text {
    white-space: normal;
    line-height: 24px;
    font-size: 17px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // sup {
    //   font-family: 'Lato';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 10px;
    //   position: relative;
    //   left: -3px;
    //   top: -3px;
    //   vertical-align: super;
    //   opacity: 0.65;
    // }
  }
}
</style>
