<template>
  <v-img
    :class="`slide-drag ${getBPEClass} prezentations-${prezIndex}-section-${sectionIndex}-slide-${slideIndex}-drag`"
    :src="slideData.thumbnail"
    alt
    lazy-src="/assets/img/slides/placeholder-slide.svg"
    :aspect-ratio="16 / 9"
    @click="() => onClick(slideData)"
  >
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
      </v-row>
    </template>
    <slot> </slot>
  </v-img>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { getThumbnail } from '../../../store/modules/helper';
import { getFileURL } from '@/utils/calyrex';

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      slideData: {},
    };
  },
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    prezentation: {
      type: Object,
      default: () => ({}),
    },
    prezIndex: {
      type: Number,
      default: 0,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    getBPEClass() {
      if (this.prezentation && this.prezentation.matchedIndice) {
        const matchedSlides = this.prezentation.matchedIndice.split(': ').pop();
        if (
          matchedSlides &&
          matchedSlides.split(', ').includes(`${this.getSerialNumber}`)
        ) {
          return 'matched';
        }
        return '';
      }
      return '';
    },
    getSerialNumber() {
      const { list } = this.prezentation.sections;
      let count = 0;
      for (let index = 0; index < this.sectionIndex; index++) {
        count += list[index].slides.filter((i) => i.assetId).length;
      }

      count += this.slideIndex + 1;

      return count;
    },
  },
  async mounted() {
    const obj = {
      thumbnail: '/assets/img/404.png',
      landscape: '/assets/img/404.png',
    };
    if (this.isLoading) return;

    this.isLoading = true;
    obj.asset = this.slide;
    obj.asset.name = obj.asset.name ? obj.asset.name : '';
    obj.asset.file = this.slide.filename;
    obj.asset.pptFile = this.slide.file;
    // obj.isFavorite = this.favoriteSlides.includes(this.slide.unique_id);
    obj.thumbnail = obj.thumbnail || '';
    obj.asset.assetId =
      obj.asset.assetId || obj.asset.landscape || obj.asset.thumbnail;

    // Setting slide intermedietly to avoid displaying image caching
    this.slideData = obj;
    if (obj.asset.landscape || obj.asset.assetId || obj.asset.thumbnail) {
      const thumbnailPath =
        obj.asset.assetId || obj.asset.landscape || obj.asset.thumbnail;
      if (
        thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
        thumbnailPath.startsWith('/output/pptx/')
      ) {
        obj.thumbnail = await getThumbnail(thumbnailPath);
        if (obj.asset?.assetId?.indexOf('.pdf') !== -1) {
          obj.thumbnail = await convertPdfToImage(obj.thumbnail);
        }
      } else if (
        obj.asset.prefs?.source === 'uploaded' ||
        obj.asset.source === 'uploaded' ||
        obj.asset.isUserUploaded
      ) {
        if (
          thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
          thumbnailPath.startsWith('/output/pptx/')
        ) {
          obj.thumbnail = await getThumbnail(thumbnailPath);
        } else {
          const tempThumbnail = await getFileURL(
            this.currentUser.user.cognitoID,
            thumbnailPath,
            this.currentUser.userIp,
            'IOCUpload',
          );
          obj.thumbnail = await convertPdfToImage(tempThumbnail);
        }
      } else if (
        obj.asset.prefs?.source === 'generated' ||
        obj.asset.source === 'generated' ||
        obj.asset.isGenerated ||
        obj.asset.isIOCUploaded
      ) {
        if (
          thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
          thumbnailPath.startsWith('/output/pptx/')
        ) {
          obj.thumbnail = await getThumbnail(thumbnailPath);
        } else {
          const tempThumbnail = await getFileURL(
            this.currentUser.user.cognitoID,
            thumbnailPath,
            this.currentUser.userIp,
            'IOCUpload',
          );
          obj.thumbnail = tempThumbnail;
        }
      } else if (obj.asset?.assetId?.indexOf('.pdf') !== -1) {
        obj.thumbnail = await getThumbnail(
          thumbnailPath,
          null,
          null,
          obj.asset?.isAnonymized ? 'iocupload' : '',
        );
        obj.thumbnail = await convertPdfToImage(obj.thumbnail);
      } else {
        let type = 'Product';
        if (obj.asset.isOPUploaded) {
          type = 'OP';
          obj.thumbnail = obj.asset.thumbnail;
        } else if (
          obj.asset.isRedesign ||
          obj.asset.isGenerated ||
          obj.asset.source === 'generated' ||
          obj.asset.isUserUploaded ||
          obj.asset.isSynthesis ||
          obj.asset.isComply ||
          obj.asset.isIOCUploaded ||
          obj.asset.isPremiumSlide
        ) {
          type = 'IOCUpload';
        }

        if (type !== 'OP') {
          obj.thumbnail = await getFileURL(
            this.currentUser.user.cognitoID,
            thumbnailPath,
            this.currentUser.userIp,
            type,
          );
        }
      }
    }
    obj.asset.thumbnail =
      obj.thumbnail || '/assets/img/slides/placeholder-slide.svg';
    obj.thumbnail = obj.thumbnail || '/assets/img/slides/placeholder-slide.svg';

    this.slideData = obj;
  },
  // getThumbnailCommonFunction(slide) {},
};
</script>

<style lang="scss" scoped>
.matched {
  border: 2px solid #fea93d;
}
</style>
