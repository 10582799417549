import { render, staticRenderFns } from "./UserOnboardingModal.vue?vue&type=template&id=d3dddf12&scoped=true&"
import script from "./UserOnboardingModal.vue?vue&type=script&lang=js&"
export * from "./UserOnboardingModal.vue?vue&type=script&lang=js&"
import style0 from "./UserOnboardingModal.vue?vue&type=style&index=0&id=d3dddf12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3dddf12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VIcon,VImg})
