<template>
  <div :class="[wrapperClasses, 'vue-suggestion']">
    <div
      :class="[{ vs__selected: value }, inputWrapperClasses, 'vs__input-group']"
    >
      <input
        v-model="searchText"
        :type="type"
        :class="[inputClasses, 'vs__input']"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLen"
        @blur="blur"
        @focus="focus"
        @input="inputChange"
        @keydown.enter.prevent="keyEnter"
        @keydown.up.prevent="keyUp"
        @keydown.down.prevent="keyDown"
        id="slide-library-search"
        autocomplete="off"
      />
      <v-progress-circular
        v-if="loading"
        :size="20"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <slot name="searchSlot" v-else />
    </div>
    <slot v-if="showList && !loading" name="suggestionList">
      <div :class="[suggestionListClasses, 'vs__list']">
        <div v-if="items.length === 0 && noResultShown" class="no-result-found">
          {{ $t('build.step1.storylineFieldNoResult') }}
        </div>
        <template v-else>
          <div
            v-for="(group, index) in itemGroups"
            :class="suggestionGroupClasses"
            :key="index"
          >
            <div
              v-if="itemGroups.length > 1 || group.header"
              :class="[suggestionGroupHeaderClasses, 'vs__group-header']"
            >
              {{ group.header }}
            </div>

            <div
              v-for="item in group.items"
              :key="item.vsItemIndex"
              :class="[
                { 'vs__item-active': item.vsItemIndex === cursor },
                suggestionItemWrapperClasses,
                'vs__list-item',
              ]"
              @click="selectItem(item, 'NA')"
              @mouseover="cursor = item.vsItemIndex"
            >
              <div
                :class="suggestionItemClasses"
                :is="itemTemplate"
                :item="item"
              />
            </div>
          </div>
          <!-- Recent Queries for Slides Library Search-->
          <div v-if="getRecentSearchTexts.length != 0 && !searchText">
            <div class="recent">
              <div class="suggested">
                <img
                  contain
                  height="20px"
                  width="20px"
                  :src="'/assets/icons/search/history-slide-search.svg'"
                  class="suggestionimage"
                />
                <span style="color: #7b7b7b">{{ $t('search.recent') }}</span>
              </div>
              <span class="clearquery" @click.stop="clearsearches">
                {{ $t('common.clear') }}
              </span>
            </div>
            <div
              v-for="(item, index) in getRecentSearchTexts"
              :key="index"
              :class="[suggestionItemWrapperClasses, 'vs__list-item']"
              @click="selectItem(item, 'Recent')"
              @mouseover="cursor = item.vsItemIndex"
            >
              <div
                :class="suggestionItemClasses"
                :is="itemTemplate"
                :item="item"
              />
            </div>
          </div>
          <!-- suggested Queries for Slides Library Search-->
          <div v-if="!searchText && filterOptionCategory === 'brand_slides'">
            <div class="suggested">
              <img
                class="suggestionimage"
                height="20px"
                width="20px"
                :src="'/assets/icons/search/message-check-slide-search.svg'"
                color="primary"
              />
              <span style="color: #7b7b7b">Suggested</span>
            </div>
            <div
              v-for="item in suggestedSlideQueries"
              :key="item.vsItemIndex"
              :class="[suggestionItemWrapperClasses, 'vs__list-item']"
              @click="selectItem(item, 'Suggested')"
              @mouseover="cursor = item.vsItemIndex"
            >
              <div
                :class="suggestionItemClasses"
                :is="itemTemplate"
                :item="item"
              />
            </div>
          </div>
          <div v-if="!searchText && isBPElanding">
            <div class="suggested">
              <img
                class="suggestionimage"
                height="20px"
                width="20px"
                :src="'/assets/icons/search/message-check-slide-search.svg'"
                color="primary"
              />
              <span style="color: #7b7b7b">Suggested</span>
            </div>
            <div
              v-for="item in suggestedSlideQueriesForBPE"
              :key="item.vsItemIndex"
              :class="[suggestionItemWrapperClasses, 'vs__list-item']"
              @click="selectItem(item, 'Suggested')"
              @mouseover="cursor = item.vsItemIndex"
            >
              <div
                :class="suggestionItemClasses"
                :is="itemTemplate"
                :item="item"
              />
            </div>
          </div>
        </template>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import utils from './utils';
import { clearSearchQueryLog } from '@/utils/api-helper';

function getDefault(key) {
  const value = utils.options[key];
  if (typeof value === 'undefined') {
    return utils.options[key];
  }
  return value;
}

export default {
  name: 'SlideSuggestions',
  props: {
    filterOptionCategory: {
      type: String,
      default: '',
    },
    noResultShown: {
      type: Boolean,
    },
    itemTemplate: {
      type: Object,
      default: () => getDefault('itemTemplate'),
    },
    minLen: {
      type: Number,
      default: () => getDefault('minLen'),
    },
    maxLen: {
      type: Number,
      default: () => getDefault('maxLen'),
    },
    value: {
      type: [Object, String, Number],
      default: () => getDefault('value'),
    },
    setLabel: {
      type: Function,
      default: () => getDefault('setLabel'),
    },
    items: {
      type: Array,
      default: () => getDefault('items'),
    },
    disabled: {
      type: Boolean,
      default: () => getDefault('disabled'),
    },
    loading: {
      type: Boolean,
      default: () => getDefault('loading'),
    },
    values: {
      type: [Object, String, Number],
      default: () => getDefault('value'),
    },
    placeholder: {
      type: String,
      default: () => getDefault('placeholder'),
    },
    inputClasses: {
      type: String,
      default: () => getDefault('inputClasses'),
    },
    wrapperClasses: {
      type: String,
      default: () => getDefault('wrapperClasses'),
    },
    inputWrapperClasses: {
      type: String,
      default: () => getDefault('inputWrapperClasses'),
    },
    suggestionListClasses: {
      type: String,
      default: () => getDefault('suggestionListClasses'),
    },
    suggestionGroupClasses: {
      type: String,
      default: () => getDefault('suggestionGroupClasses'),
    },
    suggestionGroupHeaderClasses: {
      type: String,
      default: () => getDefault('suggestionGroupHeaderClasses'),
    },
    suggestionItemWrapperClasses: {
      type: String,
      default: () => getDefault('suggestionItemWrapperClasses'),
    },
    suggestionItemClasses: {
      type: String,
      default: () => getDefault('suggestionItemClasses'),
    },
    type: {
      type: String,
      default: () => getDefault('type'),
    },
  },
  data() {
    return {
      searchText: '',
      showList: false,
      cursor: -1,
      clearClicked: false,
      dropDownCategory: '',
      suggestedSlideQueries: [
        {
          category: '',
          name: 'Award',
          type: 'Slides',
        },
        {
          category: '',
          name: 'Divider',
          type: 'Slides',
        },
        {
          category: '',
          name: 'Agenda',
          type: 'Slides',
        },
      ],
      suggestedSlideQueriesForBPE: [
        {
          category: '',
          name: 'Mission',
          type: 'Slides',
        },
        {
          category: '',
          name: 'Options',
          type: 'Slides',
        },
        {
          category: '',
          name: 'Strategy',
          type: 'Slides',
        },
      ],
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'recentSlideSearches',
      'recentBPESlideSearches',
    ]),
    isBPElanding() {
      return this.$route?.name === 'best-practice-library';
    },
    getRecentSearchTexts() {
      return this.isBPElanding
        ? this.recentBPESlideSearches
        : this.recentSlideSearches;
    },
    itemGroups() {
      return this.items.reduce((prv, crr, index) => {
        const groupName = crr.suggestionGroup || '';
        const item = {
          ...crr,
          vsItemIndex: index,
        };
        const foundGroup = prv.find((gr) => gr.header === groupName);
        if (foundGroup) {
          foundGroup.items.push(item);
        } else {
          prv.push({
            header: groupName,
            items: [item],
          });
        }
        return prv;
      }, []);
    },
  },
  watch: {
    value: {
      handler(value) {
        if (!value) {
          return;
        }
        this.searchText = this.setLabel(value);
      },
      deep: true,
    },
    values: {
      handler(values) {
        if (!values) {
          return;
        }
        this.searchText = this.values;
      },
    },
    loading(val) {
      if (!val) {
        this.showList = this.isAbleToShowList();
      }
    },
  },
  created() {
    this.checkMissingProps();
  },
  async mounted() {
    if (this.value) {
      this.searchText = this.setLabel(this.value);
    }
    if (this.values) {
      this.searchText = this.values;
    }
  },
  methods: {
    ...mapActions('users', [
      'setSlideRecentQueriesOnMount',
      'setBPESlideRecentQueriesOnMount',
    ]),
    inputChange() {
      this.showList = this.isAbleToShowList();
      this.cursor = 0;
      this.$emit('changed', this.searchText);
    },
    isAbleToShowList() {
      return (this.searchText || '').length >= this.minLen;
    },
    checkMissingProps() {
      if (!this.itemTemplate) {
        console.warn(
          'You need to pass `template` as the suggestion list item template',
        );
      }
    },
    async focus() {
      this.$emit('click', true);
      this.$emit('focus', this.searchText);
      this.showList = this.isAbleToShowList();
    },
    blur() {
      if (!this.clearClicked) {
        this.$emit('click', false);
        this.$emit('blur', this.searchText);
        // set timeout for the click event to work
        setTimeout(() => {
          this.showList = false;
        }, 500);
      }
    },
    selectItem({ ...item } = {}, from = '') {
      this.dropDownCategory = from;
      if (item) {
        this.searchText = this.setLabel(item);
        this.$emit('selected', item);
      }
      this.$emit('input', item);
    },
    keyUp() {
      this.$emit('key-up', this.searchText);
      if (this.cursor > 0) {
        this.cursor -= 1;
      }
    },
    keyDown() {
      this.$emit('key-down', this.searchText);
      if (this.cursor < this.items.length - 1) {
        this.cursor += 1;
      }
    },
    keyEnter($event) {
      $event.target.blur();
      this.$emit('enter', this.searchText);
      this.showList = false;
    },
    async clearsearches($event) {
      this.clearClicked = true;
      let payload;
      if (this.isBPElanding) {
        this.setBPESlideRecentQueriesOnMount([]);
        payload = {
          type: 'BPLSlide',
        };
      } else {
        this.setSlideRecentQueriesOnMount([]);
        payload = {
          type: 'Slide',
          templateID: this.currentUser?.theme?.id,
        };
      }
      clearSearchQueryLog(payload);
      setTimeout(() => {
        this.clearClicked = false;
      }, 300);
      $event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-suggestion {
  align-items: center;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  display: flex;
  height: 40px !important;
  justify-content: flex-start;
  margin-left: 2px;
  padding: 5px 5px 5px 5px;
  position: relative;
  width: 100%;

  .vs__input-group {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .vs__list {
    background-color: white;
    border: 0;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    width: 100%;
    margin-left: -2px !important;
    text-align: left;
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
    top: calc(100% + 5px);
    z-index: 10;
    position: absolute;

    .no-result-found {
      display: flex;
      align-items: center;
      padding: 0 1rem;
      height: 50px;
    }
  }
}

.vue-suggestion .vs__input-group .vs__input {
  background-clip: padding-box;
  background-color: transparent;
  background-image: none;
  border: none;
  color: #464a4c;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.25;
  margin: 0 auto;
  padding: 0.5rem 0.7rem;
  outline: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
  margin-bottom: 12px;
}

.vue-suggestion .vs__list .vs__list-item:first-child {
  margin-top: 10px;
}

.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}

.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
}

.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}

.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #f3f6fa;
}

.v-select {
  width: 98%;
  flex: initial;
}

.v-input__slot {
  margin-bottom: 0px;
  &:before {
    border: none;
  }
}

.v-input__icon {
  display: none;
}

.v-text-field__details {
  display: none;
}

.v-text-field__details {
  display: none;
}
.adduser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 75px;
  padding: 10px 10px 10px 16px;
  cursor: pointer;
}
.addusertext {
  display: flex;
  flex-direction: column;
  min-width: 505px;
  margin-left: -2px;
  align-items: flex-start;
  padding-left: 10px;
  line-height: 1.2;
}

.suggested {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  margin: 10px;
  padding-left: 6px;
  position: relative;
}
.searchResults {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  padding: 0 0.5rem 0 1rem;
  position: relative;
  &:hover {
    background-color: #eee !important;
  }
}
.suggestionimage {
  margin-right: 7px;
}
.clearquery {
  color: #21a7e0;
  cursor: pointer;
}
.recent {
  display: flex;
  align-items: center;
  margin-right: 33px;
  justify-content: space-between;
}
.cardcontainer {
  position: sticky;
  bottom: 0;
  background: white;
  &:hover {
    background-color: #eee !important;
  }
}
.findcontainer {
  margin-left: 29px;
  font-size: 14px;
}

.pressEnter {
  font-size: 14px;
  width: 321px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.seperator {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  border-width: thin 0 0 0;
}
</style>
