var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-selector"},[_c('p',{staticClass:"info-text"},[_vm._v(_vm._s('Select template for your slides'))]),_c('v-text-field',{staticClass:"template-search-input",attrs:{"solo":"","rounded":"","single-line":"","hide-details":"","autocomplete":"off","placeholder":_vm.$t('profile.searchTemplates')},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.sortedThemeArray()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.templateSearch.length)?_c('v-icon',{staticClass:"icon",attrs:{"name":"global-cross-icon","color":"#21a7e0"},domProps:{"textContent":_vm._s('mdi-close')},on:{"click":function($event){return _vm.clearSearch()}}}):_vm._e(),_c('v-icon',{staticClass:"icon",attrs:{"name":"global-search-icon","color":_vm.templateSearch.length ? '#21a7e0' : ''},domProps:{"textContent":_vm._s("mdi-magnify")}})]},proxy:true}]),model:{value:(_vm.templateSearch),callback:function ($$v) {_vm.templateSearch=$$v},expression:"templateSearch"}}),[(_vm.sections.length)?_c('div',{ref:"container",staticClass:"templates-list"},[_vm._l((_vm.sections),function(section){return [(section.themes.length)?_c('div',{key:section.header},[(section.header)?_c('h4',{staticClass:"section-title"},[_vm._v(" "+_vm._s(section.header)+" ")]):_vm._e(),_c('div',{staticClass:"section-list"},_vm._l((section.themes),function(item){return _c('v-card',{key:item.id,staticClass:"template",class:{
                'template-disabled': item.disabled || item.isDisabled,
                'template-selected': _vm.isCurrentSelected(item.code),
              },style:({
                'background-color': _vm.isCurrentSelected(item.code)
                  ? '#F5FCFF'
                  : '',
              }),attrs:{"ripple":!item.disabled && !item.isDisabled,"name--auto":("slides-advanced-filter-template-item" + (_vm.isCurrentSelected(item.code) ? '--active' : ''))},on:{"click":function($event){return _vm.handleClickTheme(item)}}},[_c('v-tooltip',{attrs:{"top":"","attach":"","absolute":"","max-width":"200","disabled":!item.disabled && !item.isDisabled,"content-class":'center-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_c('div',{staticClass:"template-header"},[_c('div',{staticClass:"template-header__left"},[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"template-title"},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),(item.isPrezentTemplate)?_c('span',{staticClass:"template-header__icon"},[_c('img',{staticClass:"prezent-icon",attrs:{"src":"/assets/img/prezent_avatar.svg","alt":"","width":"22px"}})]):_vm._e()],1),_c('div',{staticClass:"template-header__right"},[(item.isMarked)?_c('span',{staticClass:"template-header__icon"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"template-header__marked",attrs:{"color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.stopPropogation($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(" mdi-account-heart-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Template recommended by your team admin")])])],1):_vm._e(),(item.isPinned)?_c('span',{staticClass:"template-header__icon"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"template-header__pinned",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.stopPropogation($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pin ")])],1)]}}],null,true)},[_c('span',[_vm._v("Pinned")])])],1):_vm._e(),(item.isDisabled)?_c('span',{staticClass:"template-header__icon"},[_c('v-btn',{attrs:{"color":"primary","icon":""}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)],1):_vm._e()])]),_c('v-row',[_c('v-col',{staticClass:"available-theme-img"},[_c('img',{attrs:{"src":item.thumbnailURL,"alt":""}})]),_c('v-col',{staticClass:"example-slide-img"},[_c('img',{attrs:{"src":item.sampleSlide
                              ? item.sampleSlideURL
                              : item.thumbnailURL,"alt":""}})])],1)],1)]}}],null,true)},[(item.isDisabled && _vm.currentUser.user.role === 'User')?_c('span',[_vm._v(" This template is disabled by your team admin. Click to request access. ")]):(item.isDisabled)?_c('span',[_vm._v(" This template is disabled for your team. You can enable it in Templates tab under Profile. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.comingSoon'))+" ")])])],1)}),1)]):_vm._e()]})],2):_c('div',{staticClass:"not-found-container"},[_c('img',{attrs:{"src":"/assets/img/no-results-found-templates.svg","height":"250","width":"250","alt":""}}),_c('div',{staticClass:"heading mb-1 mt-6"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.noTemplatesFound'))+" ")]),_c('v-btn',{staticClass:"primary-button",attrs:{"center":"","height":"35","width":"160","rounded":"","filled":"","color":"primary"},on:{"click":_vm.handleAddMore}},[_vm._v(" "+_vm._s(_vm.$t('build.step3.addTemplates'))+" ")])],1),_c('div',{staticClass:"tip-bar",class:_vm.sections.length ? '' : 'no-results'},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('tagSlides.templateProTipText'))+" ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }