import { trackMatomoEvent } from './MatomoAnalyticsHandler';

/**
 * The following are the event properties in order as mentione in this requirement document
 * Order should not be modified
 */
export const NA = 'na';
export const TD_USER = 'user';
export const TD_AUD = 'aud';
export const TD_COMPANY = 'company';
export const TD_TEAM = 'team';
export const TD_ISDOWNLOAD = 'isdownload';
export const TD_DOWNLOADTYPE = 'downloadtype';
export const TD_ISFAVORITED = 'isfavorited';
export const TD_IDEA = 'idea';
export const TD_CONSTRUCT = 'construct';
export const TD_SLIDEID = 'slideID';
export const TD_NODECOUNT = 'nodecount';
export const TD_QUERY = 'query';
export const TD_HYBRIDCHIP = 'hybridchip';
export const TD_PREZNAME = 'prezname';
export const TD_PREZTYPE = 'preztype';
export const TD_TEMPLATE = 'template';
export const TD_TIMESTAMP = 'timestamp';
export const TD_FAQCATEGORY = 'faqcategory';
export const TD_FAQTITLE = 'faqtitle';
export const TD_TUTORIALCHIP = 'tutorialchip';
export const TD_TUTORIALTITLE = 'tutorialtitle';
export const TD_WALKTHROUGHTITLE = 'walkthroughtitle';
export const TD_QUICKTIPSCATEGORY = 'quicktipscategory';
export const TD_QUICKTIPSTITLE = 'quicktipstitle';
export const TD_SLIDESRESULTS = 'slidesresults';
export const TD_PEOPLERESULTS = 'peopleresults';
export const TD_LEARNRESULTS = 'learnresults';
export const TD_PREZENTATIONRESULTS = 'prezentationresults';
export const TD_KNOWRESULTS = 'knowresults';
export const TD_HELPRESULTS = 'helpresults';
export const TD_HASCARTOON = 'hascartoon';
export const TD_HASICONS = 'hasicons';
export const TD_HASIMAGES = 'hasimages';
export const TD_IS3D = 'is3d';
export const TD_SORTTYPE = 'SortType';
export const TD_SIMILARSLIDETYPE = 'SimilarSlideType';
export const TD_KNOWSOURCE = 'KnowSource';
export const TD_KNOWADDMORETYPE = 'KnowAddMoreType';
export const TD_KNOWADDMOREDESC = 'KnowAddMoreDesc';
export const TD_KNOWSUGGESTDESC = 'KnowSuggestDesc';
export const TD_KNOWBESTPRACTOGGLE = 'KnowBestPracToggle';
export const TD_VIEWTYPE = 'ViewType';
export const TD_IDEABEGIN = 'IdeaBegin';
export const TD_IDEAMIDDLE = 'IdeaMiddle';
export const TD_IDEAEND = 'IdeaEnd';
export const TD_NUMBEROFSLIDES = 'NumberOfSlides';
export const TD_ISPRIVATE = 'isPrivate';
export const TD_ISEDITOR = 'isEditor';
export const TD_ISVIEWER = 'isViewer';
export const TD_ISREVIEWER = 'isReviewer';
export const TD_COMMENT = 'Comment';
export const TD_LEARNCOURSENAME = 'LearnCourseName';
export const TD_LEARNMODULENAME = 'LearnModuleName';
export const TD_LEARNCATEGORYNAME = 'LearnCategoryName';
export const TD_LEARNCONTENTNAME = 'LearnContentName';
export const TD_LINKEDINSHARETYPE = 'LinkedInShareType';
export const TD_LEARNVIDEOSEEKBARTIME = 'LearnVideoSeekBarTime';
export const TD_LEARNVIDEOLENGTH = 'LearnVideoLength';
export const TD_COURSEMODULEPROGRESS = 'CourseModuleProgress';
export const TD_PREZENTATIONUSERACCESS = 'PrezentationUserAccess';
export const TD_PREZENTATIONAUTHOR = 'PrezentationAuthor';
export const TD_USER_NAME = 'User_Name';
export const TD_NORESULTSFOUND = 'NoResultsFound';
export const TD_AUDIENCE_TYPE = 'audience_type';
export const TD_AUD_FIRST_NAME = 'Aud_First_Name';
export const TD_AUD_LAST_NAME = 'Aud_Last_Name';
export const TD_AUD_EMAIL = 'Aud_Email';
export const TD_AUD_COMPANY_NAME = 'Aud_Company_Name';
export const TD_AUD_ADDITION_TYPE = 'Aud_addition_type';
export const TD_AUD_ROLE = 'aud_role';
export const TD_AUD_GROUP_SIZE = 'aud_group_size';
export const TD_AUD_BUSINESS_FUNCTION = 'aud_business_function';
export const TD_FINGERPRINT_SHARING_SETTINGS = 'fingerprint_sharing_settings';
export const TD_PLACEMENT = 'Placement';
export const TD_COMPANY_OF_AUD_TAILORED_FOR = 'company_of_aud_tailored_for';
export const TD_HYBRID_RESULTS_SECTION_CLICK = 'hybrid_results_section_click';
export const TD_HYBRID_NO_RESULTS_BUTTON_CLICK =
  'Hybrid_no_results_button_click';
export const TD_SUBSCRIPTION_TYPE = 'Subscription_Type';
export const TD_ROLE = 'Role';
export const TD_REPLACE_IMAGE_SOURCE = 'Replace_image_source';
export const TD_FROM_TO = 'From_To';
export const TD_FINGERPRINT_INDUSTRY = 'Fingerprint_Industry';
export const TD_FINGERPRINT_GROUP = 'Fingerprint_Group';
export const TD_FINGERPRINT_REGION = 'Fingerprint_Region';
export const TD_FINGERPRINT_JOBTITLE = 'Fingerprint_Jobtitle';
export const TD_DECK_ID = 'Deck_id';
/** Needs to remove this column and use it as value for column -> common_column_name */
export const TD_FLOW = 'flow';
export const TD_COMMON_COLUMN_NAME = 'common_column_name';
export const TD_COMMON_COLUMN_VALUE = 'common_column_value';
export const TD_PREZENTATION_CATEGORY = 'Prezentation_Category';
export const TD_SECTION_NAME = 'Section_name';
export const TD_SECTION_4 = 'Section_4';
export const TD_SECTION_5 = 'Section_5';
export const TD_PREZENTATION_ORIGIN = 'Prezentation_Origin';
export const TD_PREZENTATION_COMPANY_SOURCE = 'Prezentation_company_source';
export const TD_INSTRUCTIONS = 'Instructions';
export const TD_SLIDE_SOURCE = 'Slide_Source';
export const TD_SLIDE_NAME = 'Slide_Name';
export const TD_TIME_SPENT_IN_SECS = 'Time_spent_in_secs';
export const TD_THUMBNAIL_VIEW = 'Thumbnail View';
export const TD_LIST_VIEW = 'List View';
export const TD_INSTRUCTIONS_GIVEN = 'Instructions given';
export const TD_SHARED_FILE_ACCESS =
  'I have shared access with slides@prezentium.com';
export const TD_ADD_COPY_FINAL_DECK =
  'Add a copy of my final deck to my prezentations library';
export const TD_NEW_REQUEST = 'New requests';
export const TD_OVERVIEW = 'Overview';
export const TD_EXPANDED_DETAIL_VIEW = 'Expanded Detailed View';
export const TD_EXPANDED_STATUS_VIEW = 'Expanded status View';
export const TD_EXPANDED_VIEW = 'Expanded View';
export const TD_ABOVE_THE_GENERATED_SLIDE = 'Above the Generated slide';
export const TD_SMART_LAYOUT = 'Smart Layout';
export const TD_SMART_TOOLS = 'Smart Tools';
export const TD_PROMPT_BAR = 'Prompt bar';
export const TD_FEILD = 'Field';
export const TD_FEILD_CHANGED = 'Field Changed';
export const TD_RATING = 'Rating';
export const TD_SLIDE_POSITION_CHANGED = 'Slide position changed';
export const TD_BELOW_THE_SLIDE = 'Below the slide';
export const TD_ICON_ON_TOP = 'Icon at top';
export const TD_CTA_BUTTON = 'CTA button';
export const TD_TOP_PANE = 'Top pane';
export const TD_ICON_AT_THE_TOP = 'Icon at the top';
export const TD_PROMPT_SUGGESTIONS_POPUP = 'Prompt suggestions popup';
export const TD_CHANGE_LAYOUT = 'Change layout';
export const TD_REPLACE_VISUALS = 'Replace visuals';
export const TD_EDIT_NODE_COUNT = 'Edit node count';

/*
 * DO NOT CHANGE THE ORDER OF THE EVENT PROPERTIES AS MENTIONED ABOVE
 */

export const TD_DOWNLOADTYPE_DIRECT = 'Direct';
export const TD_DOWNLOADTYPE_GS = 'Google Slides';
export const TD_REC = 'Rec';
export const TD_RECOMMENDED_FOR_SELECTED_AUDIENCE =
  'Recommended for selected audience';
export const TD_POPULAR = 'Popular';
export const TD_A_TO_Z = 'A-Z';
export const TD_Z_TO_A = 'Z-A';
export const TD_DOWNLOADS = 'Downloads';
export const TD_FAVORITES = 'Favorites';
export const TD_DIAGRAM = 'Diagram';
export const TD_LIST = 'List';
export const TD_MOSAIC = 'Mosaic';
export const TD_COURSE_SHARE_LINKEDIN = 'LinkedIn';
export const TD_COURSE_SHARE_POST = 'Post';
export const TD_PREZ_PRIVATE = 'private';
export const TD_PREZ_EDITOR = 'editor';
export const TD_PREZ_REVIEWER = 'reviewer';
export const TD_PREZ_VIEWER = 'viewer';
export const TD_PREZ_AUTHOR = 'author';

export const TD_AUDIENCE_ORIGIN_HEADER = 'header';
export const TD_AUDIENCE_ORIGIN_NP = 'NP';
export const TD_AUDIENCE_ORIGIN_PREZENTATIONS = 'Prezentations';
export const TD_AUDIENCE_ORIGIN_SLIDES = 'Slides';
export const TD_AUDIENCE_ORIGIN_ZENTREDS = 'zentrends';

export const TD_AUDIENCE_TYPE_INDIVIDUAL = 'Individual';
export const TD_AUDIENCE_TYPE_GROUP = 'Group';
export const TD_AUDIENCE_ADD_TYPE_COMPLETE =
  'complete fingerprint Assessment for your audience';
export const TD_AUDIENCE_ADD_TYPE_REQUEST =
  'Request audience to take fingerprint assessment';
export const TD_AUDIENCE_ADD_TYPE_ZENSENSE =
  'Generate fingerprint using Prezent AI';

export const TD_PROFILE_MY_FINGERPRINT = 'my_fingerprint';
export const TD_PROFILE_CREATED_FINGERPRINTS = 'created_fingerprints';
export const TD_PROFILE_ACCESS_REQUESTS = 'access_requests';
export const TD_PROFILE_EXTERNAL_AUDIENCES = 'external_audiences';
export const TD_SHARE_FINGERPRINT_MFP = 'share_fp_my_fingerprint';
export const TD_SHARE_FINGERPRINT_ZENTRENDS = 'zentrends';
export const TD_SHARE_FINGERPRINT_NAVBAR = 'share_fp_navbar';
export const TD_SHARE_FINGERPRINT_CREATED = 'share_fp_created_fingerprint';
export const TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT =
  'storyline tab with records';
export const TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT_NO_RECORDS =
  'stpryline tab without records';
export const TD_MY_FINGERPRINT = 'My fingerprint';
export const TD_CREATED_FINGERPRINTS = 'Created fingerprints';
export const TD_EXTERNAL_AUDIENCES = 'external audience';
export const MY_PREZENTATION = 'my_prezentation';
export const MY_PREZENTATION_COPIED = 'my_prezentation_copied';
export const SLIDES = 'slides';
export const SLIDE_FAVOURITE = 'SLIDE_FAVOURITE';
export const NODE_CHANGE_COUNT = 'NODE_CHANGE_COUNT';
export const RESET_TO_DEFAULT = 'RESET_TO_DEFAULT';
export const IMAGE_REPLACEMENT = 'IMAGE_REPLACEMENT';
export const EXPANDED_VIEW = 'expanded_view';
export const BUILD = 'build';
export const PREZENTATION_PLACEMENT = 'Prezentation Library';
export const shareFingerPrintVsPlacement = {
  [TD_SHARE_FINGERPRINT_MFP]: TD_MY_FINGERPRINT,
  [TD_SHARE_FINGERPRINT_NAVBAR]: 'top nav bar',
  [TD_SHARE_FINGERPRINT_CREATED]: TD_CREATED_FINGERPRINTS,
};
export const TD_HYBRID_SEARCH = 'hybrid_search';

export const audiencePlacementMap = {
  [TD_AUDIENCE_ORIGIN_HEADER]: 'top nav bar',
  [TD_AUDIENCE_ORIGIN_NP]: 'build',
  [TD_AUDIENCE_ORIGIN_PREZENTATIONS]: 'prezentation',
  [TD_AUDIENCE_ORIGIN_SLIDES]: 'slides',
  [TD_PROFILE_CREATED_FINGERPRINTS]: TD_CREATED_FINGERPRINTS,
  [TD_PROFILE_EXTERNAL_AUDIENCES]: TD_EXTERNAL_AUDIENCES,
  [TD_HYBRID_SEARCH]: 'Hybrid search',
  [TD_AUDIENCE_ORIGIN_ZENTREDS]: 'fingerprints',
};

export const TD_SHARE_PUBLIC_LIMITED = 'Public_limited';
export const TD_SHARE_PUBLIC_FULL = 'Public full';
export const TD_SHARE_SELECT_MEMBERS = 'select members';
export const TD_HYBRID_RESULTS_SECTION_SLIDES = 'Slides';
export const TD_HYBRID_RESULTS_SECTION_PREZ = 'Prezentations';
export const TD_HYBRID_RESULTS_SECTION_KNOW = 'Know';
export const TD_HYBRID_RESULTS_SECTION_TEMPLATES = 'Templates';
export const TD_HYBRID_RESULTS_SECTION_LEARN = 'Learn';
export const TD_HYBRID_RESULTS_SECTION_PEOPLE = 'People';
export const TD_HYBRID_RESULTS_SECTION_HELP = 'Help';
export const TD_HYBRID_RESULTS_SECTION_STORYLINES = 'Storylines';
export const TD_HYBRID_RESULTS_SECTION_ALL = 'All';
export const TD_HYBRID_NO_RESULTS_ALL_TRYNOW = 'All_Trynow';
export const TD_HYBRID_NO_RESULTS_SLIDES_TRYNOW = 'slides_Trynow';
export const TD_HYBRID_NO_RESULTS_SENDSUUPPORTREQUEST = 'sendsuupportrequest';
export const TD_HYBRID_NO_RESULTS_ADDNEWTEMPLATE = 'Addnewtemplate';
export const TD_HYBRID_NO_RESULTS_BUIDNEWPREZENTATION = 'Buidnewprezentation';
export const TD_SUBSCRIPTION_TYPE_TRIAL = 'Trial';
export const TD_SUBSCRIPTION_TYPE_PAID = 'Paid';
export const TD_SUBSCRIPTION_TYPE_PREZENT_PREMIUM = 'Prezent Premium';
export const TD_SUBSCRIPTION_TYPE_PREZENT_STANDARD = 'Prezent Standard';
export const TD_SUBSCRIPTION_TYPE_ENTERPRISE = 'Enterprise';
export const TD_REPLACE_IMAGE_SOURCE_BRAND = 'Brand';
export const TD_REPLACE_IMAGE_SOURCE_LIBRARY = 'Library';
export const TD_REPLACE_IMAGE_SOURCE_UPLOAD = 'Upload';
export const TD_SHARE_PRIVATE = 'Private';
export const TD_STORYLINE_ORIGIN_TABLE = 'storyline_table';
export const TD_STORYLINE_ORIGIN_DETAIL = 'storyline_detail';
export const TD_PREZ_ADVANCED_FILTER_MY_DOWNLOADS = 'My Downloads';
export const TD_PREZ_ADVANCED_FILTER_MY_FAVORITES = 'My Favorites';
export const TD_PREZ_ADVANCED_FILTER_REVIEW = 'Prezentations to Review';
export const TD_FINGERPRINT_ADD_AUDIENCE_COMPARE = 'compare fingerprints';
export const TD_FINGERPRINT_ADD_AUDIENCE_GROUP = 'Group Insights';
export const TD_SHARE_THE_GIFT_OF_FINGERPRINT = 'Share the gift of fingerprint';
export const TD_ORIGIN_FINGERPRINT = 'fingerprint';
export const TD_ADD_AUDIENCE_REQUESTED = 'Add-audience-requested';
export const TD_ADD_AUDIENCE_COLLEAGUE_CREATED =
  'Add-audience-colleague-created';
export const TD_RETAKE_FINGERPRINT_TEST = 'Retake-fingerprint-test';
export const TD_FIRST_TIME_USER_LOGIN = 'First-time-user-login';
export const TD_FP_NEW_AUDIENCE = 'add_new_aud';
export const TD_FP_PROFILE = 'fp_profile';
export const TD_FP_MY = 'my_fp';
export const TD_FP_OTHER = 'other_fp';
export const TD_REMINDER = 'reminder_fp';
export const TD_MY_FP_IN_PROFILE = 'My Fingerprint in Profile';
export const TD_FP_FEATURE = 'Fingerprints Feature';
export const TD_FP_TEST_PLACEMENT = 'Fingerprint Test';
export const TD_FP_CREATED_FP = 'Created Fingerprints in Profile';
export const TD_FP_SEARCH = 'Search';
export const TD_SECTION_CHANGED = 'Section-changed';
export const TD_PREZENTATION_TYPE = 'Prezentation Type';
export const TD_MY_FAVORITES = 'My favourites';
export const TD_MY_DOWNLOADS = 'My downloads';
export const TD_PREZENTATION_ACCESS = 'Prezentation Access';
export const TD_PREZENTATION_AUTHOR = 'Prezentation Author';
export const TD_CREATED_ON = 'Created on';
export const TD_GRAPHICS = 'Graphics';
export const TD_DIAGRAMS = 'Diagrams';
export const TD_NODE_COUNT = 'Node Count';
export const TD_SOURCE = 'Source';
export const TD_FP_TEST = 'fingerprint_test';
export const TD_FP_RETAKE = 'Retake fingerprint test';
export const TD_EDIT = 'Edit';
export const TD_SET_AS_DEFAULT = 'Set as Default';
export const TD_CANCEL = 'Cancel';
export const TD_BUY_ADDITIONAL_LICENCSE = 'Buy Additional License';
export const TD_RENEW = 'Renew';
export const TD_UPGRADE = 'Upgrade';
export const TD_CARD_ADDITION = 'Card Addition';
export const TD_DELETE = 'Delete';
export const TD_CLICK_STATUS = 'Click status';
export const TD_CHECKED = 'Checked';
export const TD_UNCHECKED = 'Unchecked';
export const TD_FINGERPRINT_TYPE = 'Fingerprint Type';
export const TD_COUNT_OF_AUDIENCES_COMPARED = 'Count of audiences compared';
export const TD_CREATED_FROM = 'Created From';
export const TD_ENABLED = 'Enabled';
export const TD_DISABLED = 'Disabled';
export const TD_USAGE_STATISTICS = 'Usage statistics';
export const TD_ACTIVE_USERS = 'Active users';
export const TD_IMPACT = 'Impact';
export const TD_OVERNIGHT_PREZENTATIONS_UTILIZATION =
  'Overnight Prezentations Utilization';
export const TD_FILTER_SELECTED = 'filter selected';
export const TD_CUSTOM = 'Custom';
export const TD_EVENT_START = 'Event Start';
export const TD_EVENT_END = 'Event End';
export const TD_TIME_SPENT = 'Time spent';
export const TD_REST_OF_THE_SLIDES_IN_UI = 'Rest of the Slides in UI';
export const TD_MAXIMIZE = 'Maximize';
export const TD_MINIMIZE = 'Minimize';
export const TD_CLICKED_TO = 'clicked to';
export const TD_STEP2 = 'Step 2';
export const TD_STEP3 = 'Step 3';
export const TD_UPLOAD_CTA = 'Upload CTA';
export const TD_UPLOAD_SECTION = 'Upload section';
export const TD_SELECT_ALL_CHECKBOX = 'Select all check box';
export const TD_TEMPLATE_SELECTED = 'Template selected';
export const TD_RECOMMENDED = 'Recommended';
export const TD_OTHER = 'Other';
export const TD_DOWNLOAD_CTA = 'Download CTA at bottom';
export const TD_DOWNLOAD_ICON = 'Top of the pane';
export const TD_UPLAODED_HUNDRED_PERCENT_COMPLIANT_DECK =
  'uploaded 100 percent complaint deck';
export const TD_YES = 'Yes';
export const TD_NO = 'No';
export const TD_THUMB_SELECTED = 'Thumb selected';
export const TD_THUMBS_UP = 'Thumbs up';
export const TD_THUMBS_DOWN = ' Thumbs down';
export const TD_TOGGLED_TO = 'Toggled to';
export const TD_ON = 'On';
export const TD_OFF = 'Off';
export const TD_SLIDE_SELECTION = ' First step - Slide selection';
export const TD_TEMPLATE_SELECTION = 'Second Step - Template selection';
export const TD_FINAL_STEP = 'Final Step - Suggestions';
export const TD_DECK = 'Deck';
export const TD_SLIDE = 'Slide';
export const TD_CATEGORY_ACCEPTED = 'Category Accepted';
export const TD_CATEGORY_REJECTED = 'Category Rejected';
export const TD_SUGGESTION_ACCEPTED = 'Suggestion Accepted';
export const TD_SUGGESTION_REJECTED = 'Suggestion Rejected';
export const TD_COLOR_TRANSITION = 'color transition';
export const TD_NUMBER_OF_SLIDES_UPLOADED = 'number of slides uploaded';
export const TD_RECOMMENDED_TEMPLATE = 'Recommended Template';
export const TD_ISDOWNLOAD_VALUE = 1;
export const TD_TEMPLATE_SWITCHED = 'Template switched to';
export const TD_TEMPLATE_CHANGED = 'Template changed to';
export const TD_NODE_EDITED_TO = 'Node edited to';
export const TD_EMOTION_SELECTED = 'Emotion selected';
export const TD_TONE_SELECTED = 'Tone selected';
export const TD_FORMAT_SELECTED = 'Fomat selected';
// export const TD_INSTRUCTIONS = 'Instructions';
export const TD_VIEW_SELECTED = 'view selected';
export const TD_COLUMN_VIEW = 'Column view';
export const TD_GRID_VIEW = 'Grid view';
export const TD_SMART_LAYOUT_PLACEMENT = 'Smart tool';
export const TD_UPLOAD_BAR_PLACEMENT = 'Upload bar';
export const TD_RECENT_OR_SUGGESTED_OR_NA = 'Recent or Suggested or na';
export const TD_BEFORE_CLICK_ON_REVIEW_DETAILS =
  'Before click on Review details';
export const TD_AFTER_CLICK_ON_REVIEW_DETAILS = 'After click on Review details';
export const TD_PREZENTATION_LIBRARY = 'Prezentations Library';
export const TD_SLIDES_LIBRARY = 'Slides Library';
export const TD_SELECTED_ACCELERATOR_TYPE = 'Selected Accelerator type';
export const TD_LIBRARY = 'Library';
export const TD_DOWNLOAD_AS_PDF = 'Downloaded as PDF';
export const TD_TAB = 'tab';
export const TD_ISRESTRICTED = 'isrestricted';
export const TD_NO_OF_BESTPRACTICES_EDITED = 'No of best practices edited';
export const TD_NO_OF_BESTPRACTICES_DELETED = 'No of best practices deleted';
export const TD_NO_OF_BESTPRACTICES_ADDED = 'No of best practices added';
export const TD_PLACE_IN_STEP3 = 'place in step 3';
export const TD_ON_SLIDE = 'On Slide';
export const TD_ON_TOP = 'On top';
export const TD_OTHER_ACTIONS = 'Other actions';
export const TD_PLACE = 'place';
export const TD_CROSS_FEATURE = 'cross feature';
export const TD_FILE_SIZE = 'file size';
export const TD_TEMPLATE_CONVERTER = 'Template converter';
export const TD_MAKE_100_PERCENT_COMPLIANCE_ACCEPT_ALL_SUGGESTIONS =
  'Make 100% compliant or Accept all suggestions';
export const TD_MAKE_100_PERCENT_COMPLIACE = 'Make 100% compliant';
export const TD_CHECK_BOXES_SELECTED = 'Check boxes selected';
export const TD_ACCEPT_ALL_SUGGESTIONS = ' Accept all suggestions';
export const TD_SUGGESTED_QUERY = 'Suggested query';
export const TD_UPLOAD = 'Upload';
export const TD_PREZENT = 'Prezent';
export const TD_FROM_SNACK_BAR = 'From Snack bar';
export const TD_FROM_ADVANCED_FILTER = 'From Advanced filter';
export const TD_FROM_SNACK_BAR_SLIDES = 'From Snack bar_Slides';
export const TD_FROM_ADVANCED_FILTER_SLIDES = 'From Advanced filter_Slides';
export const TD_FROM_SNACK_BAR_DECKS = 'From Snack bar_Decks';
export const TD_FROM_ADVANCED_FILTER_DECKS = 'From Advanced filter_Decks';
export const TD_FULL_ANONYMIZE = 'Full Anonymize';
export const TD_PARTIAL_ANONYMIZE = 'Partial Anonymize';
export const TD_DESIGNER_NEW_POPUP = 'new template popup';
export const TD_DESIGNER_UPLOAD = 'upload any slide/deck';
export const TD_DESIGNER_ALIGNED = 'better brand aligned slide';
export const TD_DESIGNER_ADHERED = 'better brand adhered slide';
export const TD_DESIGNER_IMAGES = 'better images';
export const TD_PROFILE = 'Profile';
export const TD_AFTER_LOGIN = 'Right After Login';
/*
 * DO NOT CHANGE THE ORDER OF THE EVENT PROPERTIES AS MENTIONED BELOW
 */
export const getDefaultTrackingData = () => {
  const trackingData = new Map();
  trackingData.set(TD_USER, NA);
  trackingData.set(TD_AUD, NA);
  trackingData.set(TD_COMPANY, NA);
  trackingData.set(TD_TEAM, NA);
  trackingData.set(TD_ISDOWNLOAD, NA);
  trackingData.set(TD_DOWNLOADTYPE, NA);
  trackingData.set(TD_ISFAVORITED, NA);
  trackingData.set(TD_IDEA, NA);
  trackingData.set(TD_CONSTRUCT, NA);
  trackingData.set(TD_SLIDEID, NA);
  trackingData.set(TD_NODECOUNT, NA);
  trackingData.set(TD_QUERY, NA);
  trackingData.set(TD_HYBRIDCHIP, NA);
  trackingData.set(TD_PREZNAME, NA);
  trackingData.set(TD_PREZTYPE, NA);
  trackingData.set(TD_TEMPLATE, NA);
  trackingData.set(TD_TIMESTAMP, '');
  trackingData.set(TD_FAQCATEGORY, NA);
  trackingData.set(TD_FAQTITLE, NA);
  trackingData.set(TD_TUTORIALCHIP, NA);
  trackingData.set(TD_TUTORIALTITLE, NA);
  trackingData.set(TD_WALKTHROUGHTITLE, NA);
  trackingData.set(TD_QUICKTIPSCATEGORY, NA);
  trackingData.set(TD_QUICKTIPSTITLE, NA);
  trackingData.set(TD_SLIDESRESULTS, NA);
  trackingData.set(TD_PEOPLERESULTS, NA);
  trackingData.set(TD_LEARNRESULTS, NA);
  trackingData.set(TD_PREZENTATIONRESULTS, NA);
  trackingData.set(TD_KNOWRESULTS, NA);
  trackingData.set(TD_HELPRESULTS, NA);
  trackingData.set(TD_HASCARTOON, NA);
  trackingData.set(TD_HASICONS, NA);
  trackingData.set(TD_HASIMAGES, NA);
  trackingData.set(TD_IS3D, NA);
  trackingData.set(TD_SORTTYPE, NA);
  trackingData.set(TD_SIMILARSLIDETYPE, NA);
  trackingData.set(TD_KNOWSOURCE, NA);
  trackingData.set(TD_KNOWADDMORETYPE, NA);
  trackingData.set(TD_KNOWADDMOREDESC, NA);
  trackingData.set(TD_KNOWSUGGESTDESC, NA);
  trackingData.set(TD_KNOWBESTPRACTOGGLE, NA);
  trackingData.set(TD_VIEWTYPE, NA);
  trackingData.set(TD_IDEABEGIN, NA);
  trackingData.set(TD_IDEAMIDDLE, NA);
  trackingData.set(TD_IDEAEND, NA);
  trackingData.set(TD_NUMBEROFSLIDES, NA);
  trackingData.set(TD_ISPRIVATE, NA);
  trackingData.set(TD_ISEDITOR, NA);
  trackingData.set(TD_ISVIEWER, NA);
  trackingData.set(TD_ISREVIEWER, NA);
  trackingData.set(TD_COMMENT, NA);
  trackingData.set(TD_LEARNCOURSENAME, NA);
  trackingData.set(TD_LEARNMODULENAME, NA);
  trackingData.set(TD_LEARNCATEGORYNAME, NA);
  trackingData.set(TD_LEARNCONTENTNAME, NA);
  trackingData.set(TD_LINKEDINSHARETYPE, NA);
  trackingData.set(TD_LEARNVIDEOSEEKBARTIME, NA);
  trackingData.set(TD_LEARNVIDEOLENGTH, NA);
  trackingData.set(TD_COURSEMODULEPROGRESS, NA);
  trackingData.set(TD_PREZENTATIONUSERACCESS, NA);
  trackingData.set(TD_PREZENTATIONAUTHOR, NA);
  trackingData.set(TD_USER_NAME, NA);
  trackingData.set(TD_NORESULTSFOUND, NA);
  trackingData.set(TD_AUDIENCE_TYPE, NA);
  trackingData.set(TD_AUD_FIRST_NAME, NA);
  trackingData.set(TD_AUD_LAST_NAME, NA);
  trackingData.set(TD_AUD_EMAIL, NA);
  trackingData.set(TD_AUD_COMPANY_NAME, NA);
  trackingData.set(TD_AUD_ADDITION_TYPE, NA);
  trackingData.set(TD_AUD_ROLE, NA);
  trackingData.set(TD_AUD_GROUP_SIZE, NA);
  trackingData.set(TD_AUD_BUSINESS_FUNCTION, NA);
  trackingData.set(TD_FINGERPRINT_SHARING_SETTINGS, NA);
  trackingData.set(TD_PLACEMENT, NA);
  trackingData.set(TD_COMPANY_OF_AUD_TAILORED_FOR, NA);
  trackingData.set(TD_HYBRID_RESULTS_SECTION_CLICK, NA);
  trackingData.set(TD_HYBRID_NO_RESULTS_BUTTON_CLICK, NA);
  trackingData.set(TD_SUBSCRIPTION_TYPE, NA);
  trackingData.set(TD_ROLE, NA);
  trackingData.set(TD_REPLACE_IMAGE_SOURCE, NA);
  trackingData.set(TD_FROM_TO, NA);
  trackingData.set(TD_FINGERPRINT_INDUSTRY, NA);
  trackingData.set(TD_FINGERPRINT_GROUP, NA);
  trackingData.set(TD_FINGERPRINT_REGION, NA);
  trackingData.set(TD_FINGERPRINT_JOBTITLE, NA);
  trackingData.set(TD_DECK_ID, NA);
  /** remove this column and use the below two columns to capture key and value */
  trackingData.set(TD_COMMON_COLUMN_NAME, NA);
  trackingData.set(TD_COMMON_COLUMN_VALUE, NA);
  trackingData.set(TD_PREZENTATION_CATEGORY, NA);
  trackingData.set(TD_SECTION_NAME, NA);
  trackingData.set(TD_SECTION_4, NA);
  trackingData.set(TD_SECTION_5, NA);
  trackingData.set(TD_PREZENTATION_ORIGIN, NA);
  trackingData.set(TD_PREZENTATION_COMPANY_SOURCE, NA);
  trackingData.set(TD_INSTRUCTIONS, NA);
  trackingData.set(TD_SLIDE_SOURCE, NA);
  trackingData.set(TD_SLIDE_NAME, NA);
  trackingData.set(TD_TIME_SPENT_IN_SECS, NA);
  trackingData.set(TD_ISRESTRICTED, NA);
  trackingData.set(TD_FILE_SIZE, NA);
  trackingData.set(TD_CROSS_FEATURE, NA);
  return trackingData;
};

export const getTimeSpentTrackingData = () => {
  const trackingData = new Map();
  trackingData.set(TD_EVENT_START, NA);
  trackingData.set(TD_EVENT_END, NA);
  trackingData.set(TD_USER, NA);
  trackingData.set(TD_TEAM, NA);
  trackingData.set(TD_COMPANY, NA);
  trackingData.set(TD_TIMESTAMP, NA);
  trackingData.set(TD_ROLE, NA);
  trackingData.set(TD_SUBSCRIPTION_TYPE, NA);
  trackingData.set(TD_TIME_SPENT, NA);
  return trackingData;
};

/*
 * DO NOT CHANGE THE ORDER OF THE EVENT PROPERTIES AS MENTIONED ABOVE
 */

const setSlidePrefs = (trackingData, prefs) => {
  const {
    has_cartoon: hasCartoon,
    has_icons: hasIcons,
    has_image: hasImage,
    has_three_d: has3d,
  } = prefs;

  trackingData.set(
    TD_HASCARTOON,
    hasCartoon === 'yes' || hasCartoon === true ? 1 : 0,
  );
  trackingData.set(
    TD_HASICONS,
    hasIcons === 'yes' || hasIcons === true ? 1 : 0,
  );
  trackingData.set(
    TD_HASIMAGES,
    hasImage === 'yes' || hasImage === true ? 1 : 0,
  );
  trackingData.set(TD_IS3D, has3d === 'yes' || has3d === true ? 1 : 0);
  trackingData.set(TD_NODECOUNT, prefs.node || NA);
};

export const setCommonData = (trackingData, currentUser) => {
  if (Object.keys(currentUser).length === 0) {
    return;
  }
  const { user, company, team, theme, subscriptions } = currentUser;
  //  const isTrial = () => user.role.toLowerCase() === 'trialuser';
  const subscriptionType = () => {
    if (subscriptions[0].planID === '12349') {
      return TD_SUBSCRIPTION_TYPE_PREZENT_PREMIUM;
    }
    if (subscriptions[0].planID === '12348') {
      return TD_SUBSCRIPTION_TYPE_PREZENT_STANDARD;
    }
    if (subscriptions[0].planID === '12347') {
      return TD_SUBSCRIPTION_TYPE_ENTERPRISE;
    }
    return TD_SUBSCRIPTION_TYPE_TRIAL;
  };
  const userName = user?.fullName
    ? user.fullName
    : `${user.firstName} ${user.lastName}`;
  trackingData.set(TD_USER, user.id);
  trackingData.set(TD_COMPANY, company.name);
  trackingData.set(TD_TEAM, team.name);
  trackingData.set(TD_TEMPLATE, theme.code);
  trackingData.set(TD_SUBSCRIPTION_TYPE, subscriptionType());
  trackingData.set(TD_ROLE, user.role);
  trackingData.set(
    TD_TIMESTAMP,
    new Date().toUTCString().replaceAll(',', '').replace('GMT', ''),
  );
  trackingData.set(TD_USER_NAME, userName);
};

export const setCommonTimeSpendData = (trackingData, currentUser) => {
  const { user, company, team, subscriptions } = currentUser;
  // const isTrial = () => user.role.toLowerCase() === 'trialuser';
  const subscriptionType = () => {
    if (subscriptions[0].planID === '12349') {
      return TD_SUBSCRIPTION_TYPE_PREZENT_PREMIUM;
    }
    if (subscriptions[0].planID === '12348') {
      return TD_SUBSCRIPTION_TYPE_PREZENT_STANDARD;
    }
    if (subscriptions[0].planID === '12347') {
      return TD_SUBSCRIPTION_TYPE_ENTERPRISE;
    }
    return TD_SUBSCRIPTION_TYPE_TRIAL;
  };
  trackingData.set(TD_USER, user.id);
  trackingData.set(TD_COMPANY, company.name);
  trackingData.set(TD_TEAM, team.name);
  trackingData.set(TD_SUBSCRIPTION_TYPE, subscriptionType());
  trackingData.set(TD_ROLE, user.role);
  trackingData.set(
    TD_TIMESTAMP,
    new Date().toUTCString().replaceAll(',', '').replace('GMT', ''),
  );
};

export const setOtherData = (trackingData, otherData) => {
  if (!otherData) {
    return;
  }
  const keys = Object.keys(otherData);
  keys.forEach((key) => {
    trackingData.set(key, otherData[key]);
  });
};

export const getMatomoEventName = (trackingData) => {
  let eventAction = '';
  for (const [, value] of trackingData) {
    const formattedValue = value ? value.toString().replaceAll(',', '_') : NA;
    eventAction += `${formattedValue},`;
  }

  return eventAction;
};

export const isSlideFavorite = (slideData, favoriteSlides) =>
  favoriteSlides.includes(slideData.unique_id);

export const getSlideId = (slideData) => {
  if (!slideData) {
    return NA;
  }

  const { unique_id: uniqueID } = slideData;
  if (slideData?.prefs?.source === 'prezent') {
    return slideData?.prefs?.sid;
  }
  if (
    slideData?.prefs?.source === 'generated' ||
    slideData?.prefs?.source === 'uploaded'
  )
    return uniqueID;
  if (uniqueID?.includes('deck2_')) {
    return uniqueID?.split('deck2_')[1]?.split('_')[0];
  }
  return uniqueID?.split('deck1_')[1]?.split('_')[0];
};

export const getSlideIdCollection = (slideData) => {
  if (!slideData) {
    return NA;
  }

  const { slideID: uniqueID } = slideData;
  return uniqueID.split('deck1_')[1].split('_')[0];
};

export const getDeckId = (slideData) => {
  if (!slideData) {
    return NA;
  }

  const { unique_id: uniqueID } = slideData;
  return uniqueID?.substring(0, uniqueID.indexOf('deck') + 5);
};

export const getDeckIdCollection = (slideData) => {
  if (!slideData) {
    return NA;
  }

  const { slideID: uniqueID } = slideData;
  return uniqueID.substring(0, uniqueID.indexOf('deck') + 5);
};

export const getSlideNodeCount = (slideData) => {
  if (!slideData) {
    return NA;
  }

  const { prefs } = slideData;
  return prefs.node || NA;
};

export const getEventNameForCommonAndOtherTrackingData = (
  currentUser,
  otherData,
) => {
  const trackingData = getDefaultTrackingData();
  setCommonData(trackingData, currentUser);
  setOtherData(trackingData, otherData);
  return getMatomoEventName(trackingData);
};

export const getEventNameForNewUserTrackingData = (otherData) => {
  const trackingData = getDefaultTrackingData();
  setOtherData(trackingData, otherData);
  return getMatomoEventName(trackingData);
};

export const getEventNameForTimeSpentCommonAndOtherTrackingData = (
  currentUser,
  otherData,
) => {
  const trackingData = getTimeSpentTrackingData();
  setOtherData(trackingData, otherData);
  setCommonTimeSpendData(trackingData, currentUser);
  return getMatomoEventName(trackingData);
};

export const getSlideData = (
  slideData,
  currentUser,
  otherData,
  includeCommonColumn = true,
) => {
  const { title, constructs, prefs, name, permission } = slideData;
  const trackingData = getDefaultTrackingData();
  setCommonData(trackingData, currentUser);
  trackingData.set(TD_IDEA, title);
  if (includeCommonColumn) {
    trackingData.set(TD_COMMON_COLUMN_NAME, TD_DOWNLOAD_AS_PDF);
    const permissionLevel = permission === 'Restricted' ? TD_YES : TD_NO;
    trackingData.set(TD_COMMON_COLUMN_VALUE, permissionLevel);
  }
  if (constructs && constructs.length)
    trackingData.set(TD_CONSTRUCT, constructs[0]);
  trackingData.set(TD_SLIDEID, getSlideId(slideData));
  trackingData.set(TD_DECK_ID, getDeckId(slideData));
  if (prefs?.source) {
    trackingData.set(TD_SLIDE_SOURCE, prefs.source);
  }
  trackingData.set(TD_SLIDE_NAME, name);
  if (prefs) {
    setSlidePrefs(trackingData, prefs);
  }
  setOtherData(trackingData, otherData);
  return trackingData;
};

export const getSlideDataCollection = (slideData, currentUser, otherData) => {
  const { idea, construct } = slideData;
  const trackingData = getDefaultTrackingData();
  setCommonData(trackingData, currentUser);
  trackingData.set(TD_IDEA, idea);
  trackingData.set(TD_CONSTRUCT, construct);
  trackingData.set(TD_SLIDEID, getSlideIdCollection(slideData));
  trackingData.set(TD_DECK_ID, getDeckIdCollection(slideData));
  setOtherData(trackingData, otherData);
  return trackingData;
};

export const trackCommonEvent = (data) => {
  const { currentUser, otherData, eventCategory, eventAction } = data;
  const eventName = getEventNameForCommonAndOtherTrackingData(
    currentUser,
    otherData,
  );

  trackMatomoEvent(eventCategory, eventAction, eventName);
};

export const trackNewUserEvents = (data) => {
  const { otherData, eventCategory, eventAction } = data;
  const eventName = getEventNameForNewUserTrackingData(otherData);

  trackMatomoEvent(eventCategory, eventAction, eventName);
};

export const trackTimeSpentCommonEvents = (data) => {
  const { currentUser, otherData, eventCategory, eventAction } = data;
  const eventName = getEventNameForTimeSpentCommonAndOtherTrackingData(
    currentUser,
    otherData,
  );

  trackMatomoEvent(eventCategory, eventAction, eventName);
};

const permissionMap = {
  0: TD_PREZ_AUTHOR,
  1: TD_PREZ_VIEWER,
  2: TD_PREZ_REVIEWER,
  3: TD_PREZ_EDITOR,
};

export const getOutlinesMerged = (outlines) => {
  if (!outlines) {
    return NA;
  }
  return outlines.toLocaleString().replaceAll(',', '&');
};

export const getSectionDeatils = (prezentation, value) => {
  let details = '';
  if (prezentation?.sections?.list[value]?.sectionName) {
    details = `${prezentation?.sections?.list[value]?.sectionName}_`;
    prezentation?.sections?.list[value]?.slides.forEach((slide) => {
      details += slide.outline;
      details += '_';
    });
  }
  return details;
};

export const getSlideCount = (prezentation) => {
  console.log('count-----');
  let count = 0;
  prezentation?.sections?.list.forEach((list) => {
    count += list.slides.length;
  });
  return count;
};

export const getBuildPrezentationData = (prezentation) => {
  try {
    if (!prezentation) {
      return {};
    }
    const { name, type, audienceID, sharedWith, fullName, ownerID } =
      prezentation;
    const isPrivatePrez = sharedWith === TD_SHARE_PRIVATE;
    return {
      [TD_PREZNAME]: name,
      [TD_PREZTYPE]: type,
      [TD_AUD]: audienceID,
      [TD_IDEABEGIN]: getSectionDeatils(prezentation, 0),
      [TD_IDEAMIDDLE]: getSectionDeatils(prezentation, 1),
      [TD_IDEAEND]: getSectionDeatils(prezentation, 2),
      [TD_PREZENTATIONAUTHOR]: isPrivatePrez ? ownerID : fullName || ownerID,
      [TD_NUMBEROFSLIDES]: getSlideCount(prezentation),
      [TD_SECTION_4]: getSectionDeatils(prezentation, 3),
      [TD_SECTION_5]: getSectionDeatils(prezentation, 4),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getPrezData = (prezentation) => {
  const {
    name,
    type,
    audienceID,
    permission_level: permissionLevel,
    sharedWith,
    fullName,
    ownerID,
    sections,
    prezentationType,
    prezentationSource,
    source,
  } = prezentation;
  const isPrivatePrez = sharedWith === TD_SHARE_PRIVATE;
  let slidesLength = 0;
  sections?.list.forEach((section) => {
    slidesLength += section.slides.length;
  });
  let audEmail = audienceID;
  if (audienceID && !audienceID.includes('@')) audEmail = '';
  return {
    [TD_PREZNAME]: name,
    [TD_PREZTYPE]: type,
    [TD_AUD]: audEmail,
    [TD_PREZENTATIONUSERACCESS]: isPrivatePrez
      ? TD_PREZ_PRIVATE
      : permissionMap[permissionLevel],
    [TD_PREZENTATIONAUTHOR]: isPrivatePrez ? ownerID : fullName || ownerID,
    [TD_NUMBEROFSLIDES]: slidesLength,
    [TD_PREZENTATION_CATEGORY]: prezentationType,
    [TD_PREZENTATION_ORIGIN]: prezentationSource,
    [TD_PREZENTATION_COMPANY_SOURCE]: source,
    [TD_IDEABEGIN]: getSectionDeatils(prezentation, 0),
    [TD_IDEAMIDDLE]: getSectionDeatils(prezentation, 1),
    [TD_IDEAEND]: getSectionDeatils(prezentation, 2),
    [TD_SECTION_4]: getSectionDeatils(prezentation, 3),
    [TD_SECTION_5]: getSectionDeatils(prezentation, 4),
  };
};

export const getSharedEntitiesTypes = (sharedEntities) => {
  try {
    if (!sharedEntities?.length) {
      return TD_SHARE_PRIVATE;
    }
    const types = sharedEntities.map((entity) => entity.type);
    return types?.toString().replaceAll(',', '_');
  } catch (e) {
    console.log(e);
    return NA;
  }
};

export const getAudienceCompanyName = (audience, currentUser) => {
  const { company } = currentUser;
  if (!audience) {
    return NA;
  }

  return (
    audience?.company?.displayName ||
    audience?.audienceCompanyDisplayName ||
    company?.name ||
    NA
  );
};

export const getAudienceId = (audience) => {
  if (!audience) {
    return NA;
  }

  return audience?.user?.id || audience?.id || NA;
};
