var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"great-decision-wrapper"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('div',{staticClass:"great-decision-wrapper-content"},[_vm._m(0),_c('div',{staticClass:"great-decision-desc"},[_vm._v(_vm._s(_vm.$t('profile.greatDecision')))]),_c('div',{staticClass:"great-decision-action"},[(
          _vm.isPayByCardForPaymentEnabled &&
          _vm.plan &&
          _vm.isPayByCardEnabled &&
          _vm.standaredOrPremium &&
          !_vm.isTrial
        )?_c('v-btn',{staticClass:"great-decision-go-to-payment",attrs:{"rounded":"","color":"#20a7e0","width":"157","height":"32","loading":_vm.syncingPlans},on:{"click":_vm.handleUpgrade}},[_vm._v(" "+_vm._s(_vm.$t('profile.payByCard'))+" ")]):_vm._e(),_c('v-btn',{class:_vm.plan &&
          _vm.isPayByCardEnabled &&
          _vm.standaredOrPremium &&
          _vm.isPayByCardForPaymentEnabled &&
          !_vm.isTrial
            ? 'great-decision-get-in-touch'
            : 'getInTouchWhenPayByCardUnavailable',attrs:{"rounded":"","text":"","width":"157","height":"32"},on:{"click":_vm.handleContact}},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.getInTouch'))+" ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"great-decision-image-wrapper"},[_c('img',{attrs:{"src":"/assets/img/login/yay-great-decision.svg","alt":""}})])}]

export { render, staticRenderFns }