<template>
  <div class="empty-state__container">
    <v-img width="500" :src="imgUrl" alt />
    <p v-if="description" class="no-prez-description">
      {{ $t(description) }}
    </p>
    <p v-else :class="`no-prez-description ${btnClass || ''}`">
      <slot name="description" />
    </p>

    <v-btn
      v-if="origin === 'prezentations'"
      center
      id="new-prez-btn"
      data-pendo-id="build-new-prez-btn"
      :class="btnClass || 'primary-button'"
      rounded
      :disabled="isBtnDisabled"
      :filled="!isBtnOutlined"
      :outlined="isBtnOutlined"
      color="primary"
      @click="handleCTA"
    >
      {{ 'Add slide context' }}
    </v-btn>
    <v-btn
      v-else-if="isBtnVisible"
      center
      id="new-prez-btn"
      data-pendo-id="build-new-prez-btn"
      :class="btnClass || 'primary-button'"
      rounded
      :disabled="isBtnDisabled"
      :filled="!isBtnOutlined"
      :outlined="isBtnOutlined"
      color="primary"
      @click="handleCTA"
    >
      {{ $t(buttonName) }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'EmptyState',
  data() {
    return {};
  },
  props: {
    imgUrl: {
      type: String,
      required: true,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    isBtnVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnClass: {
      type: String,
      required: false,
      default: '',
    },
    isBtnOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleCTA: {
      type: Function,
      required: false,
    },
    buttonName: {
      type: String,
      required: false,
      default: 'profile.storylinesTabContent.buildNewPrezentation',
    },
    origin: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.card-wrapper {
  margin: 0 auto;
}

.empty-state__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto;
  padding: 24px;
}

.no-prez-description {
  color: black;
  font-size: 1.25rem;
  padding: 24px 0 28px 0;
}

#new-prez-btn {
  &:hover {
    transform: scale(10.15s);
  }
  text-transform: none;
}
</style>
