<template>
  <div class="great-decision-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="great-decision-wrapper-content">
      <div class="great-decision-image-wrapper">
        <img src="/assets/img/login/yay-great-decision.svg" alt />
      </div>
      <div class="great-decision-desc">{{ $t('profile.greatDecision') }}</div>
      <div class="great-decision-action">
        <v-btn
          v-if="
            isPayByCardForPaymentEnabled &&
            plan &&
            isPayByCardEnabled &&
            standaredOrPremium &&
            !isTrial
          "
          class="great-decision-go-to-payment"
          rounded
          color="#20a7e0"
          width="157"
          height="32"
          @click="handleUpgrade"
          :loading="syncingPlans"
        >
          {{ $t('profile.payByCard') }}
        </v-btn>
        <!-- <v-btn
          :class="
            (plan &&
              isPayByCardEnabled &&
              standaredOrPremium &&
              isTrialUpgradePayByCardEnabled) ||
            isPaymentModeAddLicense
              ? 'great-decision-get-in-touch'
              : 'getInTouchWhenPayByCardUnavailable'
          "
          rounded
          text
          width="157"
          height="32"
          @click="handleContact"
        > -->
        <v-btn
          :class="
            plan &&
            isPayByCardEnabled &&
            standaredOrPremium &&
            isPayByCardForPaymentEnabled &&
            !isTrial
              ? 'great-decision-get-in-touch'
              : 'getInTouchWhenPayByCardUnavailable'
          "
          rounded
          text
          width="157"
          height="32"
          @click="handleContact"
        >
          {{ $t('fingerprint.getInTouch') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ContactSoonModal from './ContactSoonModal.vue';
import SubmitPaymentDetail from './SubmitPaymentDetail.vue';
import BuyAdditionalLicense from './BuyAdditionalLicense.vue';
import { sendEmail } from '../../utils/api-helper';
import ECommerceActionsApi from '../../API/ecommerce-actions-api';
import AddTeamFunds from '../Main/Profile/AddTeamFunds.vue';
import { trackProfileEvents } from './Analytics/ProfileEvents';
import { TD_TEAM } from './Analytics/MatomoTrackingDataHelper';

export default {
  name: 'GreatDecisionModal',
  props: {
    upgrade: {
      type: Function,
      default: () => ({}),
    },
    plan: {
      type: Object,
    },
    paymentType: {
      type: String,
    },
    currentNumSeats: {
      type: Number,
      default: 0,
    },
    endDate: {
      type: String,
    },
    startDate: {
      type: String,
    },
    numSeats: {
      type: Number,
      default: 1,
    },

    subscriptionID: {
      type: String,
    },
    isPayByCardEnabled: {
      type: Boolean,
      default: false,
    },
    isTrial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncingPlans: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'availablePlans']),
    ...mapGetters('users', [
      'isTrialUpgradePayByCardEnabled',
      'isSubcriptionlUpgradePayByCardEnabled',
      'isSubcriptionRenewalPayByCardEnabled',
      'isBuyLicensesPayByCardEnabled',
    ]),

    getUpdatedPlan() {
      return this.availablePlans.find((pl) => pl.id === this.plan.id);
    },
    standaredOrPremium() {
      return this.plan?.id === '12348' || this.plan?.id === '12349';
    },
    isPaymentModeAddLicense() {
      return this.paymentType === 'license';
    },
    isPayByCardForPaymentEnabled() {
      let flag = this.isSubcriptionRenewalPayByCardEnabled;
      if (this.paymentType === 'license') {
        flag = this.isBuyLicensesPayByCardEnabled;
      } else if (this.paymentType === 'upgrade') {
        flag = this.isSubcriptionlUpgradePayByCardEnabled;
      }
      return flag;
    },
  },
  methods: {
    ...mapActions('users', ['loadAvailablePlans']),
    handleClose() {
      this.$modal.hide('GreatDecisionModal');
    },
    handleContact() {
      if (this.paymentType !== 'fund') {
        switch (this.paymentType) {
          case 'license':
            trackProfileEvents.profileAdminSettingsBuyAdditionalLicensesRenewRenewGetinTouch(
              this.currentUser,
              {},
            );
            ECommerceActionsApi.methods
              .registerAction({
                operation: 'create-case',
                callAction: 'GIT',
                case: 'buyLicenses',
                leadSource: 'Prezent App - Buy Additional Licenses',
              })
              .catch((err) => {
                console.log(err);
              });
            break;
          case 'upgrade':
            trackProfileEvents.profileAdminSettingsUpgradeGetInTouch(
              this.currentUser,
              {},
            );
            break;
          case 'renew':
            trackProfileEvents.profileAdminSettingsRenewGetInTouch(
              this.currentUser,
              {},
            );
            break;
          default:
            break;
        }

        if (this.paymentType !== 'license') {
          console.log(
            'GreatDecisionModal handleClose line no. 164 ==> ',
            this.getUpdatedPlan.name,
          );
          ECommerceActionsApi.methods
            .registerAction({
              operation: 'create-case',
              callAction: 'GIT',
              planName: this.getUpdatedPlan.name || '',
              leadSource:
                this.paymentType === 'upgrade'
                  ? 'Prezent App - Subscription Upgrade'
                  : 'Prezent App - Subscription Renew',
              case: this.paymentType,
            })
            .catch((err) => {
              console.log(err);
            });
        }

        this.$modal.hide('GreatDecisionModal');
        try {
          const params = {
            type: 'buy-subscription',
            to: ['sfdc@prezent.ai'],
            meta: {
              firstName: this.currentUser.user.firstName,
              lastName: this.currentUser.user.lastName,
              userEmail: this.currentUser.user.id,
            },
          };
          sendEmail(params)
            .then((response) => console.log('sendEmail rsp ', response))
            .catch((err) => console.log('sendEmail err ', err));
        } catch (err) {
          console.log(err);
        }
        this.$modal.show(
          ContactSoonModal,
          {},
          {
            name: 'ContactSoonModal',
            width: '449px',
            height: '503px',
          },
        );
        this.handleClose();
      } else {
        this.$modal.hide('GreatDecisionModal');
        this.$modal.show(
          AddTeamFunds,
          {},
          {
            name: 'AddTeamFunds',
            width: '340px',
            height: '436px',
          },
        );
      }
    },

    async syncAvailablePlansForChange() {
      await this.loadAvailablePlans(this.currentUser);
    },

    async handleUpgrade() {
      if (this.paymentType === 'license') {
        trackProfileEvents.profileAdminSettingsBuyAdditionalLicensesRenewPayByCard(
          this.currentUser,
          {},
        );
      }
      if (this.paymentType === 'renew') {
        trackProfileEvents.profileAdminSettingsRenewPayByCard(
          this.currentUser,
          {},
        );
      }
      if (this.paymentType === 'upgrade') {
        trackProfileEvents.profileAdminSettingsUpgradePayByCard(
          this.currentUser,
          { [TD_TEAM]: this.currentUser?.team?.name },
        );
      }

      // syncing plans price for any update before popup opens
      this.syncingPlans = true;
      await this.syncAvailablePlansForChange();
      this.syncingPlans = false;

      this.$modal.hide('GreatDecisionModal');
      if (this.paymentType === 'upgrade' || this.paymentType === 'renew') {
        this.$modal.show(
          SubmitPaymentDetail,
          {
            plan: this.getUpdatedPlan,
            subscriptionID: this.subscriptionID,
            licenses: this.numSeats,
            isRenewal: this.paymentType === 'renew',
            isUpgradeFromStandard:
              this.paymentType === 'upgrade' &&
              this.currentUser?.subscriptions[0]?.planID === '12348',
          },
          {
            name: 'SubmitPaymentDetail',
            width: '950px',
            height: '838px',
            clickToClose: false,
          },
        );
      } else {
        this.$modal.show(
          BuyAdditionalLicense,
          {
            upgradePlan: this.upgrade,
            plan: this.getUpdatedPlan,
            currentNumSeats: this.currentNumSeats,
            endDate: this.endDate,
            startDate: this.startDate,
            subscriptionID: this.subscriptionID,
          },
          {
            name: 'BuyAdditionalLicense',
            width: '950px',
            height: '838px',
            clickToClose: false,
          },
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.great-decision-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .great-decision-wrapper-content {
    width: 100%;
    .great-decision-image-wrapper {
      margin: auto;
      margin-top: 55px;
      margin-bottom: 22px;
      width: fit-content;
    }
    .great-decision-desc {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 31px;
    }
    .great-decision-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .great-decision-go-to-payment {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        text-transform: none;
        margin-bottom: 5px;
      }
      .great-decision-get-in-touch {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #21a7e0;
        text-transform: none;
      }
      .getInTouchWhenPayByCardUnavailable {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        background-color: #21a7e0;
        text-transform: none;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
