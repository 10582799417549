<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83335 7.08301L7.50002 5.41634L5.83335 3.74967L4.16669 5.41634L5.83335 7.08301ZM3.33335 11.6663C2.87502 
      11.6663 2.48266 11.5031 2.15627 11.1768C1.82988 10.8504 1.66669 10.458 1.66669 9.99967V2.49967C1.66669 
      2.04134 1.82988 1.64898 2.15627 1.32259C2.48266 0.996202 2.87502 0.833008 3.33335 0.833008H16.6667C17.125 
      0.833008 17.5174 0.996202 17.8438 1.32259C18.1702 1.64898 18.3334 2.04134 18.3334 2.49967V9.99967C18.3334 
      10.458 18.1702 10.8504 17.8438 11.1768C17.5174 11.5031 17.125 11.6663 16.6667 11.6663H13.9792C14.0625 
      11.3886 14.1181 11.1108 14.1459 10.833C14.1736 10.5552 14.1736 10.2775 14.1459
      9.99967H16.6667V2.49967H3.33335V9.99967H5.85419C5.82641 10.2775 5.82641 10.5552 5.85419 
     10.833C5.88197 11.1108 5.93752 11.3886 6.02085 11.6663H3.33335ZM13.75 5.83301C14.0972 5.83301 
     14.3924 5.71148 14.6354 5.46842C14.8785 5.22537 15 4.93023 15 4.58301C15 4.23579 14.8785 3.94065 
     14.6354 3.69759C14.3924 3.45454 14.0972 3.33301 13.75 3.33301C13.4028 3.33301 13.1077 3.45454 12.8646
     3.69759C12.6215 3.94065 12.5 4.23579 12.5 4.58301C12.5 4.93023 12.6215 5.22537 12.8646 5.46842C13.1077
     5.71148 13.4028 5.83301 13.75 5.83301ZM4.16669 19.1663V17.4163C4.16669 16.9302 4.3021 16.4788 4.57294 16.0622C4.84377 
     15.6455 5.20141 15.333 5.64585 15.1247C6.32641 14.8052 7.03474 14.5656 7.77085 14.4059C8.50697 14.2462 9.25002 
     14.1663 10 14.1663C10.75 14.1663 11.4931 14.2462 12.2292 14.4059C12.9653 14.5656 13.6736 14.8052 14.3542 
     15.1247C14.7986 15.333 15.1563 15.6455 15.4271 16.0622C15.6979 16.4788 15.8334 16.9302 15.8334 
     17.4163V19.1663H4.16669ZM5.83335 17.4997H14.1667V17.4163C14.1667 17.2497 14.1181 17.0969 14.0209 16.958C13.9236 
     16.8191 13.7986 16.715 13.6459 16.6455C13.0625 16.3816 12.4653 16.1802 11.8542 16.0413C11.2431 15.9025 10.625 15.833 10 
     15.833C9.37502 15.833 8.75697 15.9025 8.14585 16.0413C7.53474 16.1802 6.93752 16.3816 6.35419 16.6455C6.20141 16.715 
     6.07641 16.8191 5.97919 16.958C5.88197 17.0969 5.83335 17.2497 5.83335 17.4163V17.4997ZM10 13.333C9.19447 13.333 8.50697
     13.0483 7.93752 12.4788C7.36808 11.9094 7.08335 11.2219 7.08335 10.4163C7.08335 9.61078 7.36808 8.92329 
     7.93752 8.35384C8.50697 7.7844 9.19447 7.49967 10 7.49967C10.8056 7.49967 11.4931 7.7844 12.0625 8.35384C12.632
     8.92329 12.9167 9.61078 12.9167 10.4163C12.9167 11.2219 12.632 11.9094 12.0625 12.4788C11.4931 13.0483 10.8056
     13.333 10 13.333ZM10 11.6663C10.3472 11.6663 10.6424 11.5448 10.8854 11.3018C11.1285 11.0587 11.25 10.7636 
     11.25 10.4163C11.25 10.0691 11.1285 9.77398 10.8854 9.53092C10.6424 9.28787 10.3472 9.16634 10 9.16634C9.6528
     9.16634 9.35766 9.28787 9.1146 9.53092C8.87155 9.77398 8.75002 10.0691 8.75002 10.4163C8.75002 10.7636 8.87155 
     11.0587 9.1146 11.3018C9.35766 11.5448 9.6528 11.6663 10 11.6663Z"
      :fill="iconColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'MyWorkspaceIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
