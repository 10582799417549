<template>
  <div class="manage-settings-wrapper">
    <div class="manage-settings-title">
      {{ $t('redesign.manageSettings.heading') }}
    </div>
    <div class="manage-settings-subtitle">
      {{ $t('redesign.manageSettings.subHeading') }}
    </div>
    <!-- Panels Layout !-->
    <v-expansion-panels class="settingsPanels" v-model="currPanel">
      <template v-for="panel in panels">
        <v-expansion-panel :key="panel.id">
          <v-expansion-panel-header>
            <v-icon size="20" :color="'#075689'">{{ panel.icon }}</v-icon>
            <div class="panelHeader">{{ panel.description }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="panelDesc">
              {{ $t('redesign.manageSettings.panelsSuggestion') }}
            </div>
            <div v-if="panel.type === 'image_settings'" class="imageSettings">
              <div
                class="panelItem"
                v-for="(item, ind) in panel.panelItems"
                :key="ind"
              >
                <v-checkbox
                  color="#20a7e0"
                  :label="item.label"
                  v-model="item.selected"
                  v-if="item.enabled"
                />
                <div class="panelItemDesc" v-if="item.enabled">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
    <!-- Panels Layout !-->

    <!-- Save CTA !-->
    <div class="cta-container">
      <v-btn
        :disabled="disableCTA"
        :loading="saving"
        @click="
          saveText === 'common.saveSettings' ? handleSaveSettings() : () => {}
        "
      >
        {{ $t(saveText) }}
      </v-btn>
    </div>
    <!-- Save CTA !-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import usersApi from '../../API/users-api';

export default {
  name: 'RedesignManageSettings',
  data() {
    return {
      // state properties for the component

      // always keeping the first panel open
      currPanel: 0,

      // to hold the panel objects
      panels: [],

      // to show loader when saving is in progress
      saving: false,

      // to denote the text on the save CTA
      saveText: 'common.saveSettings',

      // to hold the settings value strings on load
      imageSettingsOnLoad: [],
    };
  },
  props: {},
  computed: {
    ...mapState('users', ['currentUser']),

    // Property to disable Save CTA as and when required
    disableCTA() {
      return (
        this.imageSettingsSelected.length <= 0 ||
        this.areEqualArrays(
          this.imageSettingsOnLoad,
          this.imageSettingsSelected,
        )
      );
    },

    // Property to determine whether brand library is enabled for image replacement
    enableCompanyLibrary() {
      const brandImageFlag = this.currentUser?.features?.find(
        (f) => f.feature_name === 'replace_image_brand',
      );
      return brandImageFlag?.enabled;
    },

    // Property to determine whether prezent stock libraries are enabled for image replacement
    enablePrezentLibrary() {
      const prezImageLibFlag = this.currentUser?.features?.find(
        (f) => f.feature_name === 'replace_image_adobe_library',
      );
      return prezImageLibFlag?.enabled;
    },

    // Property to determine whether original slides images is enabled for image replacement
    enableRedesignOriginalSlides() {
      const imageOriginalSlidesRedesignFlag = this.currentUser?.features?.find(
        (f) => f.feature_name === 'replace_image_original_slides',
      );
      return !!imageOriginalSlidesRedesignFlag?.enabled;
    },

    /*
      Property to filter out the image settings strings for the user,
      based on if it is selected by the user and if its data sensitive flag is enabled
    */
    imageSettingsSelected() {
      if (this.panels.length > 0) {
        const panel = this.panels.find((itm) => itm.type === 'image_settings');
        if (panel && panel.panelItems) {
          return panel.panelItems
            .filter((pnlItm) => pnlItm.selected && pnlItm.enabled)
            .map((st) => st.valueText);
        }
        return [];
      }
      return [];
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),

    /*
      Function to compare two arrays of the settings:
      --> On Load
      --> and while update by user interaction
    */
    areEqualArrays(firstArr, secondArr) {
      if (firstArr.length !== secondArr.length) {
        return false;
      }
      return firstArr.every((value, ind) => value === secondArr[ind]);
    },

    // Function to save the settings with user object
    async handleSaveSettings() {
      this.saving = true;

      // payload for user profile update api
      const userdata = {
        userRedesignImageSettings: this.imageSettingsSelected,
      };

      // updating user profile with the new image settings values
      await usersApi.methods
        .updateUserProfile(userdata)
        .then((resp) => {
          this.saving = false;
          this.saveText = 'common.saved';

          /*
            Setting a timeout to show saved text on the CTA after 1 sec,
            and updating user profile locally in vuex store
          */
          setTimeout(() => {
            // Updating vuex store to sync the settings with db
            let userInfo = this.currentUser;
            userInfo = {
              ...userInfo,
              user: {
                ...userInfo.user,
                userRedesignImageSettings: resp.userRedesignImageSettings,
              },
            };
            this.setCurrentUser(userInfo);

            // updating the on load settings
            this.imageSettingsOnLoad = resp.userRedesignImageSettings;

            this.saveText = 'common.saveSettings';
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.saving = false;
        });
    },
  },
  watch: {},
  mounted() {
    // Assigning panels array the values on mount
    this.panels = [
      {
        type: 'image_settings',
        id: 0,
        description: `${this.$t('redesign.manageSettings.defImgLibDesc')}`,
        icon: 'mdi-image-multiple-outline',
        panelItems: [
          {
            label: `${this.currentUser.company.displayName} ${this.$t(
              'redesign.manageSettings.imgLibCompLibLabel',
            )}`,
            description: `${this.$t(
              'redesign.manageSettings.imgLibCompLibDesc',
              { companyName: this.currentUser?.company?.displayName },
            )}`,
            selected:
              this.currentUser?.user?.userRedesignImageSettings?.includes(
                'company_library',
              ),
            valueText: 'company_library',
            enabled: this.enableCompanyLibrary,
          },
          {
            label: `${this.$t('redesign.manageSettings.imgLibPrezLibLabel')}`,
            description: `${this.$t(
              'redesign.manageSettings.imgLibPrezLibDesc',
            )}`,
            selected:
              this.currentUser?.user?.userRedesignImageSettings?.includes(
                'prezent_library',
              ),
            valueText: 'prezent_library',
            enabled: this.enablePrezentLibrary,
          },
          {
            label: `${this.$t(
              'redesign.manageSettings.imgLibOrigSlidesLabel',
            )}`,
            description: `${this.$t(
              'redesign.manageSettings.imgLibOrigSlidesDesc',
            )}`,
            selected:
              this.currentUser?.user?.userRedesignImageSettings?.includes(
                'additional_context',
              ),
            valueText: 'additional_context',
            enabled: this.enableRedesignOriginalSlides,
          },
        ],
      },
    ];

    /*
      Assiging the fetched image settings of the user to a copy variable,
      for comparison while enabling/disabling save CTA
    */
    this.imageSettingsOnLoad = this.imageSettingsSelected;
  },
};
</script>

<style lang="scss" scoped>
.manage-settings-wrapper {
  width: 495px;
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;
  .manage-settings-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 0px 0px 8px 0px;
  }
  .manage-settings-subtitle {
    font-family: 'Lato';
    font-style: normal;
    font-size: 15px;
    margin: 0px 0px 8px 0px;
  }
  .settingsPanels {
    ::v-deep .v-expansion-panel {
      margin: 12px 0px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
      border-radius: 8px !important;
      border: none;
      &::before {
        box-shadow: none;
      }
      &::after {
        border-color: #ffffff !important;
      }
      .v-expansion-panel-header {
        padding: 10px 10px 0px 10px;
        .v-icon {
          flex: none;
          margin: 0px 4px 0px 4px;
        }
        .panelHeader {
          margin: 0px 0px 0px 6px;
          font-size: 16px;
          font-weight: 600;
          color: #075689;
        }
      }
      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 0px 12px 24px 12px !important;
          .panelDesc {
            font-family: 'Lato';
            font-style: normal;
            font-size: 15px;
            margin: 0px 0px 8px 0px;
          }
          .imageSettings {
            .panelItem {
              .v-input {
                .v-input__control {
                  .v-input__slot {
                    margin: 0;
                    .v-input--selection-controls__input {
                      .v-icon {
                        color: #000000;
                      }
                    }
                    .v-label {
                      color: #000000;
                    }
                  }
                  .v-messages {
                    display: none;
                  }
                }
              }
              .panelItemDesc {
                color: #969ba3;
                font-size: 14px;
                margin: 0px 0px 0px 32px;
              }
            }
          }
        }
      }
    }
  }
  .cta-container {
    display: flex;
    justify-content: center;
    padding: 48px 0px;
    .v-btn {
      text-transform: none;
      letter-spacing: normal;
      border-radius: 20px;
      color: #ffffff;
      background-color: #21a7e0;
      box-shadow: none;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
