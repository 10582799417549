<template>
  <div class="cdl">
    <div class="cdl__header">
      <h4 class="cdl__title">{{ title || defaults.title }}</h4>
      <v-btn class="cdl__close" icon @click="close()">
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="cdl__body">
      {{ text || defaults.text }}
      <div v-if="subText && subText.length">{{ subText }}</div>
    </div>
    <div class="cdl__actions">
      <v-btn rounded outlined class="cdl__action" @click="close()">
        {{ secondaryButtonText || defaults.secondaryButtonText }}
      </v-btn>
      <v-btn
        rounded
        color="primary"
        class="cdl__action cdl__action--primary"
        @click="submit()"
        :loading="loading"
      >
        {{ primaryButtonText || defaults.primaryButtonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationDialogLite',
  props: {
    title: String,
    text: String,
    subText: String,
    primaryButtonText: String,
    secondaryButtonText: String,
    handler: {
      type: Function,
      default: async () => Promise.resolve(),
    },
  },
  data() {
    return {
      defaults: {
        title: 'Confirm',
        text: this.$t('confirmationDialog.title'),
        primaryButtonText: this.$t('common.yes'),
        secondaryButtonText: this.$t('common.no'),
        subText: '',
      },
      loading: false,
    };
  },
  methods: {
    close() {
      this.$modal.hide('ConfirmationDialogLite');
    },
    async submit() {
      this.loading = true;
      await this.handler();
      this.loading = false;
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.cdl {
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  padding: 22px;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__title {
    font-size: 22px;
  }

  &__body {
    font-size: 16px;
  }

  &__actions {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }

  &__action {
    border-color: #21a7e0;
    color: #21a7e0;
    text-transform: none;
    font-size: 18px;
    font-weight: bold;
    height: 40px !important;
    letter-spacing: normal;
  }
  &__action--primary {
    border-color: #21a7e0;
    background-color: #21a7e0 !important;
  }
}
</style>
