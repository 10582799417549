import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getMatomoEventName,
  getSlideData,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const KNOW_EVENT_CATEGORY = 'Know';
const KNOW_ICONCLICK = 'know_IconClick';
const KNOW_SEEMORERESULTS = 'know_SeeMoreResults';
const KNOW_THUMBNAIL_DOWNLOAD = 'Know_Thumbnail_download';
const KNOW_THUMBNAILCLICK = 'know_ThumbnailClick';
const KNOW_THUMBNAILCLICK_DOWNLOAD = 'know_ThumbnailClick_download';
const KNOW_THUMBNAIL_VIEWICONCLICK = 'know_Thumbnail_ViewIconClick';
const KNOW_THUMBNAIL_VIEWICONCLICK_DOWNLOAD =
  'know_Thumbnail_ViewIconClick_download';
const KNOW_SORTBY = 'know_SortBy';
const KNOW_TYPEDROPDOWN = 'know_TypeDropdown';
const KNOW_SUGGEST = 'know_Suggest';
const KNOW_SUGGEST_SUBMIT = 'know_Suggest_Submit';
const KNOW_BESTPRACTICE = 'know_BestPractice';
const KNOW_IDEACHIPCLICK = 'know_ideachipclick';
const KNOW_SIMILARSLIDES = 'know_SimilarSlides';
const KNOW_SIMILARSLIDES_DOWNLOAD = 'know_SimilarSlides_download';
const KNOW_DECKS_SEARCH = 'Know_decks_Search';
const KNOW_SLIDES_SEARCH = 'Know_slides_Search';
const KNOW_ADVACNE_FILTERS_SELECTED = 'know_advance-filters_selected';
const KNOW_FAVORITED = 'know_favorited';
const KNOW_COPY_LINK = 'Know_copy-link';
const KNOW_SLIDES_EDIT_NODE_COUNT_CLICK = 'know_slides_edit-node-count_click';
const KNOW_SLIDES_SIMILAR_SLIDES_CLICK = 'know_slides_similar-slides_click';
const KNOW_SLIDES_REPLACE_VISUALS_CLICK = 'know_slides_Replace-visuals_click';
const KNOW_SLIDES_NODE_COUNT_CHANGED = 'know_slides_node-count-changed';
const KNOW_SLIDES_REPLACE_VISUALS_IMAGE_REPLACED =
  'know_slides_Replace-visuals_Image-replaced';
const KNOW_SLIDES_REPLACE_VISUALS_IMAGE_REPLACED_DOWNLOADED =
  'know_slides_Replace-visuals_Image-replaced_downloaded';
const KNOW_DECKS_REPLACE_VISUALS_CLICK = 'know_decks_Replace-visuals_click';
const KNOW_IMAGE_REPLACEMENT_SEARCH = 'Know_image-replacement_search';
const KNOW_DECKS_REPLACE_VISUALS_IMAGE_REPLACED =
  'know_decks_Replace-visuals_Image-replaced';
const KNOW_DECKS_REPLACE_VISUALS_IMAGE_REPLACED_DOWNLOADED =
  'know_decks_Replace-visuals_Image-replaced_downloaded';
const CONVERT_TEMPLATE_CLICK = 'Convert-template_click';
const CONVERT_TEMPLATE_TEMPLATE_SELECTED = 'Convert-template_template-selected';
const CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS =
  'Convert-template_accept-all-suggestions';
const CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE =
  'Convert-template_select-a-different-template';

export const trackKnowEvents = {
  knowIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSeeMoreResults(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SEEMORERESULTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  know(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_THUMBNAIL_DOWNLOAD, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowThumbnailClickDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      const eventAction =
        slideData?.origin === 'viewicon'
          ? KNOW_THUMBNAIL_VIEWICONCLICK_DOWNLOAD
          : KNOW_THUMBNAILCLICK_DOWNLOAD;
      trackMatomoEvent(KNOW_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowThumbnailClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      const eventAction =
        slideData?.origin === 'viewicon'
          ? KNOW_THUMBNAIL_VIEWICONCLICK
          : KNOW_THUMBNAILCLICK;
      trackMatomoEvent(KNOW_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowTypeDropDown(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_TYPEDROPDOWN,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSortType(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SORTBY,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSuggest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SUGGEST,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSuggestSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SUGGEST_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowBestPractice(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_BESTPRACTICE, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  trackKnowIdeachipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_IDEACHIPCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSimilarSlides(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_SIMILARSLIDES, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowSimilarSlidesDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SIMILARSLIDES_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowDeckSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_DECKS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSlideSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SLIDES_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowAdvanceFiltersSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_ADVACNE_FILTERS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowFavorited(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_FAVORITED, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowCopyLink(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_COPY_LINK, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesEditNodeCountClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_EDIT_NODE_COUNT_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesSimilarSlidesClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_SIMILAR_SLIDES_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesReplaceVisualsClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_REPLACE_VISUALS_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesNodeCountChanged(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_NODE_COUNT_CHANGED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesReplaceVisualsImageReplaced(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_REPLACE_VISUALS_IMAGE_REPLACED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowSlidesReplaceVisualsImageReplacedDownloaded(
    slideData,
    currentUser,
    otherData,
  ) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SLIDES_REPLACE_VISUALS_IMAGE_REPLACED_DOWNLOADED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowDecksReplaceVisualsClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_DECKS_REPLACE_VISUALS_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowImageReplacementSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_IMAGE_REPLACEMENT_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowDecksReplaceVisualsImageReplaced(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_DECKS_REPLACE_VISUALS_IMAGE_REPLACED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowDecksReplaceVisualsImageReplacedDownloaded(
    slideData,
    currentUser,
    otherData,
  ) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_DECKS_REPLACE_VISUALS_IMAGE_REPLACED_DOWNLOADED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateClick(currentUser, otherData, slideData) {
    try {
      if (slideData) {
        const trackingData = getSlideData(slideData, currentUser, otherData);
        const eventName = getMatomoEventName(trackingData);
        trackMatomoEvent(
          KNOW_EVENT_CATEGORY,
          CONVERT_TEMPLATE_CLICK,
          eventName,
        );
      } else {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: KNOW_EVENT_CATEGORY,
          eventAction: CONVERT_TEMPLATE_CLICK,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateTemplateSelectClick(currentUser, otherData, slideData) {
    try {
      if (slideData) {
        const trackingData = getSlideData(slideData, currentUser, otherData);
        const eventName = getMatomoEventName(trackingData);
        trackMatomoEvent(
          KNOW_EVENT_CATEGORY,
          CONVERT_TEMPLATE_TEMPLATE_SELECTED,
          eventName,
        );
      } else {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: KNOW_EVENT_CATEGORY,
          eventAction: CONVERT_TEMPLATE_TEMPLATE_SELECTED,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateAcceptAllSuggestions(currentUser, otherData, slideData) {
    try {
      if (slideData) {
        const trackingData = getSlideData(slideData, currentUser, otherData);
        const eventName = getMatomoEventName(trackingData);
        trackMatomoEvent(
          KNOW_EVENT_CATEGORY,
          CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS,
          eventName,
        );
      } else {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: KNOW_EVENT_CATEGORY,
          eventAction: CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateSelectDifferentTemplate(currentUser, otherData, slideData) {
    try {
      if (slideData) {
        const trackingData = getSlideData(slideData, currentUser, otherData);
        const eventName = getMatomoEventName(trackingData);
        trackMatomoEvent(
          KNOW_EVENT_CATEGORY,
          CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE,
          eventName,
        );
      } else {
        trackCommonEvent({
          currentUser,
          otherData,
          eventCategory: KNOW_EVENT_CATEGORY,
          eventAction: CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
};
