<template>
  <svg
    :width="iconWidth"
    :height="iconHeight"
    :viewBox="`0 0 ${iconWidth} ${iconHeight}`"
    :fill="iconColor"
  >
    <path
      d="M7.95596 19H3.5C2.95 19 2.47917 18.8042 2.0875 18.4125C1.69583 18.0208 1.5 17.55 1.5 17V5C1.5 4.45 1.69583 3.97917 2.0875 3.5875C2.47917 3.19583 2.95 3 3.5 3H19.5C20.05 3 20.5208 3.19583 20.9125 3.5875C21.3042 3.97917 21.5 4.45 21.5 5V8.5L19.5 7.5V5H3.5V17H6V18C6 18 7.66233 18.9143 7.95596 19Z"
      :fill="iconColor"
    />
    <path d="M8 19C8 19.0088 7.9844 19.0083 7.95596 19H8Z" :fill="iconColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 17.75L13.5 20.5L19 17.75V13.5L20.5 12.75V17.5H22.5V11L13.5 6L4.5 11L8 12.75V17.75ZM19 11L13.5 13.5L8 11L13.5 8L19 11ZM17 16.8125L13.5 18.5L10 16.8125V14L13.5 15.5L17 14.1139V16.8125Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ZenithLearningWorkshopIcon',
  props: {
    iconWidth: {
      type: Number,
      default: 48,
    },
    iconHeight: {
      type: Number,
      default: 36,
    },
    iconColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
