<template>
  <div>
    <div class="logo">
      <a href="https://prezent.ai/">
        <img
          src="/assets/img/prezent_logo.svg"
          alt
          width="200px"
          height="44px"
        />
      </a>
    </div>
    <div style="padding-left: 10px">
      <div class="registration-heading-left">
        Unlock the future of enterprise communication today!
      </div>
      <div class="registration-subheading">
        {{ `Why sign-up for ${subscriptionType}:` }}
      </div>
      <div class="services-items">
        <div
          v-for="(item, index) in getAdvantagesList"
          class="services-item"
          :key="index"
        >
          <div class="service-icon-wrapper">
            <v-img :src="item.image"></v-img>
          </div>
          <div class="service-description-wrapper">
            <p class="service-description-title">
              {{ item.heading }}
            </p>
            <p
              v-for="(description, index) in item.descriptions"
              class="service-description-subtitle"
              :key="index"
            >
              {{ description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrialSignUpAdvantages',
  data() {
    return {
      advantageList: [
        {
          image: '/assets/img/AI_Icon.svg',
          heading: 'AI-Powered presentations',
          descriptions: [
            'Why spend hours on slides when you can have them ready in seconds? Our Generative AI crafts on-brand presentations tailored to your audience.',
          ],
        },
        {
          image: '/assets/img/brand_approved_slides.svg',
          heading: 'Brand approved slides',
          descriptions: [
            'Access 35K+ slides in your company brand-approved designs and fill in with your ideas.',
          ],
        },
        {
          image: '/assets/img/Enterprise_Grade_Security.svg',
          heading: 'On-Demand learning',
          descriptions: [
            'Level up your communication skills with our on-the-go learning modules. Know what ticks for your colleagues and bosses.',
          ],
        },
        {
          image: '/assets/img/Streamlined_Costs.svg',
          heading: 'Streamlined costs',
          descriptions: [
            'Cut down on agency expenses by up to 60% and allocate your budget where it truly matters.',
          ],
        },
        {
          image: '/assets/img/Security.svg',
          heading: 'Enterprise-Grade security',
          descriptions: [
            `Your data's safety is our top priority. We're compliant with GDPR, ISO/IEC 27001:2013, SOC 2 Type 2, and CCPA standards.`,
          ],
        },
      ],
    };
  },
  props: {
    subscriptionType: {
      type: String,
      default: 'free trial',
    },
  },
  computed: {
    getAdvantagesList() {
      let temp = this.advantageList;
      if (
        this.subscriptionType.toLowerCase() === 'free trial' ||
        this.subscriptionType.toLowerCase() === 'prezent premium'
      ) {
        temp = temp.filter(
          (obj) => obj.heading.toLowerCase() !== 'brand approved slides',
        );
      } else {
        temp = temp.filter(
          (obj) => obj.heading.toLowerCase() !== 'ai-powered presentations',
        );
      }
      return temp;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  padding: 0px 0 10px 0 !important;
  width: fit-content;
}
.registration-heading-left {
  font-family: Lato;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #121619;
  padding-bottom: 20px;
}
.registration-subheading {
  font-family: Lato;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #1075ab;
  padding-bottom: 15px;
}
.services-items {
  grid-row-gap: 25px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  .services-item {
    grid-column-gap: 25px;
    align-items: flex-start;
    display: flex;
    .service-icon-wrapper {
      border: 0.8px solid rgba(216, 230, 241, 1);
      background-color: #fff;
      border-radius: 12px;
      flex: none;
      justify-content: center;
      align-items: center;
      width: 75px;
      height: 75px;
      margin-bottom: 0;
      padding: 14px;
      display: flex;
      // box-shadow: rgba(179, 228, 245, 0.2);
    }
    .service-description-wrapper {
      grid-row-gap: 8px;
      flex-direction: column;
      display: flex;
      .service-description-title {
        font-size: 18px;
        font-weight: 500;
        color: #1075ab;
        margin: 0 !important;
      }
      .service-description-subtitle {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin: 0 !important;
        line-height: 25px;
      }
    }
  }
}
</style>
