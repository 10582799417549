<template>
  <div class="advanced-filter-options">
    <div class="filters-action reset-btn">
      <v-btn
        text
        color="primary"
        @click="handleResetFilters"
        :disabled="isAdvancedFilterChanged || searchProgress"
      >
        {{ $t('prezentationsList.resetAllFilters') }}
      </v-btn>
    </div>

    <v-radio-group v-model="filterOptionCategory">
      <v-radio
        color="#20a7e0"
        :label="$t('prezentationsList.brandSlides')"
        value="brand_slides"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-list v-if="filterOptionCategory === 'brand_slides'">
        <v-list-group
          v-for="item in advancedItems"
          :key="item.title"
          v-model="item.active"
          no-action
        >
          <template v-slot:activator>
            <img
              alt
              :src="`/assets/img/${item.icon}.svg`"
              width="24"
              height="24"
            />
            <v-list-item-content>
              <v-list-item-title
                class="advanced-filter-title"
                v-text="$t(item.display_title)"
              />
            </v-list-item-content>
          </template>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                :disabled="searchProgress || item.disableCheckAll"
                v-model="item.checkAll"
                @click="onCheckboxAllClicked($event, item.title)"
                color="primary"
                :on-icon="'mdi-checkbox-marked-outline'"
                :off-icon="'mdi-checkbox-blank-outline'"
              />
            </v-list-item-action>
            <img
              alt
              :src="`/assets/img/filter-all.svg`"
              width="20"
              height="20"
              class="advanced-filter-icon"
              v-if="item.showIcon"
            />
            <v-list-item-content>
              <v-list-item-title
                class="advanced-filter-content-text"
                v-text="$t('overnightPresentations.all')"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense v-for="child in item.items" :key="child.title">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :disabled="searchProgress || child.disabled"
                  :on-icon="'mdi-checkbox-marked-outline'"
                  :off-icon="'mdi-checkbox-blank-outline'"
                  :input-value="active"
                  v-model="child.active"
                  color="primary"
                  @click="onAdvancedFilerChangeNew(child, item)"
                />
              </v-list-item-action>
              <img
                alt
                :src="`/assets/img/filter-${child.icon}.svg`"
                width="20"
                height="20"
                v-if="child.icon"
                class="advanced-filter-icon"
              />
              <v-list-item-content>
                <v-list-item-title
                  class="advanced-filter-content-text"
                  v-text="$t(child.display_title)"
                />
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.favourites')"
        value="favorites"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.downloads')"
        value="downloads"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.generatedSlides')"
        value="generated"
        v-if="getGenerateAccessLevel"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.redesignedSlides')"
        value="redesign"
        v-if="isRedesignFullAccess"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.complySlides')"
        value="comply"
        v-if="isComplyFullAccess"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.synthesis')"
        value="synthesis"
        v-if="getSynthesisAccessLevel"
        :disabled="searchProgress"
      >
      </v-radio>
      <v-radio
        color="#20a7e0"
        :label="$t('slides.uploads')"
        value="uploaded"
        :disabled="searchProgress"
        v-if="getUploadAccessLevel"
      >
      </v-radio>
      <v-list v-if="filterOptionCategory === 'uploaded'">
        <v-list-group
          v-for="item in advancedItemsUpload"
          :key="item.title"
          v-model="item.active"
          no-action
        >
          <template v-slot:activator>
            <img
              alt
              :src="`/assets/img/${item.icon}.svg`"
              width="24"
              height="24"
            />
            <v-list-item-content>
              <v-list-item-title
                class="advanced-filter-title"
                v-text="$t(item.display_title)"
              />
            </v-list-item-content>
          </template>
          <v-list-item v-if="!item.isCustomGroup">
            <v-list-item-action>
              <v-checkbox
                :disabled="searchProgress || item.disableCheckAll"
                v-model="item.checkAll"
                @click="onCheckboxAllClicked($event, item.title)"
                color="primary"
                :on-icon="'mdi-checkbox-marked-outline'"
                :off-icon="'mdi-checkbox-blank-outline'"
              />
            </v-list-item-action>
            <img
              alt
              :src="`/assets/img/filter-all.svg`"
              width="20"
              height="20"
              class="advanced-filter-icon"
              v-if="item.showIcon"
            />
            <v-list-item-content>
              <v-list-item-title
                class="advanced-filter-content-text"
                v-text="$t('overnightPresentations.all')"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense v-for="child in item.items" :key="child.title">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :disabled="searchProgress || child.disabled"
                  :on-icon="'mdi-checkbox-marked-outline'"
                  :off-icon="'mdi-checkbox-blank-outline'"
                  :input-value="active"
                  v-model="child.active"
                  color="primary"
                  @click="onAdvancedFilerChangeNew(child, item)"
                />
              </v-list-item-action>
              <img
                alt
                :src="`/assets/img/filter-${child.icon}.svg`"
                width="20"
                height="20"
                v-if="child.icon"
                class="advanced-filter-icon"
              />
              <v-list-item-content>
                <v-list-item-title
                  class="advanced-filter-content-text"
                  v-text="$t(child.display_title)"
                />
              </v-list-item-content>
            </template>
          </v-list-item>
          <div v-if="item.isCustomGroup" class="customGroupWrapper">
            <div class="fromDateWrapper">
              <div class="label">
                {{ $t('overnightPresentations.from') }}
              </div>
              <div class="datePicker">
                <v-menu
                  v-model="fromDateMenu"
                  :close-on-content-click="false"
                  :close-on-click="true"
                  transition="scale-transition"
                  offset-y
                  :position-x="menuPosition.x"
                  :position-y="menuPosition.y"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDateStr"
                      :placeholder="$t('overnightPresentations.dateFormat')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click="(e) => handleDateTextFieldClick(e)"
                      @focus="getCurrentDateFormatted('from')"
                      @mouseup="getCurrentDateFormatted('from')"
                    >
                      <template #append>
                        <v-icon
                          v-if="fromDateStr !== ''"
                          @click="handleClearDate('from')"
                          class="clearIcon"
                        >
                          {{ 'mdi-close' }}
                        </v-icon>
                        <v-icon v-bind="attrs" v-on="on" class="calendarIcon">
                          {{ 'mdi-calendar' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    :allowedDates="allowedFromDates"
                    no-title
                    scrollable
                    @input="fromDateMenu = false"
                    @change="() => handleDatePickerTextChange('from')"
                    color="#20a7e0"
                    :picker-date.sync="pickerDate"
                  />
                </v-menu>
              </div>
            </div>
            <div class="toDateWrapper">
              <div class="label">
                {{ $t('overnightPresentations.to') }}
              </div>
              <div class="datePicker">
                <v-menu
                  v-model="toDateMenu"
                  :close-on-content-click="false"
                  :close-on-click="true"
                  transition="scale-transition"
                  offset-y
                  :position-x="menuPosition.x"
                  :position-y="menuPosition.y"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDateStr"
                      :placeholder="$t('overnightPresentations.dateFormat')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click="(e) => handleDateTextFieldClick(e)"
                      @focus="getCurrentDateFormatted('to')"
                      @mouseup="getCurrentDateFormatted('to')"
                    >
                      <template #append>
                        <v-icon
                          v-if="toDateStr !== ''"
                          @click="handleClearDate('to')"
                          class="clearIcon"
                        >
                          {{ 'mdi-close' }}
                        </v-icon>
                        <v-icon v-bind="attrs" v-on="on" class="calendarIcon">
                          {{ 'mdi-calendar' }}
                        </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    :allowedDates="allowedToDates"
                    no-title
                    scrollable
                    @input="toDateMenu = false"
                    @change="() => handleDatePickerTextChange('to')"
                    color="#20a7e0"
                    :picker-date.sync="pickerDate"
                  />
                </v-menu>
              </div>
            </div>
            <!-- <div class="applyDatesCTA">
              <v-btn
                text
                @click="onAdvancedFilerChangeNew(item)"
                :disabled="
                  fromDateStr === '' ||
                  toDateStr === '' ||
                  !fromDateStr ||
                  !toDateStr
                "
              >
                {{ 'Apply custom dates' }}
              </v-btn>
            </div> -->
          </div>
        </v-list-group>
      </v-list>

      <v-radio
        color="#20a7e0"
        :label="$t('slides.collections')"
        value="collections"
        :disabled="searchProgress"
      >
      </v-radio>
    </v-radio-group>
    <div class="filters-action apply-btn">
      <v-btn
        rounded
        color="primary"
        :loading="searchProgress"
        @click="handleApplyFilters"
      >
        {{ $t('slides.applyFilters') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import __ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import findIndex from 'lodash/findIndex';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import moment from 'moment';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import sortBy from 'lodash/sortBy';
import {
  addSearchTerms,
  getCollection,
  getGeneratedUploadedSlides,
  getSlideDetail,
  getUserSearchTerms,
  handleSearch,
  logSearchQuery,
  updateSearchTerms,
} from '../../../utils/api-helper';
import { handleDebounce } from '../../Main/SlideImageReplacement/slideImageReplacementUtils';
import {
  getBuildPrezentationData,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_DOWNLOADS,
  TD_FAVORITES,
  TD_FILTER_SELECTED,
  TD_PLACEMENT,
  TD_POPULAR,
  TD_REC,
  // TD_SORTTYPE,
} from '../Analytics/MatomoTrackingDataHelper';
// import { trackSlideEvents } from '../Analytics/SlideEvents';
import EventBus from '../event-bus';
import { AnalyticsHandler } from '../Analytics/AnalyticsHandler';
import { capitalizeFirstLetter } from '../../../utils/common';
import { performSearch } from '../../../graphql/queries';
import usersApi from '../../../API/users-api';
import { GraphQLService } from '../../../services/GraphQLService';
import { trackPrezentationEvents } from '../Analytics/PrezentationEvents';
import { trackBuildEvents } from '../Analytics/BuildEvents';
// import { MatomoAnalyticsHandler } from '../Analytics/MatomoAnalyticsHandler';

const GENERIC_BOOSTER_PACK = 'generic_booster_pack';

export default {
  props: {
    onFilteredSlides: {
      type: Function,
      default: () => ({}),
    },
    isloadingCallback: {
      type: Function,
      default: () => ({}),
    },
    prezentationDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterOptionCategory: 'brand_slides',
      searchProgress: true,
      menuPosition: {
        x: 0,
        y: 0,
      },
      isFiltersAppliedClick: false,
      fromDate: '',
      toDate: '',
      fromDateMenu: false,
      toDateMenu: false,
      toDateStr: '',
      pickerDate: '',
      fromDateStr: '',
      advancedFilterKey: 0,
      offsetTop: 0,
      skip: 0,
      username: '',
      filterBy: 'Recommended',
      filterTailor: null,
      filterByAdvanced: ['Cartoons', 'Icons', 'Images', '3-D'],
      trendingKeywords: [],
      userAssociatedCompanies: [],
      clickedKeyword: {},
      keywords: [
        { name: 'All', category: 'All', count: 0 },
        { name: 'Agenda', category: 'Agenda', count: 0 },
        { name: 'Vision', category: 'Vision', count: 0 },
        { name: 'Executive Summary', category: 'Executive_Summary', count: 0 },
        { name: 'Thank You', category: 'Thank_You', count: 0 },
        {
          name: 'Process',
          category: 'process',
          construct: 'Process',
          count: 0,
        },
        { name: 'Award', category: 'Award', count: 0 },
        { name: 'Timeline', category: 'Timeline', count: 0 },
        { name: 'Journey', category: 'Journey', count: 0 },
        // {
        //   name: 'Line Graph',
        //   category: 'Line_Graph',
        //   construct: 'line_graph',
        //   count: 0,
        // },
        {
          name: 'Financials',
          category: 'Financials',
          count: 0,
        },
        { name: 'Status', category: 'Status', count: 0 },
        { name: 'Title', category: 'Title', count: 0 },
        { name: 'People', category: 'People', count: 0 },
      ],
      boosterPackKeywords: [
        { name: 'All', category: 'All', count: 0 },
        { name: '4th Of July', category: '4th_of_july', count: 0 },
        { name: 'Christmas', category: 'christmas', count: 0 },
        { name: 'Thanksgiving', category: 'thanksgiving', count: 0 },
        { name: 'Sports', category: 'sports', count: 0 },
        { name: 'Movie', category: 'movie', count: 0 },
        { name: 'Nature', category: 'nature', count: 0 },
        { name: 'Mountain', category: 'mountain', count: 0 },
        { name: 'Sea', category: 'sea', count: 0 },
        { name: 'Space', category: 'space', count: 0 },
        { name: 'Race car', category: 'race_car', count: 0 },
        { name: '80s', category: '80s', count: 0 },
      ],
      rejectedKeywords: [
        'nature',
        'mountain',
        '80s',
        'sports',
        '4th of july',
        'thanksgiving',
        'christmas',
        'sea',
        'movie',
        'space',
        'race car',
        'prezfingerprinttest',
      ],
      slides: [],
      slidesFilteredChip: [],
      slidesFilteredChipAdvanced: [],
      generatedOrUploadSlidesArray: [],
      searchAudience: '',
      componentKey: 0,
      types: [
        {
          title: 'Title',
          value: 'title',
        },
        {
          title: 'Executive Summary',
          value: 'executive',
        },
        {
          title: 'SWOT',
          value: 'swot',
        },
        {
          title: 'Agenda',
          value: 'agenda',
        },
        {
          title: 'Table of Contents',
          value: 'table',
        },
        {
          title: 'Objectives',
          value: 'objectives',
        },
        {
          title: 'Value Proposition',
          value: 'proposition',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
      ],
      items: ['Recommended', 'Popular'],
      tailorItems: ['You'],
      advancedFilterOpened: false,
      collectionOpened: false,
      dialogContentFinal: null,
      templateIDD: '',
      templateName: '',
      templateTitle: '',
      dialogReset: false,
      selectTemplateKey: 0,
      selectedNodeIds: [{ selectedNodes: [] }, { selectedNodes: [] }],
      advancedItems: [
        {
          items: [
            {
              title: 'Prezent',
              name: 'prezent',
              display_title: 'Prezent',
              active: true,
              disabled: false,
            },
            {
              title: 'Company',
              name: 'company',
              display_title: 'Company',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Source',
          display_title: 'userUploadPrezentation.source',
          active: false,
          icon: 'filter-source',
          disabled: false,
        },
        {
          items: [
            { title: '1', display_title: '1', active: true, disabled: false },
            { title: '2', display_title: '2', active: true, disabled: false },
            { title: '3', display_title: '3', active: true, disabled: false },
            { title: '4', display_title: '4', active: true, disabled: false },
            { title: '5', display_title: '5', active: true, disabled: false },
            { title: '6', display_title: '6', active: true, disabled: false },
            { title: '7', display_title: '7', active: true, disabled: false },
            { title: '8', display_title: '8', active: true, disabled: false },
            { title: '9', display_title: '9', active: true, disabled: false },
            {
              title: '10 or more',
              display_title: 'filters.more',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Node Count',
          display_title: 'tagSlides.nodeCountLabel',
          active: false,
          icon: 'node-count-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Cartoons',
              display_title: 'filters.cartoons',
              active: true,
              disabled: false,
            },
            {
              title: 'Icons',
              display_title: 'filters.icons',
              active: true,
              disabled: false,
            },
            {
              title: 'Images',
              display_title: 'filters.images',
              active: true,
              disabled: false,
            },
            {
              title: '3-D',
              display_title: '3-D',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Graphics',
          display_title: 'tagSlides.graphics',
          active: false,
          icon: 'graphics-slides',
          disabled: false,
        },
        {
          active: false,
          checkAll: true,
          disableCheckAll: false,
          showIcon: true,
          items: [
            {
              title: 'Area Graph',
              display_title: 'filters.areaGraph',
              icon: 'area-graph',
              name: 'area_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Assessment',
              display_title: 'filters.assessment',
              icon: 'assessment',
              name: 'assessment',
              active: true,
              disabled: false,
            },
            {
              title: 'Bar/Column Graph',
              display_title: 'filters.barGraph',
              icon: 'bar-column',
              name: 'bar_column_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Box and Whisker',
              display_title: 'filters.boxAndWhisker',
              icon: 'box-and-whisker',
              name: 'box_and_whisker',
              active: true,
              disabled: false,
            },
            {
              title: 'Bubble Graph',
              display_title: 'filters.bubbleGraph',
              icon: 'bubblish-graph',
              name: 'bubble_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Bullet Graph',
              display_title: 'filters.bulletGraph',
              icon: 'bullet-graph',
              name: 'bullet_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Category',
              display_title: 'filters.category',
              icon: 'category',
              name: 'category',
              active: true,
              disabled: false,
            },
            {
              title: 'Causality',
              display_title: 'filters.casuality',
              icon: 'causality',
              name: 'causality',
              active: true,
              disabled: false,
            },
            {
              title: 'Calendar',
              display_title: 'filters.calendar',
              icon: 'calendar',
              name: 'calendar',
              active: true,
              disabled: false,
            },
            {
              title: 'Circular',
              display_title: 'filters.circular',
              icon: 'circular',
              name: 'circular',
              active: true,
              disabled: false,
            },
            {
              title: 'Convergent',
              display_title: 'filters.convergent',
              icon: 'convergent',
              name: 'convergent',
              active: true,
              disabled: false,
            },

            {
              title: 'Decision Tree',
              display_title: 'filters.decisionTree',
              icon: 'decision-tree',
              name: 'decision_tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Divergent',
              display_title: 'filters.divergent',
              icon: 'divergent',
              name: 'divergent',
              active: true,
              disabled: false,
            },
            {
              title: 'Donut/Pie Chart',
              display_title: 'filters.pieChart',
              icon: 'donut-pie-graph',
              name: 'donut_pie_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Emphasis',
              display_title: 'filters.emphasis',
              icon: 'emphasis',
              name: 'emphasis',
              active: true,
              disabled: false,
            },
            {
              title: 'Enclosed',
              display_title: 'filters.enclosed',
              icon: 'enclosed',
              name: 'enclosed',
              active: true,
              disabled: false,
            },
            {
              title: 'Filter',
              display_title: 'filters.filter',
              icon: 'filter',
              name: 'filter',
              active: true,
              disabled: false,
            },
            {
              title: 'Histogram',
              display_title: 'filters.histogram',
              icon: 'histogram',
              name: 'histogram',
              active: true,
              disabled: false,
            },
            {
              title: 'Linked',
              display_title: 'filters.linked',
              icon: 'linked',
              name: 'linked',
              active: true,
              disabled: false,
            },
            {
              title: 'List',
              display_title: 'filters.list',
              icon: 'list',
              name: 'list',
              active: true,
              disabled: false,
            },
            {
              title: 'Line Graph',
              display_title: 'filters.lineGraph',
              icon: 'line-graph',
              name: 'line_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Matrix',
              display_title: 'filters.matrix',
              icon: 'matrix',
              name: 'matrix',
              active: true,
              disabled: false,
            },
            {
              title: 'Multi Bar',
              display_title: 'filters.multiBar',
              icon: 'multi-graph',
              name: 'multi_bar',
              active: true,
              disabled: false,
            },
            {
              title: 'One Pager',
              display_title: 'filters.onePager',
              icon: 'one-pager',
              name: 'one_pager',
              active: true,
              disabled: false,
            },
            {
              title: 'Pictogram Chart',
              display_title: 'filters.pictogram',
              icon: 'pictogram-chart',
              name: 'pictogram_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Process',
              display_title: 'filters.process',
              icon: 'process',
              name: 'process',
              active: true,
              disabled: false,
            },
            {
              title: 'Pyramid',
              display_title: 'filters.pyramid',
              icon: 'pyramid',
              name: 'pyramid',
              active: true,
              disabled: false,
            },
            {
              title: 'Radar Chart',
              display_title: 'filters.radarChart',
              icon: 'radar-chart',
              name: 'radar_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Ring',
              display_title: 'filters.ring',
              icon: 'ring',
              name: 'ring',
              active: true,
              disabled: false,
            },
            {
              title: 'Spoke',
              display_title: 'filters.spoke',
              icon: 'spoke',
              name: 'spoke',
              active: true,
              disabled: false,
            },
            {
              title: 'Stacked Bar Graph',
              display_title: 'filters.stackedBarGraph',
              icon: 'stacked-bar-graph',
              name: 'stacked_bar_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Stairs',
              display_title: 'filters.stairs',
              icon: 'stairs',
              name: 'stairs',
              active: true,
              disabled: false,
            },
            {
              title: 'Table',
              display_title: 'filters.table',
              icon: 'table',
              name: 'table',
              active: true,
              disabled: false,
            },
            {
              title: 'Timeline',
              display_title: 'filters.timeLine',
              icon: 'timeline',
              name: 'timeline',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree',
              display_title: 'filters.tree',
              icon: 'tree',
              name: 'tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree map',
              display_title: 'filters.treeMap',
              icon: 'tree-map',
              name: 'tree_map',
              active: true,
              disabled: false,
            },
            {
              title: 'Venn',
              display_title: 'filters.venn',
              icon: 'venn',
              name: 'venn',
              active: true,
              disabled: false,
            },
          ],
          title: 'Diagrams',
          display_title: 'tagSlides.diagrams',
          icon: 'diagrams-slides',
          disabled: false,
        },
      ],
      advancedItemsUpload: [
        {
          items: [
            {
              title: 'Slide Shells',
              display_title: 'filters.slideShells',
              active: true,
              disabled: false,
              value: 'Slide Shells',
            },
            {
              title: 'Partially Anonymized',
              display_title: 'filters.partiallyAnonymized',
              active: true,
              disabled: false,
              value: 'Best Practice Slides',
            },
            {
              title: 'Original',
              display_title: 'upload.original',
              active: true,
              disabled: false,
              value: 'Original Slides',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Slide Type',
          display_title: 'filters.slideType',
          active: false,
          icon: 'slide-type-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Me',
              display_title: 'fingerprint.compareFingAud.me',
              active: true,
              disabled: false,
              value: 'me',
            },
            {
              title: 'My Colleagues',
              display_title: 'filters.myColleagues',
              active: true,
              disabled: false,
              value: 'team,company',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Author',
          display_title: 'build.step3.author',
          active: false,
          icon: 'author-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Viewer',
              display_title: 'prezentationShareStatus.viewer',
              active: true,
              disabled: false,
              value: 'viewer',
            },
            {
              title: 'Editor',
              display_title: 'prezentationShareStatus.editor',
              active: true,
              disabled: false,
              value: 'editor',
            },
            {
              title: 'Shared by me',
              display_title: 'filters.sharedByMe',
              active: true,
              disabled: false,
              value: 'shared',
            },
            {
              title: 'Private',
              display_title: 'prezentationShareStatus.private',
              active: true,
              disabled: false,
              value: 'private',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Access',
          display_title: 'filters.access',
          active: false,
          icon: 'access-slides',
          disabled: false,
        },
        {
          checkAll: false,
          title: 'Uploaded on',
          display_title: 'prezentationsList.updatedOn',
          active: false,
          includeAllOption: false,
          icon: 'created-uploaded-on-slides',
          isCustomGroup: true,
        },
      ],
      advancedItemsOnLoad: [
        {
          items: [
            {
              title: 'Prezent',
              display_title: 'Prezent',
              name: 'prezent',
              active: true,
              disabled: false,
            },
            {
              title: 'Company',
              display_title: 'Company',
              name: 'company',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Source',
          display_title: 'userUploadPrezentation.source',
          active: false,
          icon: 'filter-source',
          disabled: false,
        },
        {
          items: [
            { title: '1', display_title: '1', active: true, disabled: false },
            { title: '2', display_title: '2', active: true, disabled: false },
            { title: '3', display_title: '3', active: true, disabled: false },
            { title: '4', display_title: '4', active: true, disabled: false },
            { title: '5', display_title: '5', active: true, disabled: false },
            { title: '6', display_title: '6', active: true, disabled: false },
            { title: '7', display_title: '7', active: true, disabled: false },
            { title: '8', display_title: '8', active: true, disabled: false },
            { title: '9', display_title: '9', active: true, disabled: false },
            {
              title: '10 or more',
              display_title: 'filters.more',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Node Count',
          display_title: 'tagSlides.nodeCountLabel',
          active: false,
          icon: 'node-count-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Cartoons',
              display_title: 'filters.cartoons',
              active: true,
              disabled: false,
            },
            {
              title: 'Icons',
              display_title: 'filters.icons',
              active: true,
              disabled: false,
            },
            {
              title: 'Images',
              display_title: 'filters.images',
              active: true,
              disabled: false,
            },
            {
              title: '3-D',
              display_title: '3-D',
              active: true,
              disabled: false,
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Graphics',
          display_title: 'tagSlides.graphics',
          active: false,
          icon: 'graphics-slides',
          disabled: false,
        },
        {
          active: false,
          checkAll: true,
          disableCheckAll: false,
          showIcon: true,
          items: [
            {
              title: 'Area Graph',
              display_title: 'filters.areaGraph',
              icon: 'area-graph',
              name: 'area_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Assessment',
              display_title: 'filters.assessment',
              icon: 'assessment',
              name: 'assessment',
              active: true,
              disabled: false,
            },
            {
              title: 'Bar/Column Graph',
              display_title: 'filters.barGraph',
              icon: 'bar-column',
              name: 'bar_column_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Box and Whisker',
              display_title: 'filters.boxAndWhisker',
              icon: 'box-and-whisker',
              name: 'box_and_whisker',
              active: true,
              disabled: false,
            },
            {
              title: 'Bubble Graph',
              display_title: 'filters.bubbleGraph',
              icon: 'bubblish-graph',
              name: 'bubble_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Bullet Graph',
              display_title: 'filters.bulletGraph',
              icon: 'bullet-graph',
              name: 'bullet_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Category',
              display_title: 'filters.category',
              icon: 'category',
              name: 'category',
              active: true,
              disabled: false,
            },
            {
              title: 'Causality',
              display_title: 'filters.casuality',
              icon: 'causality',
              name: 'causality',
              active: true,
              disabled: false,
            },
            {
              title: 'Calendar',
              display_title: 'filters.calendar',
              icon: 'calendar',
              name: 'calendar',
              active: true,
              disabled: false,
            },
            {
              title: 'Circular',
              display_title: 'filters.circular',
              icon: 'circular',
              name: 'circular',
              active: true,
              disabled: false,
            },
            {
              title: 'Convergent',
              display_title: 'filters.convergent',
              icon: 'convergent',
              name: 'convergent',
              active: true,
              disabled: false,
            },

            {
              title: 'Decision Tree',
              display_title: 'filters.decisionTree',
              icon: 'decision-tree',
              name: 'decision_tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Divergent',
              display_title: 'filters.divergent',
              icon: 'divergent',
              name: 'divergent',
              active: true,
              disabled: false,
            },
            {
              title: 'Donut/Pie Chart',
              display_title: 'filters.pieChart',
              icon: 'donut-pie-graph',
              name: 'donut_pie_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Emphasis',
              display_title: 'filters.emphasis',
              icon: 'emphasis',
              name: 'emphasis',
              active: true,
              disabled: false,
            },
            {
              title: 'Enclosed',
              display_title: 'filters.enclosed',
              icon: 'enclosed',
              name: 'enclosed',
              active: true,
              disabled: false,
            },
            {
              title: 'Filter',
              display_title: 'filters.filter',
              icon: 'filter',
              name: 'filter',
              active: true,
              disabled: false,
            },
            {
              title: 'Histogram',
              display_title: 'filters.histogram',
              icon: 'histogram',
              name: 'histogram',
              active: true,
              disabled: false,
            },
            {
              title: 'Linked',
              display_title: 'filters.linked',
              icon: 'linked',
              name: 'linked',
              active: true,
              disabled: false,
            },
            {
              title: 'List',
              display_title: 'filters.list',
              icon: 'list',
              name: 'list',
              active: true,
              disabled: false,
            },
            {
              title: 'Line Graph',
              display_title: 'filters.lineGraph',
              icon: 'line-graph',
              name: 'line_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Matrix',
              display_title: 'filters.matrix',
              icon: 'matrix',
              name: 'matrix',
              active: true,
              disabled: false,
            },
            {
              title: 'Multi Bar',
              display_title: 'filters.multiBar',
              icon: 'multi-graph',
              name: 'multi_bar',
              active: true,
              disabled: false,
            },
            {
              title: 'One Pager',
              display_title: 'filters.onePager',
              icon: 'one-pager',
              name: 'one_pager',
              active: true,
              disabled: false,
            },
            {
              title: 'Pictogram Chart',
              display_title: 'filters.pictogram',
              icon: 'pictogram-chart',
              name: 'pictogram_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Process',
              display_title: 'filters.process',
              icon: 'process',
              name: 'process',
              active: true,
              disabled: false,
            },
            {
              title: 'Pyramid',
              display_title: 'filters.pyramid',
              icon: 'pyramid',
              name: 'pyramid',
              active: true,
              disabled: false,
            },
            {
              title: 'Radar Chart',
              display_title: 'filters.radarChart',
              icon: 'radar-chart',
              name: 'radar_chart',
              active: true,
              disabled: false,
            },
            {
              title: 'Ring',
              display_title: 'filters.ring',
              icon: 'ring',
              name: 'ring',
              active: true,
              disabled: false,
            },
            {
              title: 'Spoke',
              display_title: 'filters.spoke',
              icon: 'spoke',
              name: 'spoke',
              active: true,
              disabled: false,
            },
            {
              title: 'Stacked Bar Graph',
              display_title: 'filters.stackedBarGraph',
              icon: 'stacked-bar-graph',
              name: 'stacked_bar_graph',
              active: true,
              disabled: false,
            },
            {
              title: 'Stairs',
              display_title: 'filters.stairs',
              icon: 'stairs',
              name: 'stairs',
              active: true,
              disabled: false,
            },
            {
              title: 'Table',
              display_title: 'filters.table',
              icon: 'table',
              name: 'table',
              active: true,
              disabled: false,
            },
            {
              title: 'Timeline',
              display_title: 'filters.timeLine',
              icon: 'timeline',
              name: 'timeline',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree',
              display_title: 'filters.tree',
              icon: 'tree',
              name: 'tree',
              active: true,
              disabled: false,
            },
            {
              title: 'Tree map',
              display_title: 'filters.treeMap',
              icon: 'tree-map',
              name: 'tree_map',
              active: true,
              disabled: false,
            },
            {
              title: 'Venn',
              display_title: 'filters.venn',
              icon: 'venn',
              name: 'venn',
              active: true,
              disabled: false,
            },
          ],
          title: 'Diagrams',
          display_title: 'tagSlides.diagrams',
          icon: 'diagrams-slides',
          disabled: false,
        },
      ],
      advancedItemsUploadOnLoad: [
        {
          items: [
            {
              title: 'Slide Shells',
              display_title: 'filters.slideShells',
              active: true,
              disabled: false,
              value: 'Slide Shells',
            },
            {
              title: 'Partially Anonymized',
              display_title: 'filters.partiallyAnonymized',
              active: true,
              disabled: false,
              value: 'Best Practice Slides',
            },
            {
              title: 'Original',
              display_title: 'upload.original',
              active: true,
              disabled: false,
              value: 'Original Slides',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Slide Type',
          display_title: 'filters.slideType',
          active: false,
          icon: 'slide-type-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Me',
              display_title: 'fingerprint.compareFingAud.me',
              active: true,
              disabled: false,
              value: 'me',
            },
            {
              title: 'My Colleagues',
              display_title: 'filters.myColleagues',
              active: true,
              disabled: false,
              value: 'team,company',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Author',
          display_title: 'build.step3.author',
          active: false,
          icon: 'author-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Viewer',
              display_title: 'prezentationShareStatus.viewer',
              active: true,
              disabled: false,
              value: 'viewer',
            },
            {
              title: 'Editor',
              display_title: 'prezentationShareStatus.editor',
              active: true,
              disabled: false,
              value: 'editor',
            },
            {
              title: 'Shared by me',
              display_title: 'filters.sharedByMe',
              active: true,
              disabled: false,
              value: 'shared',
            },
            {
              title: 'Private',
              display_title: 'prezentationShareStatus.private',
              active: true,
              disabled: false,
              value: 'private',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Access',
          display_title: 'filters.access',
          active: false,
          icon: 'access-slides',
          disabled: false,
        },
        {
          checkAll: false,
          title: 'Uploaded on',
          display_title: 'prezentationsList.updatedOn',
          active: false,
          includeAllOption: false,
          icon: 'created-uploaded-on-slides',
          isCustomGroup: true,
        },
      ],
      excludedCategories: {
        category: [
          'nature',
          'mountain',
          '80s',
          'sports',
          '4th_of_july',
          'thanksgiving',
          'christmas',
          'sea',
          'movie',
          'space',
          'race_car',
          'prezfingerprinttest',
        ],
      },
      ideaChipClicked: 'All',
      matomoFilterMap: {
        Popular: TD_POPULAR,
        Recommended: TD_REC,
        'My Favorites': TD_FAVORITES,
        'My Downloads': TD_DOWNLOADS,
      },
      pageSize: 24,
    };
  },
  watch: {
    slidesFilteredChipAdvanced() {
      this.onFilteredSlides(
        this.slidesFilteredChipAdvanced,
        this.filterOptionCategory,
      );
    },
    searchProgress() {
      if (this.isloadingCallback) {
        this.isloadingCallback(this.searchProgress);
      }
    },
    filterOptionCategory() {
      this.fromDate = '';
      this.toDate = '';
      this.fromDateMenu = false;
      this.toDateMenu = false;
      this.toDateStr = '';
      this.fromDateStr = '';
    },
  },
  async mounted() {
    await this.makeApiCallsForSlidesAfterMount();
  },
  computed: {
    ...mapState('dialogStore', {
      initialDialogContent: 'content',
    }),
    ...mapState('users', [
      'currentUser',
      'themes',
      'currentTheme',
      'companyData',
      'startLoadingSlidesFlag',
    ]),
    ...mapState('slidesCollection', [
      'downloadSet',
      'downloadStatus',
      'nodeCountChanged',
    ]),
    isGraphics() {
      return findIndex(this.advancedItems[2], ['active', 'false']) < 0;
    },
    isDiagrams() {
      return findIndex(this.advancedItems[3], ['active', 'false']) < 0;
    },
    slideContents() {
      return get(this.initialDialogContent, 'imageProducts');
    },
    currentThemeCode() {
      return (
        this.currentTheme?.code || this.currentUser?.theme?.code || 'default'
      );
    },

    ...mapGetters('replaceImageStore', [
      'getIsDirty',
      'getShowDialog',
      'getShouldReturnToSlide',
      'getSearchTerm',
    ]),
    ...mapGetters('slidesCollection', [
      // 'isCollectionLimitReached',
      'getDownloadCompletedStatus',
      'getAllCollections',
      'getAddedCollections',
      'getAllAddedCollections',
    ]),
    ...mapGetters('slidesStore', [
      'getFavoriteSlides',
      'getImageReplacedSlides',
      'getIconReplacedSlides',
      'getDownloadedSlides',
      'getSlidesSnapshot',
      'getSlideDetailViewChangedAudienceAndTemplate',
    ]),
    ...mapGetters('users', [
      'getGenerateAccessLevel',
      'getUploadAccessLevel',
      'getSynthesisAccessLevel',
      'isRedesignFullAccess',
      'isComplyFullAccess',
    ]),
    ...mapState('users', ['assetLoaded']),
    ...mapState('users', ['imageLoaded']),
    ...mapState('users', ['assetsNextToken']),
    ...mapState('users', ['assetsStartIndex', 'savedQuery', 'searchToken']),
    ...mapState('users', ['lastAction']),
    ...mapState('users', ['currentUser', 'assetVotes']),
    ...mapState('users', ['assetFolder', 'leftSideBarStatus']),

    isAdvancedFilterChanged() {
      if (this.filterOptionCategory !== 'brand_slides') return false;
      const advItems = [...this.advancedItems].map((item) => ({
        ...item,
        active: true,
      }));
      const advItemsOnLoad = [...this.advancedItemsOnLoad].map((item) => ({
        ...item,
        active: true,
      }));
      return __.isEqual(advItems, advItemsOnLoad);
    },
  },
  methods: {
    ...mapActions('slidesCollection', [
      'setCollectionData',
      // 'setDownloadStatus',
      'setDownloadSet',
      'setTailorID',
      'setNodeCountChange',
    ]),
    ...mapActions('slidesStore', [
      'addToFavoriteSlides',
      'removeFromFavoriteSlides',
      'addToImageReplacedSlides',
      'addToIconReplacedSlides',
      'addToDownloadedSlides',
      'saveSlidesStateSnapshot',
      'setSlideDetailViewChangedAudienceAndTemplate',
      'setSlidesLoadedInUI',
    ]),

    handleResetFilters() {
      this.resetAdvancedFilter();
      this.handleFilterCategoryChangeFn();
      if (this.$route.name === 'myPrezentation.detail')
        trackPrezentationEvents.prezentationsGalleryFilterReset(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Slides',
          },
        );
      else
        trackBuildEvents.buildGalleryFilterReset(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Slide library',
        });
    },
    getCurrentDateFormatted(origin) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      this.pickerDate = `${year}-${month}-${day}`;
      if (origin === 'from' && this.fromDate) this.pickerDate = this.fromDate;
      if (origin === 'to' && this.toDate) this.pickerDate = this.toDate;
    },
    getSlidesData() {
      return this.slidesFilteredChipAdvanced;
    },
    allowedToDates(val) {
      if (this.fromDate === '') {
        return moment(val).isSameOrBefore(new Date());
      }
      return (
        moment(val).isSameOrAfter(this.fromDate) &&
        moment(val).isSameOrBefore(new Date())
      );
    },
    handleApplyFilters() {
      this.isFiltersAppliedClick = true;
      if (
        this.filterCategory === 'Source' ||
        this.filterCategory === 'uploaded'
      ) {
        handleDebounce(() => this.doSearch(false, true, true));
      } else {
        this.handleFilterCategoryChangeFn();
      }
      let filtereTitle = '';
      switch (this.filterOptionCategory) {
        case 'brand_slides':
          filtereTitle = 'Brand slides';
          break;
        case 'uploaded':
          filtereTitle = 'Uploads';
          break;
        case 'generated':
          filtereTitle = 'Generated slides';
          break;
        case 'redesign':
          filtereTitle = 'Redesigned Slidess';
          break;
        case 'comply':
          filtereTitle = 'Converted Slides';
          break;
        case 'synthesis':
          filtereTitle = 'Synthesis Output Slides';
          break;
        case 'favorites':
          filtereTitle = 'Favorites slides';
          break;
        case 'downloads':
          filtereTitle = 'Downloads';
          break;
        case 'collections':
          filtereTitle = 'Collections';
          break;
        default:
          break;
      }
      if (this.$route.name === 'myPrezentation.detail')
        trackPrezentationEvents.prezentationsGalleryFiltered(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_PLACEMENT]: 'Slides',
            [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
            [TD_COMMON_COLUMN_VALUE]: filtereTitle,
          },
        );
      else
        trackBuildEvents.buildGalleryFiltered(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Slide library',
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: filtereTitle,
        });
    },
    resetToDefaultIfApplyNotClicked() {
      if (!this.isFiltersAppliedClick) {
        this.handleResetFilters();
      }

      this.isFiltersAppliedClick = false;
    },
    async getSearchTerms() {
      getUserSearchTerms()
        .then((searchData) => {
          const termsData = searchData.data.searchByUser.items;
          const allIndex = termsData.findIndex(
            (searchTerm) => searchTerm.searchTerm === 'All',
          );
          if (allIndex > -1) termsData.splice(allIndex, 1);
          if (termsData.length) {
            this.searchTerms = termsData;
          }
          if (this.searchTerms?.length > 0) {
            const terms = sortBy(this.searchTerms, ['count']);
            const termsDesc = terms.reverse();

            // Adding "All" to the chips as first option
            this.trendingKeywords = [];
            this.trendingKeywords.push({ name: 'All', category: 'All' });
            let count = 1;
            let i = 0;
            // Adding trending keywords of the user to chips
            while (count <= 12 && i < termsDesc.length) {
              const searchTerm = termsDesc[i].searchTerm.toLowerCase();
              const item = this.rejectedKeywords.find(
                (it) => it === searchTerm,
              );
              if (!item) {
                this.trendingKeywords.push({
                  name: termsDesc[i].searchTerm,
                  category: termsDesc[i].category,
                });
                count++;
                i++;
              } else {
                i++;
              }
            }
            let j = 0;
            // Filling remaining ideas from predefined keywords
            while (count <= 12 && j < this.keywords.length) {
              const searchTerm = this.keywords[j].category
                .toLowerCase()
                .replace('_', ' ');
              const item = this.trendingKeywords.find(
                (it) =>
                  it.category.toLowerCase().replace('_', ' ') === searchTerm,
              );
              if (!item) {
                this.trendingKeywords.push(this.keywords[j]);
                count++;
                j++;
              } else {
                j++;
              }
            }
          } else {
            this.trendingKeywords = this.keywords.slice(0);
          }
        })
        .catch((err) => console.log(err));
    },
    async onTryNow() {
      this.tryNowLoading = true;
      try {
        this.imageReplaceWalkthroughSlide =
          this.imageReplaceWalkthroughSlide || (await this.getSlideData())[0];
        this.activeSlide = this.imageReplaceWalkthroughSlide;
        this.activeSlide = {
          ...this.activeSlide,
          companies: [this.currentUser?.company?.name || ''],
        };
        this.tryNowLoading = false;
        this.isImageReplaceWalkThrough = true;
      } catch (err) {
        this.tryNowLoading = false;
        console.log(err);
      }
    },
    getSlideData() {
      return new Promise((resolve, reject) => {
        const params = {
          username:
            this.savedQueryPayload.username || this.currentUser.user.cognitoID,
          category: 'break',
          orderby: 'CHOICE_DESC',
          morprefs: [],
          orprefs: [],
          prefs: [],
          notprefs: [],
          andprefs: {
            theme: this.currentThemeCode,
          },
          // company: this.currentUser.companyName.toLowerCase(),
          // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
          limit: this.pageSize,
          skip:
            this.savedQueryPayload.skip >= 0 ? this.savedQueryPayload.skip : 0,
        };
        params.onlyids = false;

        const serialized = JSON.stringify(params);
        // Leaving this to log an empty search
        // AnalyticsHandler.performSlideSearch(
        //   this.currentUser,
        //   this.savedQueryPayload.query || this.savedQueryPayload.category,
        // );
        // AnalyticsHandler.logUserStats(this.currentUser);
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            this.compKey += 1;
            if (result.statusCode === 200 && result) {
              resolve(result.body.payloads);
              console.log(result);
            } else {
              resolve([]);
            }
          })
          .catch((err) => reject(err));
      });
    },

    handleOpenAdvancedFilter() {
      this.advancedFilterOpened = !this.advancedFilterOpened;
      this.collectionOpened = false;
      if (this.advancedFilterOpened) {
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }
      setTimeout(() => {
        this.setGoTopPosition();
      }, 300);
    },
    async makeApiCallsForSlidesAfterMount() {
      this.trendingKeywords = [];
      if (this.currentThemeCode === GENERIC_BOOSTER_PACK) {
        this.trendingKeywords = this.boosterPackKeywords;
      } else {
        this.getSearchTerms();
      }
      const { companyData } = this;
      const companyName = companyData.displayName
        ? companyData.displayName
        : companyData.name;
      if (companyData.name && companyName) {
        const capitalizedCompanyName = capitalizeFirstLetter(companyName);
        this.advancedItems[0].items[1].name = companyData.name;
        this.advancedItems[0].items[1].title = capitalizedCompanyName;
        this.advancedItems[0].items[1].display_title = capitalizedCompanyName;
        this.advancedItemsOnLoad[0].items[1].name = companyData.name;
        this.advancedItemsOnLoad[0].items[1].title = capitalizedCompanyName;
        this.advancedItemsOnLoad[0].items[1].display_title =
          capitalizedCompanyName;
      }
      this.currentAudience = this.currentUser;
      this.searchProgress = true;
      if (this.$route.query.category || this.$route.query.query) {
        if (this.$route.query.imgReplaceWalkthrough === 'true') {
          this.onTryNow();
        }
        this.savedQueryPayload = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          limit: this.pageSize,
          query: this.$route.query.query ? this.$route.query.query : '',
          category: this.$route.query.category
            ? this.$route.query.category
            : 'none',
          skip: 0,
        };
        await this.doSearch(false, true, false, true);
        this.searchProgress = false;
      } else if (this.$route.query?.type === 'uploaded') {
        this.filterOptionCategory = 'uploaded';
        this.handleOpenAdvancedFilter();
        this.handleFilterCategoryChangeFn();
      } else {
        this.savedQueryPayload = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          limit: this.pageSize,
          query: '',
          skip: 0,
        };
        await this.doSearch(false, true, false, true, true, false);
        this.searchProgress = false;
      }
      EventBus.$on('DO_SEARCH', (payload) => {
        this.searchProgress = true;
        this.resetAdvancedFilter();
        this.choice = this.getChoice(
          this.currentAudience.fingerPrint
            ? this.currentAudience.fingerPrint
            : 'director',
        );

        this.ideaChipClicked = 'All';
        this.savedQueryPayload = payload;
        handleDebounce(() => this.doSearch(false, true, true));
        AnalyticsHandler.selectedSlideFromAutoComplete(
          this.currentUser,
          payload.query ? payload.query : payload.category,
        );
        this.componentKey++;
      });
      this.resetReturn();
      this.reset();
    },

    ...mapActions('replaceImageStore', [
      'reset',
      'closeDialog',
      'openDialog',
      'resetReturn',
    ]),
    getRandomNumber(min, max) {
      const num = (Math.random() * (max - min) + min).toFixed(2);
      return Number(num);
    },
    getChoice(fingerprint) {
      let choice = {
        visual: this.getRandomNumber(4.5, 6.0),
        data: this.getRandomNumber(4.0, 5.0),
      };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'performer':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'navigator':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'surgeon':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'architect':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scholar':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scientist':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;
        case 'producer':
          choice = { visual: 1.0, data: 1.0 };
          return choice;
        default:
          return choice;
      }
    },

    ...mapActions('users', [
      'setAudience',
      'setUsers',
      'setCurrentUser',
      'addSearchTerms',
      'setStartLoadingSlidesFlag',
      'setUsers',
      'setCurrentTheme',
    ]),
    incrementSearchCount() {
      const userData = {
        performIncrements: {
          countSearches: 1,
        },
      };
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUserDetails) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUserDetails;
          this.setCurrentUser(userInfo);
        })
        .catch((err) =>
          console.log('error while updating the search count', err),
        );
      // return PrezentAnalyticsAPI.incrementSearchCount().catch((e) => {
      //   console.log(e);
      // });
    },
    searchWithinCategory(category) {
      return new Promise((resolve, reject) => {
        const andprefs = {};
        andprefs.theme = this.prezentationDetails.theme;
        let notprefs = {};
        if (this.currentThemeCode !== GENERIC_BOOSTER_PACK) {
          // eslint-disable-next-line no-nested-ternary
          notprefs = this.savedQueryPayload.notprefs
            ? this.savedQueryPayload.notprefs
            : !category || category === 'all'
            ? this.excludedCategories
            : [];
        }
        const params = {
          username:
            this.savedQueryPayload.username || this.currentUser.user.cognitoID,
          query: this.savedQueryPayload.query
            ? this.savedQueryPayload.query
            : '',
          category:
            !category || category === 'all' || category === 'none'
              ? []
              : category,
          orderby: 'CHOICE_DESC',
          choice: this.choice,
          morprefs: this.savedQueryPayload.morprefs
            ? this.savedQueryPayload.morprefs
            : [],
          orprefs: this.savedQueryPayload.orprefs
            ? this.savedQueryPayload.orprefs
            : [],
          prefs: this.savedQueryPayload.prefs
            ? this.savedQueryPayload.prefs
            : [],
          notprefs,
          andprefs,
          // company: this.currentUser.companyName.toLowerCase(),
          // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
          limit: this.pageSize,
          skip:
            this.savedQueryPayload.skip >= 0 ? this.savedQueryPayload.skip : 0,
        };
        params.onlyids = false;

        const serialized = JSON.stringify(params);
        // Leaving this to log an empty search
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          this.savedQueryPayload.query || this.savedQueryPayload.category,
        );
        AnalyticsHandler.logUserStats(this.currentUser);
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            this.compKey += 1;
            if (result.statusCode === 200 && result) {
              resolve(result.body);
            } else {
              resolve([]);
            }
          })
          .catch((err) => reject(err));
      });
    },
    // logSearchQueryFlag used to not log the search in some edge case
    async doSearch(
      isLoadMore = false,
      resetFilter = true,
      resetSearchProgress = false,
      endLoop = true,
      isSearchViaChip = false,
      logSearchQueryFlag = true,
    ) {
      // let oldScroll = 0;
      // if (this.$refs.slideswrapper) {
      //   oldScroll = this.$refs.slideswrapper.scrollTop;
      // }
      this.isImageReplaceWalkThrough = false;
      if (resetFilter) {
        this.filterBy = 'Recommended';
      }
      if (!isLoadMore) {
        this.searchProgress = true;
      }
      this.slides.splice(0, this.slides.length);
      let cat = [];
      if (this.savedQueryPayload.category) {
        if (!Array.isArray(this.savedQueryPayload.category)) {
          cat.push(this.savedQueryPayload.category);
        } else {
          cat = [...this.savedQueryPayload.category];
          if (!cat.length) {
            cat = ['all'];
          }
        }
      } else {
        cat.push('all');
      }
      if (!this.choice) {
        this.choice = this.getChoice(
          this.currentAudience?.fingerPrint ||
            this.currentUser?.user?.fingerPrint ||
            'director',
        );
      }
      if (
        (this.savedQueryPayload.query || this.savedQueryPayload.category) &&
        !isLoadMore
      ) {
        cat.forEach(() => this.incrementSearchCount());
      }
      AnalyticsHandler.performSlideSearch(
        this.currentUser,
        this.savedQueryPayload.query || this.savedQueryPayload.category,
      );
      AnalyticsHandler.logUserStats(this.currentUser);
      /* eslint-disable prefer-const */
      let [{ payloads: results, translated_query: translatedQuery }] =
        await Promise.all(
          cat.map((category) => this.searchWithinCategory(category)),
        );
      results = results?.map((item) => ({
        ...item,
        assetId: item.assetId || item.landscape,
      }));
      // this.deactivateAllChip();
      let searchTermResult = false;
      if (!results || !results.length) {
        this.isNoMore = true;
        if (isLoadMore) {
          this.searchProgress = false;
          this.slides = [...this.slidesFilteredChip];
        } else {
          this.slides = [];
        }
      } else {
        this.isNoMore = false;
        if (isLoadMore) {
          this.slides = [...this.slidesFilteredChip, ...results];
        } else {
          this.slides = results;
          searchTermResult = true;
        }
        if (results.length < this.pageSize) {
          this.isNoMore = true;
        }
        const arr = results.map((item) => item.unique_id);
        const params = {
          assetIds: arr,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(params);
      }

      // log search query
      if (!isLoadMore && logSearchQueryFlag) {
        const logPayload = {
          searchTermResult,
          type: isSearchViaChip ? 'Slide Chip' : 'Slides',
        };
        if (this.savedQueryPayload.query) {
          logPayload.searchTerm = this.savedQueryPayload.query.toLowerCase();
        } else {
          const catName = cat.map((item) =>
            item.replace('_', ' ').toLowerCase(),
          );
          logPayload.searchTerm = catName.join(', ');
        }
        logPayload.searchTermTranslated = translatedQuery;
        logPayload.tempalateID = this.currentTheme?.code;
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }

      this.isLoading = false;
      this.slidesFilteredChip = [...this.slides];
      if (this.slidesFilteredChip.length > 0) {
        this.slidesFilteredChipAdvanced = this.slidesFilteredChip.slice();
      } else {
        this.slidesFilteredChipAdvanced = [];
      }
      if (!endLoop) await this.handleChangeFilter(null, results);
      if (resetSearchProgress) this.searchProgress = false;
      this.setIntervalX(
        () => {
          this.cacheKey = +new Date();
        },
        500,
        1,
      );
      const { clickedKeyword } = this;
      if (
        this.clickedKeyword.category &&
        this.clickedKeyword.category.toLowerCase() !== 'all'
      )
        this.logSearch({ clickedKeyword, results });
      return results;
    },

    async logSearch(term) {
      try {
        const index =
          this.searchTerms &&
          this.searchTerms.findIndex((item) => {
            const category = item.category.toLowerCase().replace('_', ' ');
            const termName = term.clickedKeyword.name
              .toLowerCase()
              .replace('_', ' ');
            return category === termName;
          });
        if (index >= 0) {
          this.searchTerms[index].count += 1;
          const searchData = {
            id: this.searchTerms[index].id,
            count: this.searchTerms[index].count,
            searchTermResult: !(
              term.results === undefined ||
              term.results === null ||
              term.results.length <= 0
            ),
          };
          console.log('searchData', searchData);
          updateSearchTerms(searchData)
            .then(() => {
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        } else {
          console.log('add_search_term');
          const searchData = {
            id: uuidv4(),
            userID: this.currentUser.user.id,
            searchTerm: term.clickedKeyword.name,
            category: term.clickedKeyword.category,
            count: 1,
            createdAt: new Date().toISOString(),
            searchTermResult: !(
              term.results === undefined ||
              term.results === null ||
              term.results.length <= 0
            ),
          };
          await addSearchTerms(searchData)
            .then((value) => {
              this.addSearchTerms(value.data.createSearchTerm);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }
    },
    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();

        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },
    async handleChangeFilter(by, loadedPayloads) {
      this.isNoMore = false;
      this.savedQueryPayload.skip = 0;
      switch (this.filterBy) {
        case 'Popular': {
          this.searchProgress = true;
          this.resetAdvancedFilter();
          this.resetIdeaChipSelection();
          const { payloads } = await handleSearch({
            query_type: 'popular',
            theme_id: this.currentThemeCode,
          });
          this.popularSlides = payloads ? payloads.splice(0) : [];
          this.slidesFilteredChipAdvanced = this.popularSlides.slice(
            0,
            this.pageSize,
          );
          this.searchProgress = false;
          this.isNoMore = !(
            this.popularSlides?.length > this.slidesFilteredChipAdvanced?.length
          );
          break;
        }
        case 'Recommended': {
          this.searchProgress = true;
          this.resetAdvancedFilter();
          this.resetIdeaChipSelection();
          const payloads =
            loadedPayloads || (await this.doSearch(false, false));
          this.slidesFilteredChipAdvanced = payloads.splice(0);
          this.searchProgress = false;
          break;
        }
        default: {
          return;
        }
      }
      // trackSlideEvents.slidesSortBy(this.currentUser, {
      //   [TD_SORTTYPE]: this.matomoFilterMap[this.filterBy],
      // });
      const arr = this.slidesFilteredChipAdvanced.map((item) => item.unique_id);
      const chunkSize = 90;
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const params = {
          assetIds: chunk,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(params);
      }
      this.cacheKey = +new Date();
    },

    resetIdeaChipSelection() {
      this.savedQueryPayload.category = [];
      this.clickedKeyword = { name: 'All', category: 'All' };
      this.ideaChipClicked = 'All';
    },
    resetAdvancedFilter(makeApiCall = false) {
      this.currentSlidesPage = 1;
      const onloadFilters = JSON.parse(
        JSON.stringify(this.advancedItemsOnLoad),
      );
      this.advancedItems = [...onloadFilters];
      const onloadUploadFilters = JSON.parse(
        JSON.stringify(this.advancedItemsUploadOnLoad),
      );
      this.advancedItemsUpload = [...onloadUploadFilters];
      this.filterOptionCategory = 'brand_slides';
      this.advancedFilterBy = '';
      this.fromDate = '';
      this.toDate = '';
      this.fromDateMenu = false;
      this.toDateMenu = false;
      this.toDateStr = '';
      this.fromDateStr = '';
      if (makeApiCall) this.onAdvancedFilerChangeNew();
      this.advancedFilterKey++;
    },
    handleDateTextFieldClick(e) {
      this.menuPosition.x = e.clientX - 150;
      this.menuPosition.y = e.clientY;
    },
    handleClearDate(type) {
      if (type === 'from') {
        this.fromDate = '';
        this.fromDateStr = '';
      } else if (type === 'to') {
        this.toDate = '';
        this.toDateStr = '';
      }
    },
    allowedFromDates(val) {
      return moment(val).isSameOrBefore(new Date());
    },
    handleDatePickerTextChange(type) {
      if (type === 'from') {
        const fromDate = moment(this.fromDate);
        this.fromDateStr = fromDate.format('MM/DD/YYYY');
        if (this.toDate && moment(this.toDate).isBefore(fromDate)) {
          this.toDate = '';
          this.toDateStr = '';
        }
      } else if (type === 'to') {
        this.toDateStr = moment(this.toDate).format('MM/DD/YYYY');
      }
    },
    handleFilterCategoryChangeFn() {
      this.advancedFilterBy = '';
      // this.fromDate = '';
      // this.fromDateMenu = false;
      // this.fromDateStr = '';
      // this.toDate = '';
      // this.toDateMenu = false;
      // this.toDateStr = '';
      this.filterBy = 'Recommended';
      this.ideaChipClicked = 'All';
      this.clickedKeyword = {};
      this.savedQueryPayload.category = '';
      if (this.filterOptionCategory !== 'brand_slides') {
        const onloadFilters = JSON.parse(
          JSON.stringify(this.advancedItemsOnLoad),
        );
        this.advancedItems = [...onloadFilters];
      }
      if (this.filterOptionCategory !== 'uploaded') {
        const onloadFilters = JSON.parse(
          JSON.stringify(this.advancedItemsUploadOnLoad),
        );
        this.advancedItemsUpload = [...onloadFilters];
      }
      if (
        this.filterOptionCategory === 'favorites' ||
        this.filterOptionCategory === 'downloads'
      ) {
        this.advancedFilterBy =
          this.filterOptionCategory === 'favorites'
            ? 'My Favorites'
            : 'My Downloads';
        this.handleChangeAdvancedFilter();
        return;
      }
      if (
        this.filterOptionCategory === 'generated' ||
        this.filterOptionCategory === 'uploaded' ||
        this.filterOptionCategory === 'synthesis' ||
        this.filterOptionCategory === 'redesign' ||
        this.filterOptionCategory === 'comply'
      ) {
        this.getGeneratedOrUploadSlideDetails();
        return;
      }
      if (this.filterOptionCategory === 'brand_slides') {
        this.onAdvancedFilerChangeNew();
        handleDebounce(() => this.doSearch(false, true, true));
      }

      if (this.filterOptionCategory === 'collections') {
        this.getCollectionSlides();
      }
    },
    getCollectionSlides() {
      this.searchProgress = true;
      this.slidesFilteredChipAdvanced = [];
      getCollection()
        .then(async (resp) => {
          if (resp?.data?.collections) {
            let collections = resp.data.collections.filter(
              (collection) =>
                this.prezentationDetails.theme === collection.templateCode,
            );

            if (collections && collections[0]) {
              let slides = collections[0].slides || [];
              for (let index = 0; index < slides.length; index++) {
                let slide = slides[index];

                slide = {
                  ...slide,
                  filename: slide.slideFileName,
                  assetId: slide.slideThumbnail,
                  landscape: slide.slideThumbnail,
                  poster: slide.slideThumbnail,
                  unique_id: slide.slideID,
                  id: slide.slideID,
                  categories: [slide.construct],
                  outline: slide.idea,
                  title: slide.idea,
                  category: slide.construct
                    ?.toLowerCase()
                    ?.replaceAll(' ', '_'),
                };
                this.slidesFilteredChipAdvanced.push(slide);
              }
            }
          }
        })
        .catch((error) => {
          this.slidesFilteredChipAdvanced = [];
          console.log(error);
        })
        .finally(() => {
          this.searchProgress = false;
        });
    },

    onAdvancedFilerChangeNew(filterItem, filterCategory) {
      this.filterCategory = filterCategory?.title;
      filterCategory?.items.forEach((child) => {
        if (!child.active) {
          filterCategory.checkAll = false;
        }
      });

      if (this.filterOptionCategory === 'uploaded') {
        this.advancedItemsUpload[0].checkAll =
          this.advancedItemsUpload[0].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[0].items.length;

        this.advancedItemsUpload[1].checkAll =
          this.advancedItemsUpload[1].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[1].items.length;

        this.advancedItemsUpload[2].checkAll =
          this.advancedItemsUpload[2].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[2].items.length;
        // return;
      }
      const prefs = {};
      const morprefs = {};
      const notprefs = {};
      const orprefs = {};
      morprefs.node = [];
      morprefs.source = [];
      notprefs.node = [];
      notprefs.source = [];
      let checkAll = true;
      this.advancedItems[0].items.forEach((child) => {
        if (child.active) {
          morprefs.source.push(
            child.name ? child.name : child.title.toLowerCase().split('.')[0],
          );
        } else {
          checkAll = false;
          notprefs.source.push(
            child.name ? child.name : child.title.toLowerCase().split('.')[0],
          );
        }
      });
      this.advancedItems[0].checkAll = checkAll;
      checkAll = true;
      let moreSelected = false;
      this.advancedItems[1].items.forEach((child) => {
        if (child.title !== '10 or more') {
          if (child.active) {
            morprefs.node.push(child.title);
          } else {
            notprefs.node.push(child.title);
            checkAll = false;
          }
        } else if (child.title === '10 or more' && child.active)
          moreSelected = true;
      });
      if (moreSelected) morprefs.node = [];
      // "0" is added for a special case: if none is selected
      // then no slides will be returned
      // Given the scenario, none of the slides will be tagged with "0"
      else morprefs.node.push('0');
      this.advancedItems[1].checkAll = checkAll;
      checkAll = true;

      this.advancedItems[2].items.forEach((child) => {
        if (!child.active) {
          checkAll = false;
        }
      });
      this.advancedItems[2].items.forEach((child) => {
        switch (child.title.toLowerCase()) {
          case 'cartoons':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_cartoon = 'yes';
              }
            } else {
              notprefs.has_cartoon = 'yes';
              checkAll = false;
            }
            break;
          case 'icons':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_icons = 'yes'; // in solr (has_icons and has_icons_bus) will be checked
              }
            } else {
              notprefs.has_icons = 'yes'; // in solr (has_icons and has_icons_bus) will be checked
              checkAll = false;
            }
            break;
          case 'images':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_image_bus = 'yes'; // in solr (has_image and has_image_bus) will be checked
              }
            } else {
              notprefs.has_image_bus = 'yes'; // in solr (has_image and has_image_bus) will be checked
              checkAll = false;
            }
            break;
          case '3-d':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_three_d = 'yes';
              }
            } else {
              notprefs.has_three_d = 'yes';
              checkAll = false;
            }
            break;

          default:
            break;
        }
      });
      this.advancedItems[2].checkAll = checkAll;
      checkAll = true;
      morprefs.construct = [];
      /* "pyramid","bar_column_graph","donut_pie_graph","radar_chart","area_graph","multi_bar","line_graph","stacked_bar_graph","histogram","pictogram_chart","tree_map" */
      notprefs.construct = [];
      this.advancedItems[3].items.forEach((child) => {
        if (!child.active) {
          notprefs.construct.push(child.name);
          checkAll = false;
        } else {
          morprefs.construct.push(child.name);
        }
      });
      this.advancedItems[3].checkAll = checkAll;
      const payload = this.savedQueryPayload;
      payload.morprefs = morprefs;
      payload.prefs = prefs;
      payload.notprefs = notprefs;
      payload.orprefs = orprefs;
      payload.skip = 0;
      this.savedQueryPayload = payload;
      // handleDebounce(() => this.doSearch(false, true, true));
    },
    convertDate(dateStr) {
      const [month, day, year] = dateStr.split('/');
      const date = new Date(`${year}-${month}-${day}`);
      date.setMonth(date.getMonth()); // Subtract one month
      return date.toISOString(); // Convert to ISO format
    },
    async getGeneratedOrUploadSlideDetails() {
      this.isNoMore = false;
      this.searchProgress = true;
      this.generatedOrUploadSlidesArray = [];
      const andPrefs = {
        templateCode: this.currentUser?.theme?.code || 'default',
      };
      const notPrefs = {};
      const aclFilters = {};
      if (this.filterOptionCategory === 'uploaded') {
        const slideType = this.advancedItemsUpload[0].items
          .filter((item) => item.active === true)
          .map((item) => item.title);
        this.advancedItemsUpload[0].checkAll =
          this.advancedItemsUpload[0].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[0].items.length;
        if (slideType?.length !== this.advancedItemsUpload[0].items.length) {
          const slideTypePrefs = [];
          slideType.forEach((ele) => {
            if (ele === 'Original') {
              slideTypePrefs.push('Original Slides');
            }
            if (ele === 'Slide Shells') {
              slideTypePrefs.push('Original Slides');
            }
            if (ele === 'Partially Anonymized') {
              slideTypePrefs.push('Best Practice Slides');
            }
          });
          andPrefs.type = slideTypePrefs;
        }

        const author = this.advancedItemsUpload[1].items
          .filter((item) => item.active === true)
          .map((item) => item.title);
        this.advancedItemsUpload[1].checkAll =
          this.advancedItemsUpload[1].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[1].items.length;
        if (author?.length !== this.advancedItemsUpload[1].items.length) {
          author.forEach((ele) => {
            if (ele === 'Me') {
              andPrefs.ownerId = `\"${this.currentUser.user.id}\"`; // eslint-disable-line no-useless-escape
            }
            if (ele === 'My Colleagues') {
              notPrefs.ownerId = `\"${this.currentUser.user.id}\"`; // eslint-disable-line no-useless-escape
            }
          });
        }

        const access = this.advancedItemsUpload[2].items
          .filter((item) => item.active === true)
          .map((item) => item.title);
        this.advancedItemsUpload[2].checkAll =
          this.advancedItemsUpload[2].items.filter((item) => item.active)
            .length === this.advancedItemsUpload[2].items.length;
        if (access?.length !== this.advancedItemsUpload[2].items.length) {
          const permissionLevel = [];
          console.log(access);
          access.forEach((ele) => {
            if (ele === 'Viewer' || ele === 'Restricted' || ele === 'Editor') {
              permissionLevel.push(ele);
              notPrefs.ownerId = `\"${this.currentUser.user.id}\"`; // eslint-disable-line no-useless-escape
            }
            if (ele === 'Shared by me') {
              andPrefs.ownerId = `\"${this.currentUser.user.id}\"`; // eslint-disable-line no-useless-escape
              andPrefs.isShared = true;
            }
            if (ele === 'Private') {
              andPrefs.ownerId = `\"${this.currentUser.user.id}\"`; // eslint-disable-line no-useless-escape
              andPrefs.isShared = false;
            }
            if (
              andPrefs.ownerId === `\"${this.currentUser.user.id}\"` && // eslint-disable-line no-useless-escape
              notPrefs.ownerId === `\"${this.currentUser.user.id}\"` // eslint-disable-line no-useless-escape
            ) {
              delete andPrefs.ownerId;
              delete notPrefs.ownerId;
            }
            if (permissionLevel.length) {
              aclFilters.and = {
                permission_level_ss: permissionLevel,
                entity_ss: [
                  `\"${this.currentUser.user.id}\"`, // eslint-disable-line no-useless-escape
                  `\"${this.currentUser.company.id}\"`, // eslint-disable-line no-useless-escape
                  `\"${this.currentUser.team.id}\"`, // eslint-disable-line no-useless-escape
                ],
              };
            }
          });
        }

        const createdOnFromDate = this.fromDateStr
          ? this.convertDate(this.fromDateStr)
          : '';
        const createdOnToDate = this.toDateStr
          ? this.convertDate(this.toDateStr)
          : '';
        if (createdOnFromDate && createdOnToDate) {
          andPrefs.date_range = [createdOnFromDate, createdOnToDate];
        }
      }
      // const createdFrom = this.filterOptionCategory;
      this.advancedItemsUpload[0].checkAll =
        this.advancedItemsUpload[0].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[0].items.length;
      const choice = this.getChoice(
        this.currentUser.user.fingerPrint
          ? this.currentUser.user.fingerPrint
          : 'director',
      );
      this.advancedItemsUpload[1].checkAll =
        this.advancedItemsUpload[1].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[1].items.length;
      this.advancedItemsUpload[2].checkAll =
        this.advancedItemsUpload[2].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[2].items.length;
      try {
        const params = {
          username: this.currentUser.user.cognitoID,
          query: '*',
          // category: text || 'break',
          choice,
          moreprefs: [],
          andprefs: andPrefs,
          notprefs: notPrefs,
          acl_filters: aclFilters,
          slide_type: this.filterOptionCategory,
          audience_id: this.currentUser.user.id,
          audience_type: this.currentUser.user.type || 'user',
          limit: 30,
          skip: 0,
        };
        params.onlyids = false;
        const serialized = JSON.stringify(params);
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200 && result) {
              let slidesList = [];
              // After searching the slides from solr we are getting each slides data from db.
              const ids = [];
              result.body.payloads?.forEach((ele) => {
                ids.push(ele.parent.sid);
              });
              const params1 = {
                ids,
                limit: 30,
                offset: 0,
                slideTypeString: 'all',
                categories: '',
                createdFromString: this.filterOptionCategory,
                createdOnFromDate: '',
                createdOnToDate: '',
                authorString: 'all',
                accessString: 'all',
                templateCode: this.currentUser?.theme?.code || 'default',
                origin: this.filterOptionCategory,
              };
              const slideData = await getGeneratedUploadedSlides(params1);
              slideData.data.slides.sort(
                (a, b) => ids.indexOf(a?.id) - ids.indexOf(b?.id),
              );
              slidesList = slideData?.data?.slides.map((item) => ({
                ...item,
                assetId: item.assetId || item.landscape,
                template: item.templateCode,
                businessIdeas: item.businessIdea,
                nodeCount: item?.prefs?.node,
                tags: item.keywords,
                description: item.title,
                isGenerated: this.filterOptionCategory === 'generated',
                isUserUploaded: this.filterOptionCategory === 'uploaded',
                isRedesign: this.filterOptionCategory === 'redesign',
                isSynthesis: this.filterOptionCategory === 'synthesis',
                isComply: this.filterOptionCategory === 'comply',
              }));

              this.generatedOrUploadSlidesArray = slidesList;
              this.componentkey += 1;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isNetworkError = true;
          })
          .finally(() => {
            if (this.generatedOrUploadSlidesArray?.length) {
              this.slidesFilteredChipAdvanced =
                this.generatedOrUploadSlidesArray.slice(0, this.pageSize);
              const parametersMeta = {
                assetIds: [...this.generatedOrUploadSlidesArray].map(
                  (sld) => sld.unique_id,
                ),
                getDownload: true,
                getFavourite: true,
                getTrending: true,
                getViewCount: true,
              };
              this.getSlideDetails(parametersMeta);
            } else this.slidesFilteredChipAdvanced = [];
            this.searchProgress = false;
            if (this.generatedOrUploadSlidesArray.length < 24) {
              this.isNoMore = true;
            } else {
              this.isNoMore = false;
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async handleChangeAdvancedFilter() {
      this.isNoMore = false;
      this.savedQueryPayload.skip = 0;
      switch (this.advancedFilterBy) {
        case 'My Favorites': {
          this.searchProgress = true;
          let { payloads } = await handleSearch({
            query_type: 'favorites',
            // Using deck related theme
            theme_id: this.prezentationDetails?.theme || '',
          });

          payloads = payloads.map((item) => ({
            ...item,
            assetId: item.assetId || item.landscape,
          }));

          const favSlides = payloads ? payloads.splice(0) : [];
          console.log(...payloads, ...favSlides);
          this.popularSlides = favSlides
            .splice(0)
            .filter(
              (slide) =>
                slide?.prefs?.theme === this.prezentationDetails?.theme || '',
            );

          this.slidesFilteredChipAdvanced = this.popularSlides.slice(
            0,
            this.pageSize,
          );
          this.searchProgress = false;
          this.isNoMore = !(
            this.popularSlides?.length > this.slidesFilteredChipAdvanced?.length
          );
          break;
        }
        case 'My Downloads': {
          this.searchProgress = true;
          let { payloads } = await handleSearch({
            query_type: 'downloaded',
            // Using deck related theme
            theme_id: this.prezentationDetails?.theme || '',
          });
          payloads = payloads.map((item) => ({
            ...item,
            assetId: item.assetId || item.landscape,
          }));
          this.popularSlides = payloads ? payloads.splice(0) : [];
          this.slidesFilteredChipAdvanced = this.popularSlides.slice(
            0,
            this.pageSize,
          );
          this.searchProgress = false;
          this.isNoMore = !(
            this.popularSlides?.length > this.slidesFilteredChipAdvanced?.length
          );
          break;
        }
        default: {
          return;
        }
      }
      // trackSlideEvents.slidesSortBy(this.currentUser, {
      //   [TD_SORTTYPE]: this.matomoFilterMap[this.filterBy],
      // });
      const arr = this.slidesFilteredChipAdvanced.map((item) => item.unique_id);
      const chunkSize = 90;
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const params = {
          assetIds: chunk,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(params);
      }
      // if (
      //   this.savedQueryPayload &&
      //   this.savedQueryPayload.category &&
      //   typeof this.savedQueryPayload.category === 'string'
      // ) {
      //   this.slidesFilteredChipAdvanced =
      //     this.slidesFilteredChipAdvanced.filter(
      //       (e) =>
      //         e &&
      //         e.categories &&
      //         e.categories.length > 0 &&
      //         e.categories[0].toLowerCase() ===
      //           this.savedQueryPayload.category.toLowerCase(),
      //     );
      // }
      this.cacheKey = +new Date();
      // this.$nextTick(() => {
      //   if (this.$refs.slideswrapper) {
      //     console.log
      //     this.$refs.slideswrapper.scrollTop = 0;
      //   }
      //  });
    },

    getSlideDetails(params) {
      getSlideDetail(params)
        .then((slideData) => {
          this.slideMetaData = { ...this.slideMetaData, ...slideData.data };
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isDownloaded === true &&
              !this.getDownloadedSlides?.includes(key)
            ) {
              this.addToDownloadedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isImageReplaced === true &&
              !this.getImageReplacedSlides?.includes(key)
            ) {
              this.addToImageReplacedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMeta[key].isIconReplaced === true &&
              !this.getIconReplacedSlides?.includes(key)
            ) {
              this.addToIconReplacedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isFavourite === true &&
              !this.getFavoriteSlides?.includes(key)
            ) {
              this.addToFavoriteSlides(key);
            }
          }
        })
        .catch((err) => console.log(err));
    },

    onCheckboxAllClicked(e, title) {
      this.filterCategory = title;
      e.preventDefault();
      e.stopPropagation();
      if (title === 'Slide Type' || title === 'Author' || title === 'Access') {
        this.advancedItemsUpload.forEach((item) => {
          if (item.title === title && item.checkAll) {
            item.checkAll = true;
            item.items.forEach((opt) => {
              opt.active = true;
              opt.disabled = false;
            });
          }
          if (item.title === title && !item.checkAll) {
            item.checkAll = false;
            item.items.forEach((opt) => {
              opt.active = false;
              opt.disabled = false;
            });
          }
        });
      }
      //   const dataToTrack = {
      //     isAll: true,
      //     category: title && title.replace(/ /g, ''),
      //   };
      if (title === 'Graphics') {
        let items = [];
        if (this.advancedItems[2].checkAll === true) {
          //   dataToTrack.selected = true;
          items = this.advancedItems[2].items.map((item) => ({
            ...item,
            active: true,
          }));
          //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
          //     [TD_HASIMAGES]: 'All',
          //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
          //     [TD_COMMON_COLUMN_VALUE]: TD_GRAPHICS,
          //   });
        } else {
          //   dataToTrack.selected = false;
          items = this.advancedItems[2].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[2], 'items', items);
      } else if (title === 'Diagrams') {
        let items = [];
        if (this.advancedItems[3].checkAll === true) {
          //   dataToTrack.selected = true;
          items = this.advancedItems[3].items.map((item) => ({
            ...item,
            active: true,
          }));
          //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
          //     [TD_CONSTRUCT]: 'All',
          //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
          //     [TD_COMMON_COLUMN_VALUE]: TD_DIAGRAMS,
          //   });
        } else {
          //   dataToTrack.selected = false;
          items = this.advancedItems[3].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[3], 'items', items);
      } else if (title === 'Node Count') {
        let items = [];
        if (this.advancedItems[1].checkAll === true) {
          //   dataToTrack.selected = true;
          items = this.advancedItems[1].items.map((item) => ({
            ...item,
            active: true,
          }));
          //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
          //     [TD_NODECOUNT]: 'All',
          //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
          //     [TD_COMMON_COLUMN_VALUE]: TD_NODE_COUNT,
          //   });
        } else {
          //   dataToTrack.selected = false;
          items = this.advancedItems[1].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[1], 'items', items);
      } else if (title === 'Source') {
        let items = [];
        if (this.advancedItems[0].checkAll === true) {
          items = this.advancedItems[0].items.map((item) => ({
            ...item,
            active: true,
          }));
        } else {
          items = this.advancedItems[0].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[0], 'items', items);
      }
      //   MatomoAnalyticsHandler.advancedFilter(dataToTrack, this.currentUser);
    },
  },
};
</script>

<style lang="scss" scoped>
.advanced-filter-options {
  // height: calc(100% - 100px);
  // overflow: auto;
  padding: 0px 15px 15px 18px;

  .filters-action {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    .v-btn {
      text-transform: none;
      float: right;
    }

    &.apply-btn {
      position: sticky;
      bottom: 0;
      right: 0;
      width: fit-content;
      float: right;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }

    &.reset-btn {
      ::v-deep .v-btn__content {
        letter-spacing: normal;
      }
    }
  }
  ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        .v-input--radio-group__input {
          .v-radio {
            padding: 8px 0;
          }
          .v-list {
            .v-list-group {
              .v-list-group__items {
                .v-list-item {
                  max-height: 40px !important;
                  min-height: 40px !important;
                  .v-list-item__action {
                    margin: 0px 10px 0px 0px !important;
                  }
                  img {
                    margin: 0px 16px 0px 6px !important;
                    transform: none !important;
                  }
                  .v-list-item__content {
                    padding: 0 !important;
                    .v-list-item__title {
                      font-size: 16px !important;
                      // padding: 12px 0px 0px 0px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .v-label {
    color: black;
    font-size: 17px;
    margin-left: 10px;
  }
  ::v-deep .v-input--selection-controls {
    margin-top: 0px !important;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  ::v-deep .v-list {
    padding: 0 16px;
    overflow: auto;
  }

  ::v-deep .v-list-group__items > .v-list-item {
    padding-left: 50px;
  }

  .customGroupWrapper {
    margin: 0 10px;

    .label {
      font-size: 16px;
      font-weight: 600;
      color: black;
    }

    .applyDatesCTA {
      width: 70%;
      margin: 0 auto;
      .v-btn {
        background: transparent;
        border: none;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        font-size: 16px;
        color: #21a7e0;
        width: 100%;
        background-color: transparent !important;
      }
    }

    .clearIcon:hover,
    .calendarIcon:hover {
      color: rgb(32, 167, 224);
    }
  }

  .advanced-filter-title {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
  }
}
</style>
