<template>
  <div class="save-to-gdrive-down">
    <!-- Close icon !-->
    <div class="close-icon">
      <v-icon @click="handleClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <!-- Close icon !-->

    <!-- Illustration !-->
    <div class="illustration">
      <img src="/assets/img/save-to-google-drive-down.svg" alt />
    </div>
    <!-- Illustration !-->

    <!-- Description !-->
    <div class="error-text">
      {{ $t('common.googleDriveDown') }}
    </div>
    <!-- Description !-->

    <!-- Cta !-->
    <div class="ok-cta">
      <v-btn @click="handleClose">{{ $t('common.okUpperCase') }}</v-btn>
    </div>
    <!-- Cta !-->
  </div>
</template>

<script>
export default {
  name: 'SaveToGoogleDriveDown',
  data() {
    return {};
  },
  methods: {
    // Function to close the popup
    handleClose() {
      this.$modal.hide('SaveToGoogleDriveDown');
    },
  },
};
</script>

<style lang="scss" scoped>
.save-to-gdrive-down {
  padding: 16px;
  .close-icon {
    text-align: right;
    .v-icon {
      cursor: pointer;
    }
  }
  .illustration {
    width: 300px;
    margin: 0 auto;
    padding: 16px 0px;
    img {
      width: 100%;
    }
  }
  .error-text {
    padding: 0px 0px 16px 0px;
    font-size: 14px;
    text-align: center;
  }
  .ok-cta {
    display: flex;
    justify-content: center;
    .v-btn {
      box-shadow: none;
      background-color: #21a7e0;
      border-radius: 20px;
      letter-spacing: normal;
      text-transform: none;
      color: #ffffff;
    }
  }
}
</style>
