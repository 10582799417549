<template>
  <div>
    <AudienceItem
      v-if="item.type === 'People'"
      :itemClass="'user-template'"
      :maxWidth="427"
      :isInComplete="!item.item.fingerPrint"
      :fullName="item.item.fullName"
      :firstName="item.item.firstName"
      :lastName="item.item.lastName"
      :profileImgS3Path="item.item.profileImage"
      :email="audienceEmail"
      :creator="item.item.creator"
      :emailEntered="item.item.emailEntered"
      :companyName="item.item.companyName"
      :ownerId="item.item.ownerID"
      :isPrivate="item.item.isPrivate"
      :isShared="item.item.isShared"
      :status="item.type === 'People' && !item.item.isActive ? 'Disabled' : ''"
      :isDifferentCompany="item.item.companyID !== currentUser.user.companyID"
      :isPublicLimited="item.item.isPublicLimited"
      :isGroup="item.item.isGroup"
      :fingerprintType="
        item.item.fingerprintType
          ? item.item.fingerprintType.toLowerCase()
          : 'default'
      "
    >
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </AudienceItem>
    <div
      class="item-templates"
      v-else-if="item.type === 'Prezentations' && !item.item.isBestPracticeDeck"
    >
      <v-list-item class="audience-item" :style="'max-width: 350px'">
        <v-list-item-avatar :size="26" class="profile-avatar">
          <img :src="typeIcons[item.type] || typeIcons.Slides" alt="" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-name">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-email">
            <div
              v-if="
                item &&
                item.item &&
                item.item.matchedIndice &&
                item.item.matchedIndice.length
              "
              class="matchingSlidesWrapper"
            >
              <v-tooltip bottom open-delay="500" close-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <div class="matching-results" v-bind="attrs" v-on="on">
                    {{ item.item.matchedIndice }}
                  </div>
                </template>
                <div>
                  <v-list-item-title class="text-name">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-email">
                    {{ item.item.matchedIndice }}
                  </v-list-item-subtitle>
                </div>
              </v-tooltip>
            </div>
            <div v-else class="author">
              <span>
                {{ `Storyline: ${item.item.type}` }}
              </span>
              <span>
                {{ `| Author: ` }}
                <span>
                  {{ `${isOwner(item.item) ? 'Me' : item.item.ownerName}` }}
                </span>
              </span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
    <div
      class="item-templates"
      v-else-if="item.type === 'Storylines' || item.type === 'Build'"
    >
      <v-list-item class="audience-item" :style="'max-width: 350px'">
        <v-list-item-avatar :size="26" class="profile-avatar">
          <img :src="typeIcons[item.type] || typeIcons.Slides" alt="" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-name">
            {{ item.name }}
            <img
              v-if="item.creator === 'Prezent'"
              src="/assets/img/prezent_avatar.svg"
              alt
              width="18px"
            />
          </v-list-item-title>
          <v-list-item-subtitle class="text-email">
            <div class="author">
              <span
                v-if="
                  item.type === 'Storylines' ||
                  (item.type === 'Build' && item.item.isPrezentGenerated)
                "
              >
                {{ `Source: ` }}
                <span v-if="item.item.isPrezentGenerated">
                  {{ 'Prezent' }}
                </span>
                <span v-else>
                  {{ currentUser.company.displayName }}
                </span>
              </span>
              <span
                v-if="!item.item.isPrezentGenerated && item.type === 'Build'"
              >
                {{ `Storyline: ${item.item.type}` }}
              </span>
              <span v-if="!item.item.isPrezentGenerated">
                {{ `| Author: ` }}
                <span>
                  {{ `${isOwner(item.item) ? 'Me' : item.item.fullName}` }}
                </span>
              </span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
    <div class="item-templates" v-else-if="item.type === 'Best Practice Decks'">
      <v-list-item class="audience-item" :style="'max-width: 350px'">
        <v-list-item-avatar :size="26" class="profile-avatar">
          <img :src="typeIcons[item.type] || typeIcons.Slides" alt="" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-name">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-email">
            <div
              v-if="
                item &&
                item.item &&
                item.item.matchedIndice &&
                item.item.matchedIndice.length
              "
              class="matchingSlidesWrapper"
            >
              <v-tooltip bottom open-delay="500" close-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <div class="matching-results" v-bind="attrs" v-on="on">
                    {{ item.item.matchedIndice }}
                  </div>
                </template>
                <div>
                  <v-list-item-title class="text-name">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-email">
                    {{ item.item.matchedIndice }}
                  </v-list-item-subtitle>
                </div>
              </v-tooltip>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
    <div v-else class="item-template">
      <div class="image">
        <img
          width="26"
          :src="typeIcons[item.type] || typeIcons.Slides"
          alt=""
        />
      </div>
      <div class="name">
        {{ item.name || item.display_name }}

        <img
          v-if="item.creator === 'Prezent'"
          src="/assets/img/prezent_avatar.svg"
          alt
          width="22px"
        />
      </div>
      <div class="dot">·</div>
      <div class="type">
        {{ renameTypes(item.type) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AudienceItem from './AudienceItem.vue';
import { isGeneratedEmail } from '../../utils/common';

export default {
  components: {
    AudienceItem,
  },
  data() {
    return {
      typeIcons: {
        Prezentations: `/assets/icons/search/prezentation.svg`,
        Templates: `/assets/icons/search/templates.svg`,
        Slides: `/assets/icons/search/slides.svg`,
        Know: `/assets/icons/search/know-search-icon.svg`,
        Learn: `/assets/icons/search/learn.svg`,
        Help: `/assets/icons/search/help.svg`,
        FAQs: `/assets/icons/search/faqs.svg`,
        Walkthroughs: `/assets/icons/search/walkthrough.svg`,
        Tutorials: `/assets/icons/search/tutorial.svg`,
        'Quick-tips': `/assets/icons/search/quicktips.svg`,
        Storylines: `/assets/icons/search/Storyline-search-dropdown-icon.svg`,
        Build: `/assets/icons/search/Build-search-dropdown-icon.svg`,
        'Overnight Prezentations': `/assets/icons/search/overnight-search.svg`,
        Generate: `/assets/icons/search/generate.svg`,
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isOwner(item) {
      if (item?.ownerID) {
        return item?.ownerID === this.currentUser?.user?.id;
      }
      return item?.userID === this.currentUser?.user?.id;
    },

    renameTypes(item) {
      switch (item) {
        case 'Slides (Uploads)':
          return 'Slide Library (Uploads)';
        // Added premium slides in renameTypes
        case 'Slides (Generated)':
          return 'Slide Library (Generated)';

        case 'Slides (Synthesis)':
          return 'Slide Library (Synthesis)';

        case 'Slides (Template Converter)':
          return 'Slide Library (Converted)';

        case 'Slides (Redesigned)':
          return 'Slide Library (Redesigned)';

        case 'Slides':
          return 'Slide Library';

        case 'Generate':
          return 'Auto Generator';

        case 'Learn':
          return 'Courses';

        case 'Prezentations':
          return 'Prezentation Library';

        case 'Build':
          return 'Story Builder';

        default:
          return item;
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    audienceEmail() {
      if (this.item?.item) {
        return isGeneratedEmail(this.item?.item) ? null : this.item?.item?.id;
      }
      return this.item?.item?.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-template {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  padding: 0 0.5rem 0 1rem;

  .image {
    margin-right: 0.5rem;
  }

  .name {
    width: 300px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.item-templates {
  padding: 0px;
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-start;
}
.dot {
  margin-left: 10px;
  margin-right: 5px;
  opacity: 0.5;
  text-align: right;
}
.author {
  width: 300px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.audience-item {
  min-height: 40px;

  .profile-avatar {
    margin-right: 8px !important;

    &--initial {
      color: white;
      font-size: 14px;
      font-weight: 250;
    }
  }

  .text-name {
    font-size: 14px;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-email {
    font-size: 12px;
    text-align: start;
    .matchingSlidesWrapper {
      .matching-results {
        font-size: 12px;
        width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .matching-results-tooltip {
        font-size: 12px;
      }
    }

    .dot {
      margin: 0 4px;
      font-size: 8px;
    }
  }
}
.type {
  font-size: 14px;
  opacity: 0.5;
}
</style>
