import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getMatomoEventName,
  getSlideData,
  getSlideDataCollection,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const SLIDES_EVENT_CATEGORY = 'Slides';
const SLIDES_SEEMORERESULTS = 'slides_SeeMoreResults';
const SLIDES_ICONCLICK = 'slides_IconClick';
const SLIDES_BACKTOTOP = 'slides_BackToTop';
const SLIDES_TAILORSLIDES = 'slides_TailorSlides';
const SLIDES_SORTBY = 'slides_SortBy';
const SLIDES_THUMBNAIL_DOWNLOAD = 'Slides_Thumbnail_download';
const DROPDOWN_SLIDES = 'dropdown_slides';
const SLIDES_THUMBNAILCLICK = 'slides_ThumbnailClick';
const SLIDES_THUMBNAILCLICK_DOWNLOAD = 'slides_ThumbnailClick_download';
const SLIDES_THUMBNAIL_VIEWICONCLICK = 'slides_Thumbnail_ViewIconClick';
const SLIDES_THUMBNAIL_VIEWICONCLICK_DOWNLOAD =
  'slides_Thumbnail_ViewIconClick_download';
const SLIDES_SIMILARSLIDES = 'slides_SimilarSlides';
const SLIDES_IDEACHIPCLICK = 'slides_IdeaChipClick';
const SLIDES_IMAGEREPLACEMENT = 'slides_ImageReplacement';
const SLIDES_IMAGEREPLACEMENT_DOWNLOAD = 'slides_ImageReplacement_download';
const SLIDES_SIMILARSLIDES_DOWNLOAD = 'slides_SimilarSlides_download';
const SLIDES_IMAGE_REPLACEMENT_RESET_TO_DEFAULT =
  'slides_ImageReplacement_Resettodefault';
const SLIDES_NODE_COUNT_CHANGE = 'Slides_Nodecountchange';
const SLIDES_NODE_COUNT_CHANGE_DOWNLOAD = 'Slides_Nodecountchange_download';
const SLIDES_FAVOURITED = 'Slides_favourited';
const SLIDES_IMAGEREPLACEMENT_SEARCH = 'Slides_imagereplacement_Search';
const SLIDES_TEMPLATEINFO_CLICK = 'Slides_templateinfo_click';
const SLIDES_TEMPLATEINFO_SEARCH = 'Slides_templateinfo_search';
const SLIDES_TEMPLATEINFO_YESIMSURE = 'Slides_templateinfo_yesimsure';
const SLIDES_TEMPLATEINFO_ADDMORETEMPLATES_GETINTOUCH =
  'Slides_templateinfo_Addmoretemplates_getintouch';

const SLIDES_COLLECTION_CLICK = 'Slides_collection_click';
const SLIDES_ADDTOCOLLECTION = 'Slides_addtocollection';
const SLIDES_REMOVE_FROMCOLLECTION = 'Slides_removefromcollection';
const SLIDES_COLLECTION_MAXLIMIT = 'Slides_collectionmaxlimit';

const SLIDES_COLLECTION_DOWNLOAD = 'Slides_collection_download';
const SLIDES_COLLECTION_DELETE_YES = 'Slides_collection_delete_yes';
const SLIDES_COLLECTION_DELETE_NO = 'Slides_collection_delete_no';
const SLIDES_SET_DOWNLOAD = 'Slides_set_download';
const SLIDES_SET_DELETE_YES = 'Slides_set_delete_yes';
const SLIDES_SET_DELETE_NO = 'Slides_set_delete_no';

const SLIDES_IMAGEREPLACEMENT_ADDTOCOLLECTION =
  'Slides_imagereplacement_Addtocollection';
const SLIDES_IMAGEREPLACEMENT_ADDTOCOLLECTION_DOWNLOAD =
  'Slides_imagereplacement_Addtocollection_download';
const SLIDES_NODECOUNTCHANGE_ADDTOCOLLECTION =
  'Slides_Nodecountchange_Addtocollection';
const SLIDES_NODECOUNTCHANGE_ADDTOCOLLECTION_DOWNLOAD =
  'Slides_Nodecountchange_Addtocollection_download';
const SLIDES_SNACKBAR_CANCEL = 'Slides_snackbar_cancel';
const SLIDES_ADVANCE_FILTERS_SELECTED = 'Slides_advance filters_selected';

const SLIDES_RENAME_TICK = 'Slides_Rename_tick';
const SLIDES_RENAME_OUTSIDE_CLICK = 'Slides_Rename_outside_click';
const SLIDES_ELLIPSIS_COPYLINK = 'Slides_ellipsis_copylink';
const SLIDES_SHARE_UPDATE = 'Slides_share_update';
const SLIDES_DELETE_DELETE = 'Slides_delete_delete';
const SLIDES_RESET_ALL_FILTERS_CLICK = 'Slides_Reset-all-filters_click';
const SLIDES_BACK_FROM_DETAILED_VIEW = 'Slides_back-from-detailed-view';
const SLIDES_EDIT_SLIDE_DETAILS_CLICK = 'Slides_Edit-slide-details_click';
const SLIDES_EDIT_SLIDE_DETAILS_UPDATED = 'Slides_Edit-slide-details_updated';
const SLIDES_OTHER_ACTIONS_EDIT_BEST_PRACTICES_CLICK =
  'Slides_other-actions_Edit-Best-practices_click';
const SLIDES_BEST_PRACTICES_ADDED = 'Slides_best-practices_Added';
const SLIDES_BEST_PRACTICES_DRAGGED = 'Slides_best-practices_dragged';
const SLIDES_BEST_PRACTICES_EDITED = 'Slides_best-practices_Edited';
const SLIDES_BEST_PRACTICES_DELETE_DELETE =
  'Slides_best-practices_delete_delete';
const SLIDES_BEST_PRACTICE_HOVER = 'Slides_best-practice_hover';
const SLIDES_BEST_PRACTICE_TOGGLE_CLICK = 'Slides_best-practice_toggle_click';
const SLIDES_FAVOURITE_DOWNLOAD = 'Slides_favourite_download';
const SLIDES_EXPANDEDVIEW_DOWNLOAD = 'Slides_expandedview_download';
const SLIDES_SEARCH_DROPDOWN = 'Slides_search_dropdown';
const SLIDES_SEARCH_HYBRID = 'Slides_search_hybrid';
const ICON_REPLACEMENT_SUCCESS = 'Icon-replacement_success';
const ICON_REPLACEMENT_ICON_SEARCH = 'Icon-replacement_icon-search';
const SLIDES_CHANGE_LAYOUT_CLICK = 'Slides_change-layout_click';
const SLIDES_REPLACE_VISUALS_CLICK = 'Slides_Replace-visuals_click';
const SLIDES_EDIT_NODE_COUNT_CLICK = 'Slides_edit-node-count_click';
const SLIDES_REVERT_TO_ORIGINAL = 'Slides_Revert-to-original';
const SLIDES_ANONYMIZE_CLICK = 'Slides_anonymize-click';
const SLIDES_ANONYMIZE_ANONYMIZE = 'Slides_anonymize_anonymize';
const CONVERT_TEMPLATE_CLICK = 'Convert-template_click';
const CONVERT_TEMPLATE_TEMPLATE_SELECTED = 'Convert-template_template-selected';
const CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS =
  'Convert-template_accept-all-suggestions';
const CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE =
  'Convert-template_select-a-different-template';

export const trackSlideEvents = {
  slidesTemplateInfoClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_TEMPLATEINFO_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesTemplateInfoSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_TEMPLATEINFO_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesTemplateInfoYesIamSure(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_TEMPLATEINFO_YESIMSURE,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesTemplateInfoAddMoreGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_TEMPLATEINFO_ADDMORETEMPLATES_GETINTOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesCollectionClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_COLLECTION_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesAddToCollection(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_ADDTOCOLLECTION,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesRemoveFromCollection(slideData, currentUser, otherData) {
    try {
      let trackingData;
      if (slideData.slideID)
        trackingData = getSlideDataCollection(
          slideData,
          currentUser,
          otherData,
        );
      else trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_REMOVE_FROMCOLLECTION,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesCollectionMaxLimit(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_COLLECTION_MAXLIMIT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesCollectionDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_COLLECTION_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesCollectionDeleteYes(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_COLLECTION_DELETE_YES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesCollectionDeleteNo(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_COLLECTION_DELETE_NO,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSetDownload(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SET_DOWNLOAD,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSetDeleteYes(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SET_DELETE_YES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSetDeleteNo(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SET_DELETE_NO,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesImageReplacementAddToCollection(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_IMAGEREPLACEMENT_ADDTOCOLLECTION,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesImageReplacementAddToCollectionDownload(
    slideData,
    currentUser,
    otherData,
  ) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_IMAGEREPLACEMENT_ADDTOCOLLECTION_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesNodeCountChangeAddToCollection(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_NODECOUNTCHANGE_ADDTOCOLLECTION,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesNodeCountChangeAddToCollectionDownload(
    slideData,
    currentUser,
    otherData,
  ) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_NODECOUNTCHANGE_ADDTOCOLLECTION_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesSnackBarCancel(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SNACKBAR_CANCEL,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSeeMoreResults(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SEEMORERESULTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesBackToTop(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_BACKTOTOP,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesTailorSlides(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_TAILORSLIDES,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSortBy(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SORTBY,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slides(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_THUMBNAIL_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  trackDropdownSlides(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);

      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, DROPDOWN_SLIDES, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slideThumbNailClickDownload(slideData, currentUser, otherData) {
    try {
      const { imageReplaced } = slideData;
      let eventAction = '';
      if (imageReplaced) {
        eventAction = SLIDES_IMAGEREPLACEMENT_DOWNLOAD;
      } else {
        eventAction =
          slideData?.origin === 'viewicon'
            ? SLIDES_THUMBNAIL_VIEWICONCLICK_DOWNLOAD
            : SLIDES_THUMBNAILCLICK_DOWNLOAD;
      }
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slideThumbNailClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      const eventAction =
        slideData?.origin === 'viewicon'
          ? SLIDES_THUMBNAIL_VIEWICONCLICK
          : SLIDES_THUMBNAILCLICK;
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slidesSimilar(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, SLIDES_SIMILARSLIDES, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slideImageReplacement(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_IMAGEREPLACEMENT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesIdeaChipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_IDEACHIPCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSimilarSlidesDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_SIMILARSLIDES_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesImageReplacementResetToDefault(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_IMAGE_REPLACEMENT_RESET_TO_DEFAULT,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesNodeCountChange(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_NODE_COUNT_CHANGE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesFaviourite(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, SLIDES_FAVOURITED, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slidesNodeCountChangeDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_NODE_COUNT_CHANGE_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesImagereplacementSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_IMAGEREPLACEMENT_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesAdvanceFiltersSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_ADVANCE_FILTERS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesRenameTick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, SLIDES_RENAME_TICK, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slidesRenameOutsideClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_RENAME_OUTSIDE_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesElipsisCopyLink(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_ELLIPSIS_COPYLINK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesShareUpdate(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, SLIDES_SHARE_UPDATE, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slidesDeleteDelete(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(SLIDES_EVENT_CATEGORY, SLIDES_DELETE_DELETE, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  slidesResetAllFiltersClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_RESET_ALL_FILTERS_CLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesBackFromDetailedView(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BACK_FROM_DETAILED_VIEW,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesEditSlideDetailsClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_EDIT_SLIDE_DETAILS_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesEditSlideDetailsUpdated(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_EDIT_SLIDE_DETAILS_UPDATED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesOtherActionsEditBestPracticesClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_OTHER_ACTIONS_EDIT_BEST_PRACTICES_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticesAdded(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICES_ADDED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticesDragged(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICES_DRAGGED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticesEdited(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICES_EDITED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticesDeleteDelete(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICES_DELETE_DELETE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticeHover(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICE_HOVER,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesBestPracticeAddedToggleClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_BEST_PRACTICE_TOGGLE_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesFavoriteDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_FAVOURITE_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesExpandedViewDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_EXPANDEDVIEW_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slidesSearchDropdown(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SEARCH_DROPDOWN,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slidesSearchDropHybrid(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: SLIDES_EVENT_CATEGORY,
        eventAction: SLIDES_SEARCH_HYBRID,
      });
    } catch (e) {
      console.log(e);
    }
  },
  slideIconReplacement(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        ICON_REPLACEMENT_SUCCESS,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideIconReplacementSearch(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        ICON_REPLACEMENT_ICON_SEARCH,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideChangeLayoutClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_CHANGE_LAYOUT_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideReplaceVisualsClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_REPLACE_VISUALS_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideEditNodeCountClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_EDIT_NODE_COUNT_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideRevertToOriginal(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_REVERT_TO_ORIGINAL,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideAnonymizeClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_ANONYMIZE_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  slideAnonymizeAnonymizeButtonClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        SLIDES_ANONYMIZE_ANONYMIZE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        CONVERT_TEMPLATE_CLICK,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateTemplateSelectClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        CONVERT_TEMPLATE_TEMPLATE_SELECTED,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateAcceptAllSuggestions(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        CONVERT_TEMPLATE_ACCEPT_ALL_SUGGESTIONS,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  convertTemplateSelectDifferentTemplate(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(
        slideData,
        currentUser,
        otherData,
        false,
      );
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        SLIDES_EVENT_CATEGORY,
        CONVERT_TEMPLATE_SELECT_A_DIFFERENT_TEMPLATE,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
};
