<template>
  <div>
    <div class="collection-wrapper">
      <div class="collection-header-wrapper">
        <div class="collection-heading">
          <h1>{{ $t('slides.collection') }}</h1>
        </div>
        <div :style="{ display: 'flex', gap: '1.2rem' }">
          <!-- !-->
          <!-- only supporting local download for entire collections !-->
          <v-progress-circular
            indeterminate
            v-if="showAllCollectionsDownloadLoader"
            :size="22"
          />
          <Download
            v-else
            :file-name="''"
            :download-file-name="''"
            :merged-url="() => {}"
            :item="{}"
            :log-download="() => {}"
            :on-download="() => handleDownloadWholeCollection()"
            origin="prezentation"
            limitsKey="prezentation"
            :speedDialDirection="'left'"
            :mode="'icon'"
            :iconModeTooltipText="'slides.downloadEntireCollection'"
            :customSpeedDialPositionEnabled="true"
            :disableSaveToGDrive="true"
            :saveToGDriveDisabledTooltip="'slides.entireCollectionDownloadNotAvailable'"
            :isDisabled="
              disableOverallCollectionDownload ||
              getCollectionUpdateProgress ||
              isDownloadBlocked
            "
            :downloadBlocked="isDownloadBlocked"
          />
          <!-- !-->
          <v-btn
            class="close-btn"
            icon
            @click="showReset()"
            :disabled="getCollectionUpdateProgress"
            :height="'24px'"
          >
            <v-tooltip
              bottom
              max-width="200"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="black"
                  dark
                  dense
                  v-text="'mdi-delete-outline'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t('slides.removeEntireCollection') }}</span>
            </v-tooltip>
          </v-btn>
          <v-btn
            class="close-btn"
            icon
            @click="hideCollection"
            :height="'24px'"
          >
            <v-icon color="black" dense dark v-text="`mdi-close`" />
          </v-btn>
        </div>
      </div>
      <p class="collection-sub">
        {{ 'Shortlist and download up to 5 slides at once or save for later.' }}
      </p>
      <div class="empty-collection" v-if="getAllCollections.length === 0">
        <v-img
          class="empty-collection__img"
          :src="'/assets/img/Empty Collection.svg'"
          alt
        ></v-img>
        <p class="empty-collection__text">
          {{ $t('slides.collectionIsEmpty') }}
        </p>
        <p>
          {{ $t('slides.downloadUptoFiveSlides') }}
        </p>
        <p>
          {{ $t('slides.clickOnAddToCollection') }}
        </p>
      </div>
      <v-expansion-panels flat v-else v-model="panel">
        <v-expansion-panel
          v-for="(item, i) in getAllCollections"
          :key="`${item.templateID}_${i}`"
          expand
          v-model="panel"
        >
          <v-expansion-panel-header>
            <div class="set-header">
              <span>
                {{ item.templateName }}
              </span>
              <span style="display: flex; gap: 1.2rem; align-items: center">
                <!-- !-->
                <div
                  @click="
                    (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  "
                >
                  <v-progress-circular
                    indeterminate
                    v-if="
                      showCollectionDownloadLoader(item.templateID) === true
                    "
                    :size="22"
                  />
                  <Download
                    v-else
                    :file-name="`${item.templateName}`.trim()"
                    :download-file-name="`${item.templateName}`.trim()"
                    :merged-url="() => getMergedUrlForSaveToGdrive(item)"
                    :item="getDownloadItem(item)"
                    :log-download="
                      () => {
                        logDownloadForSaveToGdrive(item);
                      }
                    "
                    :on-download="() => handleDownload(item)"
                    origin="prezentation"
                    limitsKey="prezentation"
                    :speedDialDirection="'left'"
                    :mode="'icon'"
                    :iconModeTooltipText="'slides.downloadThisSet'"
                    :customSpeedDialPositionEnabled="true"
                    :isDisabled="
                      showAllCollectionsDownloadLoader ||
                      getCollectionUpdateProgress ||
                      isDownloadBlocked
                    "
                    :downloadBlocked="isDownloadBlocked"
                  />
                </div>
                <!-- !-->
                <v-btn
                  class="close-btn"
                  icon
                  @click.stop="showReset(item)"
                  :disabled="getCollectionUpdateProgress"
                >
                  <v-tooltip
                    bottom
                    max-width="200"
                    content-class="tooltip-content"
                    dense
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="black"
                        dark
                        v-text="'mdi-delete-outline'"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ $t('slides.removeThisSet') }}</span>
                  </v-tooltip>
                </v-btn>
                <div>
                  <v-tooltip
                    bottom
                    max-width="200"
                    content-class="tooltip-content"
                    fixed
                    open-delay="200"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="black"
                        :class="
                          panel >= 0 && panel === i ? 'min-collection' : ''
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        $expand
                      </v-icon>
                    </template>
                    <span>
                      {{
                        panel >= 0 && panel === i
                          ? $t('slides.minimizeThisSet')
                          : $t('slides.expandThisSet')
                      }}
                    </span>
                  </v-tooltip>
                </div>
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-for="(slidee, i) in item.slides"
              :key="`${slidee.slideFileName}_${i}`"
              class="slide-image-container"
            >
              <img
                :src="
                  slidee.thumbnail || '/assets/img/slides/placeholder-slide.svg'
                "
                :key="slidee.slideFileName"
                class="slide-image"
                contain
                loading="lazy"
                :aspect-ratio="16 / 9"
              />
              <div class="additional-options">
                <div
                  class="show-detail pl-2"
                  @click="handleDeleteCollection(slidee, item.templateID)"
                >
                  <v-tooltip
                    bottom
                    max-width="200"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on">
                        mdi-file-minus
                      </v-icon>
                    </template>
                    <span>{{ $t('slides.removeThisSlide') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div
                class="image-replaced__collection"
                v-if="slidee.isImageReplaced || slidee.isIconReplaced"
              >
                {{ $t('build.step1.custom') }}
              </div>
            </div>
            <div class="limit-reaced__message" v-if="item.slides.length === 5">
              <p>{{ $t('slides.limitReached') }}</p>
              <p>{{ $t('slides.slidesAdded') }}</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapActions } from 'vuex';
import { miscConfig } from '../../../runtimeConfig';
// import usersApi from '../../../API/users-api';
// import {
//   createDownload,
//   createCollectionDownload,
// } from '../../../utils/api-helper';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
import Download from '../Download.vue';

export default {
  name: 'CollectionToolCntr',
  data() {
    return {
      panel: 0,
    };
  },
  props: {
    downloadCollection: {
      type: Function,
      default: () => ({}),
    },
    showReset: {
      type: Function,
      default: () => ({}),
    },
    hideCollection: {
      type: Function,
      default: () => ({}),
    },
    handleDeleteCollection: {
      type: Function,
      default: () => ({}),
    },
    logDownloadForSaveToGdrive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('slidesCollection', [
      'getAllCollections',
      'getCollectionUpdateProgress',
    ]),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    ...mapGetters('users', ['isDownloadBlocked']),

    showAllCollectionsDownloadLoader() {
      if (this.getAllCollections.length) {
        let loaders = 0;
        this.getAllCollections.forEach((cl) => {
          const ldr = this.getDownloadLoaders.find(
            (ld) => ld.loaderId === cl.templateID && ld.downloading,
          );
          if (ldr) {
            loaders++;
          }
        });
        return loaders === this.getAllCollections.length;
      }
      return false;
    },

    disableOverallCollectionDownload() {
      if (this.getAllCollections.length) {
        let loaders = 0;
        this.getAllCollections.forEach((cl) => {
          const ldr = this.getDownloadLoaders.find(
            (ld) => ld.loaderId === cl.templateID && ld.downloading,
          );
          if (ldr) {
            loaders++;
          }
        });
        return loaders.length > 0;
      }
      return true;
    },
  },
  components: {
    Download,
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),

    showCollectionDownloadLoader(templateID) {
      const ldr = [...this.getDownloadLoaders].find(
        (ld) => ld.loaderId === templateID,
      );
      console.log(ldr);
      return ldr && ldr.downloading;
    },

    // logDownloadForSaveToGdrive(item) {
    //   let countSlideDownloads = 0;
    //   let countImgReplacedSlides = 0;
    //   let countSynthesisSlides = 0;
    //   let countComplySlides = 0;
    //   let countGeneratedSlides = 0;
    //   let countRedesignedSlides = 0;
    //   let countUploadedSlides = 0;
    //   let countIconReplacedSlides = 0;
    //   //  let countSanitizedSlides = 0;
    //   for (let i = 0; i < item.slides.length; i++) {
    //     const slide = item.slides[i];
    //     const downloadData = {
    //       id: uuidv4(),
    //       assetID: slide.slideID,
    //       themeID: item.templateCode,
    //       tailorID: slide.tailorID,
    //       idea: slide.idea,
    //       construct: slide?.construct,
    //       source: slide.source,
    //       downloadedFrom: 'Slide library (Collection)',
    //       sanitizedLevel: slide.sanitizedLevel,
    //       acceptedRejectedCount: slide.acceptedRejectedCount,
    //     };
    //     if (item.slides[i].isImageReplaced) {
    //       downloadData.replacedImage = true;
    //       countImgReplacedSlides++;
    //     }
    //     if (item.slides[i].isIconReplaced) {
    //       downloadData.replacedIcon = true;
    //       countIconReplacedSlides++;
    //     }
    //     if (item.slides[i].isComply) {
    //       countComplySlides++;
    //     }
    //     if (item.slides[i].isSynthesis) {
    //       countSynthesisSlides++;
    //     }
    //     if (item.slides[i].isGenerated) {
    //       countGeneratedSlides++;
    //     }
    //     if (item.slides[i].isRedesign) {
    //       countRedesignedSlides++;
    //     }
    //     if (item.slides[i].isUploaded) {
    //       countUploadedSlides++;
    //     }
    //     if (
    //       !item.slides[i].isUploaded &&
    //       !item.slides[i].isSynthesis &&
    //       !item.slides[i].isGenerated &&
    //       !item.slides[i].isRedesign &&
    //       !item.slides[i].isComply
    //     ) {
    //       countSlideDownloads++;
    //     }
    //     // if (item.slides[i].isUploaded) {
    //     //   countSanitizedSlides++;
    //     //   countUploadedSlides++;
    //     // }
    //     createDownload(downloadData)
    //       .then(() => {
    //         console.log('emit');
    //         this.$emit('logCollectionDownload', item);
    //       })
    //       .catch((err) => console.log(err));
    //   }
    //   const userData = {
    //     performIncrements: {},
    //   };
    //   if (countImgReplacedSlides > 0) {
    //     userData.performIncrements.downloadWithImgReplaced =
    //       countImgReplacedSlides;
    //   }
    //   if (countIconReplacedSlides > 0) {
    //     userData.performIncrements.downloadWithIconReplaced =
    //       countIconReplacedSlides;
    //   }
    //   if (countComplySlides > 0) {
    //     userData.performIncrements.complySlidesDownloaded = countComplySlides;
    //   }
    //   if (countSynthesisSlides > 0) {
    //     userData.performIncrements.synthesizeSlidesDownloaded =
    //       countSynthesisSlides;
    //   }
    //   if (countSlideDownloads > 0) {
    //     userData.performIncrements.downloadUsed = countSlideDownloads;
    //   }
    //   if (countUploadedSlides > 0) {
    //     userData.performIncrements.uploadSlidesDownloaded = countUploadedSlides;
    //   }
    //   if (countRedesignedSlides > 0) {
    //     userData.performIncrements.redesignSlidesDownloaded =
    //       countRedesignedSlides;
    //   }
    //   if (countGeneratedSlides > 0) {
    //     userData.performIncrements.generateSlidesDownloaded =
    //       countGeneratedSlides;
    //   }
    //   if (item.templateID) {
    //     createCollectionDownload({ templateID: item.templateID })
    //       .then((res) => console.log(res))
    //       .catch((err) => console.log(err));
    //   }
    //   usersApi.methods
    //     .updateUserProfile(userData)
    //     .then((updatedUser) => {
    //       const userInfo = this.currentUser;
    //       userInfo.user = updatedUser;
    //       this.setCurrentUser(userInfo);
    //     })
    //     .catch((err) => console.log(err));
    // },

    handleDownload(item) {
      this.downloadCollection(item.templateID);
    },

    handleDownloadWholeCollection() {
      this.downloadCollection();
    },

    getDownloadItem(item) {
      return {
        ...item,
        name: item.templateName,
        prefs: {
          source: item.source,
          theme: '',
        },
        filename: '',
        categories: [''],
        noofslides: item.slides.length,
      };
    },

    async getMergedUrlForSaveToGdrive(item) {
      const s = {
        slides: [],
      };
      item.slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.slideFileName?.charAt(0) === '/'
              ? slide?.slideFileName?.substring(1)
              : slide?.slideFileName,
          isReplaced:
            slide?.slideFileName?.startsWith(
              '/newImageReplaceOutput/output/',
            ) || slide?.slideFileName?.startsWith('/output/pptx/'),
          isUploaded: slide?.isUploaded,
          isGenerated: slide?.isGenerated,
          isSynthesis: slide?.isSynthesis,
          isRedesign: slide?.isRedesign,
          isComply: slide?.isComply,
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.collection-wrapper {
  ::v-deep .v-list-group__header .v-list-item__content {
    flex-wrap: nowrap;
  }
  ::v-deep .v-list-item {
    margin-bottom: 0px !important;
  }

  ::v-deep .v-list-group__header {
    justify-content: space-between;
  }
  ::v-deep .v-list {
    height: calc(100vh - 127px);
    overflow: auto;
  }
  ::v-deep .v-list-item img {
    filter: none;
  }
  ::v-deep .v-list-group__items > .v-list-item {
    padding-left: 50px;
  }

  .v-btn--icon.v-size--default {
    height: 0;
    width: 24.5px;
  }

  .collection-header-wrapper {
    // padding: 5px 10px 5px 20px;
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;

    h3 {
      display: inline-block;
      font-size: 17px;
    }
    .collection-heading {
      width: 70%;
      h1 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #000000;
        font-family: Lato;
        text-align: left;
      }
    }
    ::v-deep .download-actions {
      .save-to-gdrive-disabled {
        .download-icon {
          width: 24px;
          height: 28px;
          display: flex;
          .v-icon {
            color: black !important;
            &:after {
              background-color: #ffffff !important;
            }
          }
        }
      }
      .downloadPreferences {
        .downloadEleWithPrefs {
          .download-icon {
            width: 24px;
            height: 28px;
            display: flex;
            .v-icon {
              color: black !important;
              &:after {
                background-color: #ffffff !important;
              }
            }
          }
        }
        .alwaysAskPref {
          .new-slide-detail-speed {
            .download-icon {
              width: 24px;
              height: 28px;
              display: flex;
              .v-icon {
                color: black !important;
                &:after {
                  background-color: #ffffff !important;
                }
              }
            }
            .v-speed-dial__list {
              .download-list {
                position: fixed;
              }
            }
          }
        }
      }
    }
  }
  .collection-sub {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
    margin-top: 18px;
  }
  ::v-deep .v-expansion-panel-header {
    padding: 0 !important;
    width: -webkit-fill-available;
    &::before {
      background-color: #ffffff;
    }
  }
  .v-expansion-panels {
    max-height: calc(100vh - 220px) !important;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  ::v-deep .v-expansion-panel-header__icon {
    margin: 0px -14px 0px 13px;
    display: none;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 10px;
    padding: 0;
  }
  .slide-image {
    box-shadow: $item-box-shadow;
  }
  .slide-image-container {
    position: relative;
    padding: 0px 0px 4px 4px;
  }
  .limit-reaced__message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 10px;
    p {
      margin: 0;
    }
  }
  .additional-options {
    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: none;
    z-index: 0;
  }
  .image-replaced__collection {
    width: 100%;
    text-align: center;
  }
  .slide-image-container:hover .additional-options {
    display: flex;
  }
  .slide-image:hover {
    box-shadow: $item-box-shadow-hover;
  }
  .empty-collection {
    text-align: center;
    margin: 15px;
    background-color: white;
    .empty-collection__text {
      margin-top: 15px;
    }
    p {
      margin: 0;
      font-size: 12.5px;
    }
  }
  .set-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #000000;
    ::v-deep .download-actions {
      .save-to-gdrive-disabled {
        .download-icon {
          width: 24px;
          height: 28px;
          display: flex;
          .v-icon {
            color: black !important;
            &:after {
              background-color: #ffffff !important;
            }
          }
        }
      }
      .downloadPreferences {
        .downloadEleWithPrefs {
          .download-icon {
            width: 24px;
            height: 28px;
            display: flex;
            .v-icon {
              color: black !important;
              &:after {
                background-color: #ffffff !important;
              }
            }
          }
        }
        .alwaysAskPref {
          .new-slide-detail-speed {
            .download-icon {
              width: 24px;
              height: 28px;
              display: flex;
              .v-icon {
                color: black !important;
                &:after {
                  background-color: #ffffff !important;
                }
              }
            }
            .v-speed-dial__list {
              .download-list {
                position: fixed;
              }
            }
          }
        }
      }
    }
  }
  .min-collection {
    transform: rotate(180deg) !important;
  }
}
</style>
