import { trackCommonEvent } from './MatomoTrackingDataHelper';

const DESIGNER_EVENT_CATEGORY = 'Template Designer';
const DESIGNER_NEXT = 'template_designer_next';
const DESIGNER_GET_IN_TOUCH = 'template_designer_get-in-touch';
const DESIGNER_EXIT = 'template_designer_exit';
const DESIGNER_PREVIEW = 'template_designer_preview';

export const trackTemplateDesignerEvents = {
  designerNextFlow(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: DESIGNER_EVENT_CATEGORY,
        eventAction: DESIGNER_NEXT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  designerGetInTouch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: DESIGNER_EVENT_CATEGORY,
        eventAction: DESIGNER_GET_IN_TOUCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  designerExit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: DESIGNER_EVENT_CATEGORY,
        eventAction: DESIGNER_EXIT,
      });
    } catch (e) {
      console.log(e);
    }
  },
  previewTemplate(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: DESIGNER_EVENT_CATEGORY,
        eventAction: DESIGNER_PREVIEW,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
