<template>
  <div class="blocked-download-bnnr-cntr">
    <div class="banner_content">
      <div class="textContent">
        {{ $t('profile.downloadBlockedBannerInfo') }}
      </div>
      <div class="navCTA">
        <v-btn :loading="loading" @click="handleGetInTouch">{{
          $t('common.getInTouch')
        }}</v-btn>
      </div>
    </div>

    <v-dialog width="500px" v-model="dialog">
      <v-card class="blocked-download-wrapper">
        <div class="blocked-download-header">
          <v-icon
            medium
            class="notificationMenuCloseIcon"
            @click="handleCloseMsg"
          >
            mdi-close
          </v-icon>
        </div>

        <v-img :src="'/assets/img/blockedDownloads/get-in-touch.svg'" contain />

        <p>
          We have received your request and will get in touch with you shortly.
        </p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TemplateDesignerService } from '../../../pages/components/TemplateDesigner/TemplateDesignerService.js';
import { getInTouch } from '../../../utils/api-helper.js';

export default {
  name: 'BlockedDownloadsBanner', // Banner is displayed when the download limit per user has been crossed & the download is blocked
  data() {
    return {
      dialog: false,
      removingBanner: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('templateDesigner', ['getTDData', 'getFirstTDData']),
    formBannerText() {
      return this.getTDData.length === 0
        ? this.$t('templateDesign.banners.addTemplateBanner')
        : this.$t('templateDesign.banners.addAnotherTemplateBanner');
    },
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  methods: {
    ...mapActions('templateDesigner', ['setTDData']),
    async handleGetInTouch() {
      this.loading = true;
      try {
        await getInTouch('downloadBlocked');
        this.loading = false;
        this.dialog = true;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    handleCloseMsg() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked-download-bnnr-cntr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.blocked-download-wrapper {
  // height: 350px;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .blocked-download-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .notificationMenuCloseIcon {
      cursor: pointer;
    }
  }

  ::v-deep .v-image {
    width: 60%;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: center;
    width: 80%;
  }
}
</style>
